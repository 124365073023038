import { formatDateTimeFormat } from './helpers';

async function request(url: string, options: Record<string, string | FormData | Record<string, string>>): Promise<any> {
  const token = localStorage.getItem('token');

  let baseUrl = process.env.NODE_ENV === 'development' ? 'http://localhost:8076' : 'https://master.wondr.se';
  baseUrl = process.env.REACT_APP_MASTER_URL ? process.env.REACT_APP_MASTER_URL : baseUrl;
  const defaultOptions = {
    method: 'GET',
    headers: {
      Accept: 'application/json; */*',
      pragma: 'no-cache',
      'cache-control': 'no-store',
      ...(token ? { Authorization: token } : {})
    }
  };
  const headersWithDefault = { ...defaultOptions.headers, ...(options as Record<string, Record<string, string>>).headers };
  const optionsWithDefaults = { ...defaultOptions, ...options, headers: headersWithDefault };
  let response = null as Response | Error | unknown;
  try {
    response = await fetch(`${baseUrl}/${url}`, optionsWithDefaults);
  } catch (error) {
    console.log('error', error);
    response = error;
  }
  if (response instanceof Response) {
    const body = JSON.parse(await response.text(), (key, value) => {
      if (typeof value === 'string' && value.match(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\+\d{2}:\d{2}$/)) {
        return new Date(value);
      }

      if (typeof value === 'string' && key === 'attributes') {
        try {
          return JSON.parse(value);
        } catch (error) {
          return {};
        }
      }

      return value;
    });
    body.status = response.status;
    return body;
  }
  return null;
}
function formatBody(data: any) {
  return JSON.stringify(data, (key, value) => {
    if (value && value.match && value.match(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/)) {
      return formatDateTimeFormat(value);
    }
    return value;
  });
}
// ----------------- //
export async function get(url: string, query: any = []) {
  const options = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };

  query.master = true;

  const queryFormatted = Object.keys(query)
    .map((key) => {
      let res;
      const value = query[key];
      if (Array.isArray(value)) {
        res = value.map((v) => `${encodeURIComponent(key)}[]=${encodeURIComponent(v)}`).join('&');
      } else if (typeof value === 'object' && value !== null) {
        if (value) {
          res = Object.keys(value)
            .map((k) => {
              let nestedRes;
              const nestedValue = value[k];
              if (Array.isArray(nestedValue)) {
                if (nestedValue.length > 0) {
                  nestedRes = nestedValue.map((nv) => `${encodeURIComponent(key)}[${encodeURIComponent(k)}][]=${encodeURIComponent(nv)}`).join('&');
                } else {
                  nestedRes = `${encodeURIComponent(key)}[${encodeURIComponent(k)}]=`;
                }
              } else {
                nestedRes = `${encodeURIComponent(key)}[${encodeURIComponent(k)}]=${encodeURIComponent(nestedValue)}`;
              }
              return nestedRes;
            })
            .join('&');
        }
      } else {
        res = `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
      }

      return res;
    })
    .join('&');

  return request(url + (queryFormatted ? `?${queryFormatted}` : ''), options);
}
export async function post(url: string, data: any = [], file: string | boolean = 'image') {
  const options: { method: string, body: string | FormData, headers: Record<string, string> } = {
    method: 'POST',
    body: formatBody(data),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };

  file = file === true ? 'image' : file;
  if (file && data[file]) {
    delete options.headers['Content-Type'];
    const fromData = new FormData();
    fromData.append(file, data[file], data[file].name);
    options.body = fromData;
  }
  return request(url, options);
}
export async function put(url: string, data: any = []) {
  const options = {
    method: 'PUT',
    body: formatBody(data),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  return request(url, options);
}
export async function remove(url: string, data: any = []) {
  const options = {
    method: 'DELETE',
    body: formatBody(data),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  return request(url, options);
}
