import {
  Box, Button, Group, Image, Pagination, Select, SimpleGrid, Space, Stack, Tabs, Text, TextInput
} from '@mantine/core';
import { Dropzone, FileWithPath } from '@mantine/dropzone';
import {
  Dispatch, forwardRef, SetStateAction, useEffect, useImperativeHandle, useState
} from 'react';
import { get, post } from '../../utils/api';
import { __ } from '../../utils/translate';
import Icon from './Icon';

interface ImageModel {
  id: string;
}
interface UnsplashImage {
  id: string;
  name: string;
  thumbnail: string;
}
interface FileHandle {
  files: (FileWithPath | ImageModel)[];
  setFiles: Dispatch<SetStateAction<(FileWithPath | ImageModel)[]>>
  upload?: Function;
  directUpload?: boolean;
}
function ImagePreview({ fileHandle, maxFiles }: { fileHandle: FileHandle, maxFiles: number}) {
  const imageUrlDomain = process.env.NODE_ENV === 'development' ? 'http://localhost:8076' : '';
  const { files, setFiles } = fileHandle;
  const removeFile = (index: number) => {
    const newFiles = [...files];
    newFiles.splice(index, 1);
    setFiles(newFiles);
  };
  return (
    <SimpleGrid cols={Math.min(maxFiles, 3)} spacing="md" mt="md">
      {files.map((file, index) => {
        const imageUrl = (file as FileWithPath).path ? URL.createObjectURL((file as FileWithPath)) : `${imageUrlDomain}/images/view/${(file as ImageModel).id}`;
        return (
          <Box
            className="image-wrapper"
            mb={4}
            sx={{
              position: 'relative'
            }}
          >
            <Box
              sx={{
                position: 'absolute',
                top: '0.5rem',
                right: '0.5rem',
                zIndex: 1,
                cursor: 'pointer'
              }}
            >
              <Button
                onClick={() => removeFile(index)}
                size="sm"
                variant="filled"
                color="tertiary"
              >
                <Icon
                  name="close"
                  wrapper={false}
                />
              </Button>

            </Box>
            <Image
              src={imageUrl}
              radius="md"
              fit="cover"
              height={140}
            />
          </Box>
        );
      })}
    </SimpleGrid>
  );
}
function Upload({ maxFiles, onSelect }: { maxFiles: number, onSelect: Function }) {
  return (
    <Dropzone
      accept={['image/*']}
      maxFiles={maxFiles}
      multiple={maxFiles > 1}
      onDrop={(droppedFiles) => {
        droppedFiles.forEach(async (droppedFile) => {
          onSelect(droppedFile);
        });
      }}
    >
      <Group position="center" spacing="xl" style={{ minHeight: 75, pointerEvents: 'none' }}>
        <Stack align="center">
          <Icon name="upload" size="xl" />
          <Text size="xl">
            {maxFiles > 1 ? __('Drop images') : __('Drop an image here')}
          </Text>
        </Stack>
      </Group>
    </Dropzone>
  );
}
function UnsplashList({ onSelect }: { onSelect: Function }) {
  const [unsplash, setUnsplash] = useState({ images: [] as UnsplashImage[], pagination: { pageCount: 10 } });
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const getUnsplash = async () => {
    setUnsplash(await get('images/unsplash', { limit: 30, page, search }));
  };
  useEffect(() => {
    getUnsplash();
  }, [page]);
  useEffect(() => {
    const searchTimeout = setTimeout(() => {
      getUnsplash();
    }, 300);
    return () => clearTimeout(searchTimeout);
  }, [search]);
  return (
    <div className="list-wrapper">
      <TextInput
        value={search}
        onChange={(event) => setSearch(event.currentTarget.value)}
        placeholder={__('Search')}
        mb="md"
      />
      <SimpleGrid cols={3} className="image-wrapper" mb="md">
        {unsplash.images.map((image) => (
          <Image
            onClick={() => onSelect(image)}
            key={image.id}
            radius="md"
            src={image.thumbnail}
            alt={image.name}
            fit="cover"
            height={140}
            sx={() => ({
              cursor: 'pointer',
              transition: '0.2s ease-in-out',
              '&:hover': {
                opacity: 0.5
              }
            })}
          />
        ))}
      </SimpleGrid>
      <Group position="center">
        <Pagination page={page} onChange={setPage} total={unsplash.pagination.pageCount} />
      </Group>
    </div>
  );
}
function StoredImages({ onSelect }: { onSelect: Function }) {
  const imageUrlDomain = process.env.NODE_ENV === 'development' ? 'http://localhost:8076' : '';

  const [selectedGroup, setSelectedGroup] = useState<string | null>(null);
  const [imageGroups, setImageGroups] = useState([] as any[]);
  const getImageGroups = async () => {
    const res = await get('image-groups', { master: true, limit: -1 });
    setImageGroups(res.imageGroups.map((group:any) => ({ label: group.name, value: group.id })));
  };

  useEffect(() => {
    getImageGroups();
  }, []);

  const [images, setImages] = useState([] as ImageModel[]);
  const [pagination, setPagination] = useState({
    page: 1,
    pageCount: 0
  });
  const [page, setPage] = useState(1);
  const getImages = async () => {
    const res = await get('images', { limit: 12, page, ...(selectedGroup ? { groups: [selectedGroup] } : {}) });
    setImages(res.Images);
    setPagination(res.pagination.Images);
  };
  useEffect(() => {
    getImages();
  }, [page, selectedGroup]);
  return (
    <div className="list-wrapper">
      <Select
        data={imageGroups}
        placeholder={__('Select group')}
        value={selectedGroup}
        onChange={(value) => {
          setSelectedGroup(value);
        }}
        mb="md"
      />
      <SimpleGrid cols={3} className="image-wrapper" mb="md">
        {images.map((image) => (
          <Image
            onClick={() => onSelect(image)}
            key={image.id}
            radius="md"
            src={`${imageUrlDomain}/images/view/${image.id}`}
            alt={image.id}
            fit="cover"
            height={140}
            sx={() => ({
              cursor: 'pointer',
              transition: '0.2s ease-in-out',
              '&:hover': {
                opacity: 0.5
              }
            })}
          />
        ))}
      </SimpleGrid>
      <Group position="center">
        <Pagination page={pagination.page} onChange={setPage} total={pagination.pageCount} />
      </Group>
    </div>
  );
}
function ImageOptions({ tabs, fileHandle, maxFiles }: {tabs?: string[], fileHandle: FileHandle, maxFiles: number }) {
  const {
    files, setFiles, upload, directUpload
  } = fileHandle;
  const addFile = (file: FileWithPath | UnsplashImage | ImageModel) => {
    setFiles([...files, file]);
    if (directUpload) {
      (upload as Function)();
    }
  };
  return (
    <div>
      {maxFiles <= files.length ? null : (
        <Tabs
          color="tertiary"
          defaultValue="upload"
          className="media-wrapper"
        >
          <Tabs.List>
            {tabs?.includes('upload') && <Tabs.Tab value="upload" icon={<Icon name="upload" wrapper={false} />}>{__('Upload')}</Tabs.Tab>}
            {tabs?.includes('unsplash') && <Tabs.Tab value="unsplash" icon={<Icon name="image" wrapper={false} />}>{__('Unsplash')}</Tabs.Tab>}
            {tabs?.includes('wondr') && <Tabs.Tab value="wondr" icon={<Icon name="image" wrapper={false} />}>{__('Wondr')}</Tabs.Tab>}
          </Tabs.List>
          {tabs?.includes('upload') && (
            <Tabs.Panel value="upload" mt="md">
              <Upload
                maxFiles={maxFiles}
                onSelect={(file: FileWithPath) => {
                  addFile(file);
                }}
              />
            </Tabs.Panel>
          )}
          {tabs?.includes('unsplash') && (
            <Tabs.Panel value="unsplash" mt="md">
              <UnsplashList
                onSelect={(unsplashImage: UnsplashImage) => {
                  unsplashImage.id = `unsplash_${unsplashImage.id}`;
                  addFile(unsplashImage);
                }}
              />
            </Tabs.Panel>
          )}
          {tabs?.includes('wondr') && (
            <Tabs.Panel value="wondr" mt="md">
              <StoredImages
                onSelect={(image: ImageModel) => {
                  addFile(image);
                }}
              />
            </Tabs.Panel>
          )}
        </Tabs>
      )}
    </div>
  );
}
ImageOptions.defaultProps = {
  tabs: ['upload', 'unsplash', 'wondr']
};
/*
  - Looks like input and shows small image preview
  - Does not upload image to server
  - onclick opens modal
  - show stored images
  - tabs for upload and unsplash
  - before submit upload stored images
*/
const ImageHandle = forwardRef(({
  maxFiles, tabs, initialImages, error, directUpload, onAfterUpload, onChange
} : {
  maxFiles?: number, tabs?: string[], initialImages?: ImageModel[], error?: any, directUpload?: boolean, onAfterUpload?: Function, onChange?: Function
}, ref: any) => {
  const [files, setFiles] = useState<(FileWithPath | ImageModel)[]>([]);
  const [fileLength, setFileLength] = useState(0);

  useEffect(() => {
    if (initialImages) {
      setFiles(initialImages);
      setFileLength(initialImages.length);
    }
  }, [initialImages]);
  const upload = async () => {
    const promises = [] as Promise<any>[];
    // eslint-disable-next-line no-restricted-syntax
    for (const file of files) {
      if ((file as FileWithPath).path) {
        promises.push(post('images/add', { file }, 'file'));
      }
    }
    const uploadedImages = await Promise.all(promises);
    const results = [] as string[];
    files.forEach((file) => {
      const uploadedImage = uploadedImages.find((image) => (file as FileWithPath).path === image.image.filename);
      if (uploadedImage) {
        results.push(uploadedImage.image.id);
        if (onAfterUpload) {
          onAfterUpload(uploadedImage);
        }
      } else {
        results.push((file as ImageModel).id);
      }
    });
    return results;
  };
  useImperativeHandle(ref, () => ({
    async submit() : Promise<string[]> {
      return upload();
    }
  }));

  useEffect(() => {
    if (onChange && files.length !== fileLength) {
      setFileLength(files.length);
      upload().then((res: string[]) => onChange(res));
    }
  }, [files]);

  return (
    <div>
      <ImagePreview fileHandle={{ files, setFiles }} maxFiles={maxFiles ?? 1} />
      <Space h="md" />
      <ImageOptions
        tabs={tabs}
        maxFiles={maxFiles ?? 1}
        fileHandle={{
          files,
          setFiles,
          upload,
          directUpload
        }}
      />
      {error && (
        <Text fz="xs" color="red">
          {error}
        </Text>
      )}
    </div>
  );
});
ImageHandle.defaultProps = {
  maxFiles: 1,
  initialImages: [],
  tabs: ['upload', 'unsplash', 'wondr'],
  error: '',
  directUpload: false,
  onAfterUpload: (s: string) => {},
  onChange: (s: string[]) => {}
};
export default ImageHandle;
