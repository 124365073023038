import {
  Button,
  Checkbox,
  Group, MultiSelect, Select, Textarea, TextInput
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { useContext, useEffect, useState } from 'react';
import { CampaignGroup } from '../../models/campaigns/CampaignGroup';
import { CampaignTag } from '../../models/campaigns/CampaignTag';
import { get, put, remove } from '../../utils/api';
import { handleResponceErrors } from '../../utils/helpers';
import { __ } from '../../utils/translate';
import LanguageContext from '../helpers/LanguageContext';

function EditCampaign({ id, onSubmit, onDelete }: {id: string, onSubmit: () => void, onDelete: () => void }) {
  const { language, countryOptions, languageAlternativeCodes } = useContext(LanguageContext);
  const [loadingPut, setLoadingPut] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);

  const languageOptions = countryOptions.map((option) => ({
    value: languageAlternativeCodes[option.value],
    label: option.label
  }));

  const form = useForm({
    initialValues: {
      title: '',
      description: '',
      country: '',
      included_in_trial: false,
      type: 'wireframe',
      campaign_groups: [],
      campaign_tags: [],
      published_at: false
    }
  });

  const typeOptions = [
    { value: 'content', label: __('Content') },
    { value: 'wireframe', label: __('Wireframe') },
    { value: 'webpage', label: __('Webpage') }
  ];

  const getCampaign = async () => {
    const campaign = await get(`campaigns/${id}`);

    form.setValues({
      title: campaign.campaign.title,
      description: campaign.campaign.description,
      country: campaign.campaign.country,
      type: campaign.campaign.type,
      campaign_groups: campaign.campaign.campaign_groups.map((group: CampaignGroup) => group.id),
      campaign_tags: campaign.campaign.campaign_tags.map((tag: CampaignTag) => tag.id),
      published_at: !!campaign.campaign.published_at,
      included_in_trial: campaign.campaign.included_in_trial
    });
  };

  useEffect(() => {
    getCampaign();
  }, [id]);

  const [groups, setGroups]: [{ campaignGroups: CampaignGroup[], }, any] = useState({ campaignGroups: [] });
  const getGroups = async () => setGroups(await get('campaign-groups', { ...(language ? { language } : {}) }));

  const groupsOpitons = groups.campaignGroups ? groups.campaignGroups.map((group) => ({
    label: group.name,
    value: group.id
  })) : [];

  const [tags, setTags]: [{ campaign_tags: CampaignTag[], }, any] = useState({ campaign_tags: [] });
  const getTags = async () => setTags(await get('campaigns/tags'));

  const tagsOpitons = tags.campaign_tags ? tags.campaign_tags.map((tag) => ({
    label: tag.name,
    value: tag.id
  })) : [];

  const submit = async (data : any) => {
    setLoadingPut(true);

    data.published_at = data.published_at ? new Date() : null;

    const resp = await put(`campaigns/${id}`, data);
    setLoadingPut(false);

    if (resp.status === 200) {
      if (onSubmit) {
        onSubmit();
      }
    } else {
      const errors = handleResponceErrors(resp.error);

      form.setErrors(errors);
    }
  };

  const deleteCampaign = async () => {
    setLoadingDelete(true);
    await remove(`campaigns/${id}`);
    await onDelete();
    setLoadingDelete(false);
  };

  useEffect(() => {
    getGroups();
    getTags();
  }, [language]);

  return (
    <form onSubmit={form.onSubmit((data) => submit(data))}>
      <TextInput
        placeholder={__('Title')}
        label={__('Title')}
        {...form.getInputProps('title')}
        mb="lg"
        required
      />
      <Textarea
        placeholder={__('Description')}
        label={__('Description')}
        {...form.getInputProps('description')}
        mb="lg"
      />
      {false && (
        <TextInput
          placeholder={__('10,00 kr')}
          label={__('Price')}
          {...form.getInputProps('price')}
          mb="lg"
          rightSection={__('Kr')}
        />
      )}
      <Checkbox
        {...form.getInputProps('published_at', { type: 'checkbox' })}
        label={__('Published')}
        mb="lg"
      />
      <Checkbox
        {...form.getInputProps('included_in_trial', { type: 'checkbox' })}
        label={__('Part of trial')}
        mb="lg"
      />
      <Select
        data={typeOptions}
        placeholder={__('Select type')}
        label={__('Type')}
        {...form.getInputProps('type')}
        mb="lg"
      />
      <Select
        data={languageOptions}
        placeholder={__('Select Country')}
        label={__('Country')}
        {...form.getInputProps('country')}
        mb="lg"
      />
      <MultiSelect
        data={tagsOpitons}
        label={__('Tags')}
        placeholder={__('Select tags')}
        {...form.getInputProps('campaign_tags')}
        mb="lg"
      />

      {form.values.type !== 'wireframe' && (
        <MultiSelect
          data={groupsOpitons}
          label={__('Groups')}
          placeholder={__('Select groups')}
          {...form.getInputProps('campaign_groups')}
          mb="lg"
        />
      )}

      <Group grow>
        <Button color="red" variant="light" onClick={() => deleteCampaign()} loading={loadingDelete}>
          {
            loadingDelete ? __('Deleting...') : __('Delete')
          }
        </Button>
        <Button type="submit" loading={loadingPut}>
          {
            loadingPut ? __('Saving...') : __('Save')
          }
        </Button>
      </Group>
    </form>
  );
}

export default EditCampaign;
