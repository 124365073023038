import {
  Flex, Paper, Image, Group, Title, Stack
} from '@mantine/core';
import {
  createContext, ReactNode, useContext, useEffect, useMemo, useState
} from 'react';
import { get } from '../../utils/api';
import Icon from './Icon';
import Login from './Login';

interface AuthInterface {
  token: null | string;
  setToken: (token: string) => void;
  loading: boolean;
  setLoading: (loading: boolean) => void;

  dbLoading: boolean;
  setDbLoading: (loading: boolean) => void;
}

const AuthContext = createContext<AuthInterface>({
  token: null,
  setToken: (token) => { },
  loading: true,
  setLoading: (loading) => { },

  dbLoading: true,
  setDbLoading: (loading) => { }
});

export function AuthLogin() {
  const { setToken, loading, dbLoading } = useContext(AuthContext);
  return (
    <Flex
      justify="center"
      align="center"
      h="100vh"
      sx={{
        backgroundImage: 'url(/app/bg.jpg)',
        backgroundSize: 'cover',
        backgroundPosition: 'center'
      }}
    >
      <Paper
        sx={(theme) => ({
          backgroundColor: theme.colors.black[6],
          minWidth: '400px',
          padding: '64px'
        })}
        shadow="md"
      >
        <Image src="/app/w.svg" mb="xl" mx="auto" height="120px" fit="contain" />

        {loading || dbLoading
          ? (
            <Stack align="center">
              <Icon name="spinner-third" spin wrapper={false} />
              <Title order={4}>{dbLoading ? 'Loading database' : 'Loading authentication'}</Title>
            </Stack>
          )
          : (
            <Login
              onChange={(value) => {
                localStorage.setItem('token', value);
                setToken(value as string);
              }}
            />
          )}
      </Paper>
    </Flex>
  );
}

export function AuthProvider({ children }: { children: ReactNode }) {
  const [token, setToken] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [dbLoading, setDbLoading] = useState<boolean>(true);

  const authProviderValue = useMemo(() => ({
    token, setToken, loading, setLoading, dbLoading, setDbLoading
  }), [token, setToken, loading, dbLoading, setLoading]);

  useEffect(() => {
    async function init() {
      if (localStorage.getItem('token') && token === null) {
        const t = localStorage.getItem('token');
        const request = await get('auth/validate', { token: t });
        setToken(request.token_valid ? t : null);
      }
      setLoading(false);
    }
    init();
  }, []);

  useEffect(() => {
    async function updateDb() {
      if (window.location.origin.includes('test.wondr')) {
        await get('wondr/migrate');
        setDbLoading(false);
      } else {
        setDbLoading(false);
      }
    }
    updateDb();
  }, []);

  return (
    <AuthContext.Provider value={authProviderValue}>
      { token === null || dbLoading ? <AuthLogin /> : children}
    </AuthContext.Provider>
  );
}
export default AuthContext;
