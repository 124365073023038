import { useContext, useEffect, useState } from 'react';
import {
  Button, Text, Table, Container, Paper, Title, Flex, Badge
} from '@mantine/core';
import { closeModal, openModal } from '@mantine/modals';
import { useNavigate } from 'react-router-dom';
import { get } from '../../../../utils/api';
import AddArticleCategory from '../../../../components/articles/AddArticleCategory';
import { __ } from '../../../../utils/translate';
import { ArticleCategory } from '../../../../models/articles/articleCategory';
import LanguageContext from '../../../../components/helpers/LanguageContext';
import Icon from '../../../../components/helpers/Icon';
import { stringToHash } from '../../../../utils/helpers';

function Index() {
  const { language } = useContext(LanguageContext);
  const [articleCategories, setArticleCategories]: [{ categories: ArticleCategory[] }, any] = useState({ categories: [] });
  const getArticleCategories = async () => setArticleCategories(await get('articles/categories', {
    type: 'education',
    language
  }));

  const navigate = useNavigate();

  useEffect(() => {
    getArticleCategories();
  }, [language]);

  const addArticleCategory = () => {
    openModal({
      modalId: 'add-article-category',
      title: __('Add article category'),
      size: 'xl',
      children: (
        <AddArticleCategory
          type="education"
          onSubmit={() => {
            closeModal('add-article-category');
            getArticleCategories();
          }}
        />
      )
    });
  };

  return (
    <div className="articles/categories-wrapper p-rel">
      <Container px="md" py="lg" size="lg">
        <Flex justify="space-between" align="center" mb="sm">
          <Title order={1}>{__('Article categories')}</Title>
          <Button onClick={addArticleCategory}>
            <Icon wrapper={false} name="plus" library="fas" />
          </Button>
        </Flex>
        <Paper shadow="sm" p="md">

          <Table highlightOnHover striped horizontalSpacing="md" verticalSpacing="md">
            <thead>
              <tr>
                <th><Text>{__('Name')}</Text></th>
                <th><Text align="center">{__('Articles')}</Text></th>
                <th><Text align="right">{__('Created')}</Text></th>
              </tr>
            </thead>
            <tbody>
              {articleCategories.categories.map((articleCategory) => (
                <tr key={articleCategory.id} onClick={() => navigate(`/support/articles/categories/${articleCategory.id}`)}>
                  <td align="left">
                    <Badge
                      size="lg"
                      variant="gradient"
                      gradient={{ from: stringToHash(articleCategory.name), to: stringToHash(articleCategory.id) }}
                    >
                      {articleCategory.name}
                    </Badge>
                  </td>
                  <td align="center">{articleCategory.articles.length}</td>
                  <td align="right">{new Date(articleCategory.created_at).toLocaleDateString()}</td>
                </tr>
              ))}

            </tbody>
          </Table>
        </Paper>
      </Container>
    </div>
  );
}

export default Index;
