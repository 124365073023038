import {
  Checkbox, NumberInput, Stack, TextInput
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { useEffect, useState } from 'react';
import { ArticleChapterBlockVideo as ArticleChapterBlockVideoModel } from '../../../models/articles/articleChapterBlockVideo';
import { __ } from '../../../utils/translate';

function ArticleChapterBlockVideo({ initialValues, submit }: { initialValues: ArticleChapterBlockVideoModel, submit: Function }) {
  const [title, setTitle] = useState(initialValues?.title || '');
  const [videoId, setVideoId] = useState(initialValues?.videoId || 0);
  const [startTime, setStartTime] = useState(initialValues?.startTime || 0);
  const [endTime, setEndTime] = useState(initialValues?.endTime || 0);
  const [autoplay, setAutoplay] = useState(initialValues?.autoplay || false);
  const [muted, setMuted] = useState(initialValues?.muted || false);
  const [loop, setLoop] = useState(initialValues?.loop || false);

  useEffect(() => {
    submit({
      title, videoId, startTime, endTime, autoplay, muted, loop
    });
  }, [title, videoId, startTime, endTime, autoplay, muted, loop]);

  return (
    <Stack className="video-wrapper">
      <TextInput
        placeholder={__('Title')}
        value={title}
        onChange={(event) => setTitle(event.currentTarget.value)}
        label={__('Title')}
        mb="lg"
      />
      <TextInput
        required
        label={__('Vimeo video id')}
        placeholder={__('372824458')}
        value={videoId}
        onChange={(event) => setVideoId(Number(event.currentTarget.value))}
        mb="lg"
      />
      <NumberInput
        label={__('Start time')}
        min={0}
        value={startTime}
        onChange={(value) => setStartTime(Number(value))}
        mb="lg"
      />
      <NumberInput
        label={__('End time')}
        min={1}
        value={endTime}
        onChange={(value) => setEndTime(Number(value))}
        mb="lg"
      />

      <Checkbox
        label={__('Loop')}
        checked={loop}
        onChange={(event) => setLoop(event.currentTarget.checked)}
        mb="lg"
      />
      <Checkbox
        label={__('Mute')}
        checked={muted}
        onChange={(event) => setMuted(event.currentTarget.checked)}
        mb="lg"
      />
      <Checkbox
        label={__('Autoplay')}
        checked={autoplay}
        onChange={(event) => setAutoplay(event.currentTarget.checked)}
        mb="lg"
      />
    </Stack>
  );
}

export default ArticleChapterBlockVideo;
