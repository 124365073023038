import {
  Container, Title, Paper, Table, Button, Text, Flex
} from '@mantine/core';
import { closeModal, openModal } from '@mantine/modals';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { MarketingPlan } from '../../../../models/marketingplan/MarketingPlan';
import { __ } from '../../../../utils/translate';
import { MarketingPlanGroup } from '../../../../models/marketingplan/MarketingPlanGroup';
import { get } from '../../../../utils/api';
import EditGroup from '../../../../components/marketingplans/EditGroup';
import Icon from '../../../../components/helpers/Icon';
import EditPlan from '../../../../components/marketingplans/EditPlan';

function Group() {
  const navigate = useNavigate();
  const { marketingPlanGroupId } = useParams() as { marketingPlanGroupId: string };

  const [group, setGroup]: [{ marketing_plans_group: MarketingPlanGroup }, any] = useState({
    marketing_plans_group: {
      id: '',
      name: '',
      marketing_plans: [],
      language: 'swe'
    }
  });
  const getGroup = async () => setGroup(await get(`marketing-plans/groups/${marketingPlanGroupId}`));

  useEffect(() => {
    getGroup();
  }, []);

  const editGroup = () => {
    openModal({
      modalId: 'edit-group',
      title: __('Edit marketingplan group'),
      children: (
        <EditGroup
          marketingPlanGroupId={marketingPlanGroupId as string}
          onSubmit={() => {
            closeModal('edit-group');
            getGroup();
          }}
          onDelete={() => {
            closeModal('edit-group');
            navigate('/marketinghub/marketingplans/groups');
          }}
        />
      )
    });
  };

  const editMarketingPlan = (marketingPlanId:string) => {
    openModal({
      modalId: 'edit-marketingplan',
      title: __('Edit marketingplan'),
      size: 'lg',
      children: (
        <EditPlan
          marketingPlanId={marketingPlanId}
          onSubmit={() => {
            closeModal('edit-marketingplan');
            getGroup();
          }}
          onDelete={() => {
            closeModal('edit-marketingplan');
            getGroup();
          }}
        />
      )
    });
  };

  return (
    <div className="marketingplans-wrapper">
      <Container px="md" py="lg" size="lg">
        <Flex
          mb="sm"
          justify="space-between"
          align="center"
        >
          <Title order={1}>{ group.marketing_plans_group.name }</Title>
          <Button onClick={editGroup}>
            <Icon wrapper={false} name="edit" />
          </Button>
        </Flex>
        <div>
          <Title order={2}>{__('Marketingplans')}</Title>
          <Paper shadow="sm" p="md" mt="md">
            <Table highlightOnHover striped horizontalSpacing="md" verticalSpacing="md">
              <thead>
                <tr>
                  <th>{__('Name')}</th>
                  <th><Text align="right">{__('Updated')}</Text></th>
                  <th style={{ width: '0px' }}><Text align="center">#</Text></th>
                </tr>
              </thead>
              <tbody>
                {group.marketing_plans_group.marketing_plans.map((item: MarketingPlan) => (
                  <tr key={item.id}>
                    <td onClick={() => navigate(`/marketinghub/marketingplans/${item.id}`)}>
                      {item.name}
                    </td>
                    <td onClick={() => navigate(`/marketinghub/marketingplans/${item.id}`)}>
                      <Text align="right">{item.updated_at.toLocaleDateString('sv-se')}</Text>
                    </td>
                    <td aria-label="edit">
                      <Button onClick={() => editMarketingPlan(item.id)}>
                        <Icon wrapper={false} name="edit" />
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Paper>
        </div>
      </Container>
    </div>
  );
}

export default Group;
