import { useContext, useEffect, useState } from 'react';
import {
  Button, Container, Divider, Flex, Stack, Title
} from '@mantine/core';
import { closeModal, openModal, useModals } from '@mantine/modals';
import LanguageContext from '../../../components/helpers/LanguageContext';
import { __ } from '../../../utils/translate';
import PartnerList from '../../../components/partners/PartnerList';
import AddPartner from '../../../components/partners/AddPartner';
import Icon from '../../../components/helpers/Icon';

function Index() {
  const { language } = useContext(LanguageContext);
  const [partnerListKey, setPartnerListKey] = useState(0);

  const reloadPartnerList = () => {
    closeModal('add-partner');
    setPartnerListKey(partnerListKey + 1);
  };

  const addPartner = () => {
    openModal({
      modalId: 'add-partner',
      title: __('Add partner'),
      size: 'xl',
      children: (
        <AddPartner
          onSubmit={() => {
            reloadPartnerList();
          }}
        />
      ),
      onClose: () => {
        reloadPartnerList();
      }
    });
  };

  return (
    <div className="partners-wrapper">
      <Container p="md" size="xl">
        <Flex mb="sm" justify="space-between" align="center">
          <Title order={1}>{__('Partners')}</Title>
          <Button onClick={addPartner}>
            <Icon wrapper={false} name="plus" />
          </Button>
        </Flex>
        <PartnerList key={partnerListKey} country={language} />
      </Container>
    </div>
  );
}

export default Index;
