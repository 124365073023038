import {
  Box, Skeleton, Stack, Text
} from '@mantine/core';
import { ReactElement } from 'react';
import { __ } from '../../utils/translate';

function ListHelper({
  items, loading, skeleton, empty, children
}: { items: any[], loading?: boolean, skeleton?: ReactElement, empty?: any, children: any }) {
  let main = null;
  if (loading) {
    main = skeleton;
  } else if (items?.length === 0) {
    main = (
      <Box>
        <Text>{empty}</Text>
      </Box>
    );
  } else {
    main = (
      <Stack>
        {items.map((item, index) => (
          <Box key={item.id}>
            {children(item, index)}
          </Box>
        ))}
      </Stack>
    );
  }
  return (
    <div className="list-helper-wrapper">
      {main}
    </div>
  );
}
ListHelper.defaultProps = {
  loading: false,
  skeleton: (
    <Stack>
      <Skeleton height={35} />
      <Skeleton height={35} />
      <Skeleton height={35} />
    </Stack>),
  empty: __('No items found')
};
export default ListHelper;
