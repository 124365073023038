import { Stack, Text, TextInput } from '@mantine/core';
import { RichTextEditor } from '@mantine/rte';
import { useEffect, useState } from 'react';
import { ArticleChapterBlockText as ArticleChapterBlockTextModel } from '../../../models/articles/articleChapterBlockText';
import { __ } from '../../../utils/translate';
import AdvancedSettings from '../../helpers/AdvancedSettings';

function ArticleChapterBlockText({ initialValues, submit }: { initialValues: ArticleChapterBlockTextModel, submit: Function }) {
  const [title, setTitle] = useState(initialValues.title ?? '');
  const [text, setText] = useState(initialValues.text ?? '');
  const [columns, setColumns] = useState(initialValues.columns ?? 1);

  useEffect(() => {
    submit({ title, text, columns });
  }, [title, text, columns]);

  return (
    <Stack className="text-wrapper">
      <TextInput
        placeholder={__('Title')}
        value={title}
        onChange={(event) => setTitle(event.currentTarget.value)}
        label={__('Title')}
      />

      <div>
        <Text c="dimmed" size="sm">{__('Use [url article_id="article_id"] to link to an article. Use a local url (/users/start) to link to anywhere in wondr.')}</Text>
        <RichTextEditor
          value={text}
          onChange={(value) => setText(value)}
          sticky
          stickyOffset={155}
          controls={[
            ['bold', 'strike', 'italic', 'underline', 'link', 'clean'],
            ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'],
            ['blockquote', 'sub', 'sup'],
            ['unorderedList', 'orderedList'],
            ['alignCenter', 'alignLeft', 'alignRight'],
            ['code', 'codeBlock']
          ]}
        />
      </div>
      <AdvancedSettings>
        <TextInput
          placeholder={__('Columns')}
          value={columns}
          onChange={(event) => setColumns(Number(event.currentTarget.value))}
          type="number"
          min={1}
          max={4}
          label={__('Number of columns')}
          description={__('The text will be split into the number of columns you choose. But minumum with is 250px / column.')}
        />
      </AdvancedSettings>
    </Stack>
  );
}

export default ArticleChapterBlockText;
