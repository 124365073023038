import {
  Title, Container, Stack, Grid
} from '@mantine/core';
import { __ } from '../../utils/translate';
import Widget from '../../components/helpers/Widget';

function Index() {
  return (
    <div className="marketinghub-wrapper">
      <Container px="md" py="lg" size="lg">
        <Title order={1} mb="md">{__('Support')}</Title>
        <Stack>
          <div className="articles-wrapper">
            <Title order={2} mb="sm">{__('Articles')}</Title>
            <Grid mb="sm">
              <Grid.Col span={4}>
                <Widget
                  icon="newspaper"
                  to="/support/articles"
                  title={__('Articles')}
                  controller="articles"
                  type="Articles"
                  filter={{
                    type: 'education'
                  }}
                />
              </Grid.Col>
              <Grid.Col span={4}>
                <Widget
                  icon="shapes"
                  to="/support/articles/categories"
                  title={__('Categories')}
                  controller="articles"
                  type="ArticlesCategories"
                  filter={{
                    type: 'education'
                  }}
                />
              </Grid.Col>
              <Grid.Col span={4}>
                <Widget
                  icon="tags"
                  to="/support/articles/tags"
                  title={__('Tags')}
                  controller="articles"
                  type="ArticlesTags"
                  filter={{
                    type: 'education'
                  }}
                />
              </Grid.Col>
            </Grid>
          </div>
        </Stack>
      </Container>
    </div>
  );
}

export default Index;
