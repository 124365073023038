import {
  Button, TextInput
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { createRef, useState } from 'react';
import { post } from '../../utils/api';
import { handleResponceErrors } from '../../utils/helpers';
import { __ } from '../../utils/translate';
import ImageHandle from '../helpers/ImageHandle';

function AddImageGroup({ onSubmit }: { onSubmit: () => void }) {
  const [loading, setLoading] = useState(false);
  const form = useForm({
    initialValues: {
      name: '',
      image_id: ''
    }
  });

  const imageRef = createRef<any>();

  const submit = async (data: any) => {
    setLoading(true);

    const images = await imageRef.current.submit();
    data.image_id = images[0] ? images[0] : null;

    const resp = await post('image-groups/add', data);
    setLoading(false);

    if (resp.status === 200) {
      if (onSubmit) {
        onSubmit();
      }
    } else {
      const errors = handleResponceErrors(resp.error);
      form.setErrors(errors);
    }
  };

  return (
    <form onSubmit={form.onSubmit((data) => submit(data))}>
      <TextInput
        required
        label={__('Name')}
        {...form.getInputProps('name')}
        mb="lg"
      />

      <ImageHandle
        ref={imageRef}
      />

      <Button mt="md" fullWidth type="submit" loading={loading}>
        {
          loading ? __('Saving...') : __('Save')
        }
      </Button>
    </form>
  );
}

export default AddImageGroup;
