import { Container, Stack, Title } from '@mantine/core';
import { useContext } from 'react';
import HolidayList from '../components/economy/HolidayList';
import LanguageContext from '../components/helpers/LanguageContext';
import { __ } from '../utils/translate';

function Economy() {
  const { language, countryOptions } = useContext(LanguageContext);

  return (
    <div className="economy-wrapper">
      <Container p="md" size="xl">
        <Title order={1} mb="md">{__('Economy')}</Title>
        <Stack>
          {
            language
              ? (
                <div>
                  <Title order={2} my="md">{__('Holiday list')}</Title>
                  <HolidayList country={language} />
                </div>
              )
              : countryOptions.map((country) => (
                <div key={country.value}>
                  <Title order={2} my="md">{`${__('Holiday list')} - ${country.label}`}</Title>
                  <HolidayList country={country.value} />
                </div>
              ))
          }
        </Stack>
      </Container>
    </div>
  );
}

export default Economy;
