import {
  Badge, Button, Container, Flex, Text, Title
} from '@mantine/core';
import { useModals } from '@mantine/modals';
import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Archive from '../../../components/helpers/Archive';
import Icon from '../../../components/helpers/Icon';
import AddPlan from '../../../components/marketingplans/AddPlan';
import { MarketingPlan } from '../../../models/marketingplan/MarketingPlan';
import { stringToHash } from '../../../utils/helpers';
import { __ } from '../../../utils/translate';

function Index() {
  const modals = useModals();
  const marketingPlanList = useRef({ load: () => { } });
  const navigate = useNavigate();

  const addPlan = () => {
    const id = modals.openModal({
      title: __('Add marketingplan'),
      children: (
        <AddPlan
          onSubmit={() => {
            modals.closeModal(id);
            marketingPlanList.current.load();
          }}
        />
      )
    });
  };

  return (
    <div className="marketinghub-wrapper">
      <Container px="md" py="lg" size="lg">
        <Flex
          mb="sm"
          justify="space-between"
          align="center"
        >
          <Title order={1}>{__('Marketingplans')}</Title>
          <Button onClick={addPlan}>
            <Icon wrapper={false} name="plus" library="fas" />
          </Button>
        </Flex>
        <Archive
          limit={20}
          ref={marketingPlanList}
          request="marketing-plans"
          model="marketing_plans"
          order={{ 'MarketingPlans.updated_at': 'ASC' }}
          columns={[
            { key: 'name', label: __('Name') },
            { key: 'published_at', label: __('Public') },
            { key: 'marketing_plans_group', label: __('Groups') },
            { key: 'created_at', label: __('Created') },
            { key: 'updated_at', label: __('Updated'), textAlign: 'right' }
          ]}
          languageType="language"
        >
          {(item: MarketingPlan) => (
            <tr key={item.id} onClick={() => navigate(`/marketinghub/marketingplans/${item.id}`)}>
              <td>{item.name}</td>
              <td>{item.published_at ? __('Yes') : __('No')}</td>
              <td align="left">
                {item.marketing_plans_group ? (
                  <Badge
                    onClick={(e) => {
                      e.stopPropagation();
                      if (item.marketing_plans_group) {
                        navigate(`/marketinghub/marketingplans/groups/${item.marketing_plans_group.id}?language=${item.marketing_plans_group.language}`);
                      }
                    }}
                    variant="gradient"
                    gradient={{ from: stringToHash(item.marketing_plans_group.id), to: stringToHash(item.marketing_plans_group.name) }}
                  >
                    {item.marketing_plans_group.name}
                  </Badge>
                )
                  : null}
              </td>
              <td>{item.created_at.toLocaleDateString('sv-se')}</td>
              <td><Text align="right">{item.updated_at.toLocaleDateString('sv-se')}</Text></td>
            </tr>
          )}
        </Archive>
      </Container>
    </div>
  );
}

export default Index;
