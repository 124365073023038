import { Button, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useEffect, useState } from 'react';
import { get, post } from '../../utils/api';
import { __ } from '../../utils/translate';

function Login({ onChange }: { onChange: (token: string) => void }) {
  const form = useForm({
    initialValues: {
      email: '',
      password: ''
    }
  });

  const submit = async (data: {email: string, password: string}) => {
    const request = await post('auth/login', {
      email: data.email,
      password: data.password
    });

    if (!request.success) {
      form.setErrors({ password: request.error });
    }

    onChange(request.token);
  };

  return (
    <form onSubmit={form.onSubmit((values) => submit(values))}>
      <TextInput
        label={__('Email')}
        placeholder={__('Email')}
        required
        {...form.getInputProps('email')}
        mb="xl"
      />

      <TextInput
        label={__('Password')}
        placeholder={__('Password')}
        required
        type="password"
        {...form.getInputProps('password')}
        mb="xl"
      />
      <Button fullWidth type="submit">
        {__('Login')}
      </Button>
    </form>
  );
}

export default Login;
