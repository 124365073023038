import { IconName, library } from '@fortawesome/fontawesome-svg-core';
import { fad } from '@fortawesome/pro-duotone-svg-icons';
import {
  SimpleGrid, TextInput, UnstyledButton
} from '@mantine/core';
import { closeModal, openModal } from '@mantine/modals';
import { useEffect, useState } from 'react';
import { __ } from '../../utils/translate';
import Icon from './Icon';

function Picker({ onChange }: { onChange: Function}) {
  // add all icons from fontawesome
  useEffect(() => {
    library.add(fad);
  }, []);

  const [search, setSearch] = useState<string>('');

  const searchIcons = () => {
    const icons = (Object.keys((library as any).definitions.fad) as IconName[]).filter((icon) => icon.toLocaleLowerCase().includes(search.toLocaleLowerCase()));
    return icons;
  };

  return (
    <div>
      <TextInput
        placeholder={__('Search icon')}
        value={search}
        onChange={(e: any) => setSearch(e.target.value)}
      />
      {searchIcons().length > 0 ? (
        search.length > 0 && (
          <SimpleGrid cols={4}>
            {searchIcons().map((icon) => (
              <UnstyledButton
                onClick={() => onChange(icon)}
                sx={(theme) => ({
                  textAlign: 'center',
                  padding: '1rem',
                  marginTop: '1rem',
                  cursor: 'pointer',
                  borderRadius: '0.5rem',
                  backgroundColor: theme.colors.dark[5],
                  '&:hover': {
                    backgroundColor: theme.colors.dark[3]
                  }
                })}
              >
                <Icon name={icon} wrapper={false} size={48} />
              </UnstyledButton>
            ))}
          </SimpleGrid>
        )
      ) : (
        <div className="no-icons-found">
          {__('No icons found')}
        </div>
      )}
    </div>
  );
}

function IconPicker({ value, onChange }: { value: IconName, onChange: Function }) {
  const pickerModal = () => {
    openModal({
      modalId: 'select-icon',
      title: __('Select icon'),
      size: 'lg',
      children: (
        <Picker
          onChange={(v: any) => {
            onChange(v);
            closeModal('select-icon');
          }}
        />
      )
    });
  };

  return (
    <UnstyledButton
      className="icon-picker"
      onClick={() => pickerModal()}
      sx={(theme) => ({
        textAlign: 'center',
        cursor: 'pointer',
        borderRadius: '50%',
        width: '3rem',
        height: '3rem',
        border: `1px solid ${theme.colors.dark[4]}`,
        backgroundColor: theme.colors.dark[6]
      })}
    >
      {value && (
        <Icon name={value} wrapper={false} size={32} />
      )}
    </UnstyledButton>
  );
}

export default IconPicker;
