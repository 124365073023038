import {
  Container, Flex, Title, Button, Group, Badge, Text, Tooltip, MultiSelect
} from '@mantine/core';
import {
  useContext, useEffect, useRef, useState
} from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocalStorage } from '@mantine/hooks';
import { IconName } from '@fortawesome/fontawesome-svg-core';
// import AddCampaign from '../../../components/campaigns/AddCampaign';
import Icon from '../../../components/helpers/Icon';
import { Campaign } from '../../../models/campaigns/Campaign';
import { __ } from '../../../utils/translate';
import Archive from '../../../components/helpers/Archive';
import { CampaignGroup } from '../../../models/campaigns/CampaignGroup';
import { stringToHash } from '../../../utils/helpers';
import { get } from '../../../utils/api';
import { CampaignTag } from '../../../models/campaigns/CampaignTag';
import LanguageContext from '../../../components/helpers/LanguageContext';

function Index() {
  const list = useRef({ load: () => { } });
  const navigate = useNavigate();
  const [campaignTypes, setCampaignTypes] = useLocalStorage<'wireframe' | 'content' | 'webpage'>({ key: 'campaign-types', defaultValue: 'content' });

  const { language } = useContext(LanguageContext);

  const campaignTypesOptions = {
    content: 'images',
    wireframe: 'table-layout',
    webpage: 'browser'
  } as Record<'wireframe' | 'content' | 'webpage', IconName>;

  const tooltipNiceName = {
    content: __('Content'),
    wireframe: __('Wireframe'),
    webpage: __('Webpage')
  } as Record<'wireframe' | 'content' | 'webpage', string>;

  /* const addCampaign = () => { // Add from wondr instead
    openModal({
      modalId: 'add-campaign',
      title: __('Add campaign'),
      children: (
        <AddCampaign
          onSubmit={() => {
            closeModal('add-campaign');
            list.current.load();
          }}
        />
      )
    });
  }; */

  const [selectedCampaignGroups, setSelectedCampaignGroups] = useState<string[]>([]);
  const [campaignGroups, setCampaignGroups] = useState<{campaignGroups : CampaignGroup[]}>({ campaignGroups: [] });
  const getCampaignGroups = async () => setCampaignGroups(await get('campaign-groups', { limit: 1000, country: language }));

  const campaignGroupsOptions = campaignGroups.campaignGroups.map((group) => ({
    label: group.name,
    value: group.id
  }));

  const [selectedCampaignTags, setSelectedCampaignTags] = useState<string[]>([]);
  const [campaignTags, setCampaignTags] = useState<{campaign_tags : CampaignTag[]}>({ campaign_tags: [] });
  const getCampaignTags = async () => setCampaignTags(await get('campaigns/tags', { limit: -1, language }));

  const campaignTagsOptions = campaignTags.campaign_tags.map((tag) => ({
    label: tag.name,
    value: tag.id
  }));

  useEffect(() => {
    getCampaignGroups();
    getCampaignTags();
  }, [language]);

  return (
    <div className="campaigns-wrapper">
      <Container px="md" py="lg" size="lg">
        <Flex
          mb="sm"
          justify="space-between"
          align="center"
        >
          <Title order={1}>{__('Templates')}</Title>
          <Group>
            <Button.Group>
              {(Object.keys(campaignTypesOptions) as ('wireframe' | 'content' | 'webpage')[]).map((type: 'wireframe' | 'content' | 'webpage') => (
                <Tooltip key={type} label={tooltipNiceName[type]} color="primary" withArrow position="bottom">
                  <Button onClick={() => setCampaignTypes(type)} variant={campaignTypes === type ? 'filled' : 'outline'}>
                    <Icon wrapper={false} name={campaignTypesOptions[type]} />
                  </Button>
                </Tooltip>
              ))}
            </Button.Group>
          </Group>
        </Flex>

        <div className="campaign-wrapper">
          <Archive
            ref={list}
            limit={100}
            request="campaigns"
            model="campaigns"
            order={{ title: 'ASC' }}
            conditions={{
              type: campaignTypes
            }}
            columns={[
              { key: 'title', label: __('Title') },
              { key: 'campaign_groups', label: __('Groups') },
              { key: 'campaign_tags', label: __('Tags') },
              { key: 'created', label: __('Created'), textAlign: 'right' },
              { key: 'published_at', label: __('Published'), textAlign: 'right' }
            ]}
            extraQuery={{
              master: true,
              ...(selectedCampaignGroups.length > 0 ? { groups: selectedCampaignGroups } : {}),
              ...(selectedCampaignTags.length > 0 ? { tags: selectedCampaignTags } : {})
            }}
            extraSearchOptions={(
              <Group grow>
                <MultiSelect
                  placeholder={__('Select groups')}
                  data={campaignGroupsOptions}
                  value={selectedCampaignGroups}
                  onChange={(value) => setSelectedCampaignGroups(value)}
                />
                <MultiSelect
                  placeholder={__('Select tags')}
                  data={campaignTagsOptions}
                  value={selectedCampaignTags}
                  onChange={(value) => setSelectedCampaignTags(value)}
                />
              </Group>
            )}
          >
            {(item: Campaign) => (
              <tr key={item.id} onClick={() => navigate(`/marketinghub/campaigns/${item.id}`)}>
                <td>{item.title}</td>
                <td>
                  <Group>
                    {item.campaign_groups?.map((group) => (
                      <Badge
                        key={group.id}
                        gradient={{ from: stringToHash(group.id), to: stringToHash(group.name) }}
                        onClick={(e) => {
                          e.stopPropagation();
                          navigate(`/marketinghub/campaigns/groups/${group.id}`);
                        }}
                      >
                        {group.name}
                      </Badge>
                    ))}
                  </Group>
                </td>
                <td>
                  <Group>
                    {item.campaign_tags?.map((tag) => (
                      <Badge
                        key={tag.id}
                        gradient={{ from: stringToHash(tag.id), to: stringToHash(tag.name) }}
                        onClick={(e) => {
                          e.stopPropagation();
                          navigate(`/marketinghub/campaigns/tags/${tag.id}`);
                        }}
                      >
                        {tag.name}
                      </Badge>
                    ))}
                  </Group>
                </td>
                <td><Text align="right">{item.created.toLocaleDateString('sv-se')}</Text></td>
                <td><Text align="right">{item.published_at ? item.published_at.toLocaleDateString('sv-se') : '-'}</Text></td>
              </tr>
            )}
          </Archive>
        </div>
      </Container>
    </div>
  );
}

export default Index;
