import {
  Button, Container, Flex, Group, Paper, Stack, Title, Text, Box, Badge, Grid, BackgroundImage
} from '@mantine/core';
import { useModals } from '@mantine/modals';
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import AddDate from '../../../components/marketingplans/AddDate';
import { MarketingPlan as MarketingPlanModel } from '../../../models/marketingplan/MarketingPlan';
import { MarketingPlanDate } from '../../../models/marketingplan/MarketingPlanDate';
import { get } from '../../../utils/api';
import { __ } from '../../../utils/translate';
import Event from '../../../components/marketingplans/Event';
import Icon from '../../../components/helpers/Icon';
import EditPlan from '../../../components/marketingplans/EditPlan';
import LanguageContext from '../../../components/helpers/LanguageContext';
import { stringToHash } from '../../../utils/helpers';

function MarketingPlan() {
  const { marketingPlanId } = useParams<{ marketingPlanId: string }>();
  const modals = useModals();
  const navigate = useNavigate();
  const { languageOptions } = useContext(LanguageContext);

  const [marketingPlan, setMarketingPlan] = useState<{ marketing_plan: MarketingPlanModel }>({
    marketing_plan: {
      id: '',
      name: '',
      description: '',
      language: 'swe',
      updated_at: new Date(),
      created_at: new Date(),
      published_at: null
    }
  });
  const getMarketingPlan = async () => setMarketingPlan(await get(`/marketing-plans/${marketingPlanId}`));

  const formatDates = () => {
    const result = {} as Record<string | number, Record<string | number, MarketingPlanDate[]>>;

    const dates = marketingPlan.marketing_plan.marketing_plans_dates;

    if (dates) {
      dates.forEach((date) => {
        const eventDate = new Date(date.date);

        if (!result[eventDate.getFullYear()]) {
          result[eventDate.getFullYear()] = {};
        }

        if (!result[eventDate.getFullYear()][eventDate.getMonth()]) {
          result[eventDate.getFullYear()][eventDate.getMonth()] = [];
        }

        result[eventDate.getFullYear()][eventDate.getMonth()].push(date);
      });
    }

    return result;
  };

  const timeline = formatDates();

  useEffect(() => {
    getMarketingPlan();
  }, []);

  const addDate = () => {
    const id = modals.openModal({
      title: __('Add marketingplan date'),
      children: (
        <AddDate
          marketingPlanId={marketingPlanId as string}
          onSubmit={() => {
            modals.closeModal(id);
            getMarketingPlan();
          }}
        />
      )
    });
  };

  const editPlan = () => {
    const id = modals.openModal({
      title: __('Edit marketingplan'),
      children: (
        <EditPlan
          marketingPlanId={marketingPlanId as string}
          onSubmit={() => {
            modals.closeModal(id);
            getMarketingPlan();
          }}
          onDelete={() => {
            modals.closeModal(id);
            navigate('/marketinghub/marketingplans');
          }}
        />
      )
    });
  };

  const getMonthName = (month: string): string => {
    const monthNames = [
      __('January'),
      __('February'),
      __('March'),
      __('April'),
      __('May'),
      __('June'),
      __('July'),
      __('August'),
      __('September'),
      __('October'),
      __('November'),
      __('December')
    ];

    return monthNames[month as unknown as number] ? monthNames[month as unknown as number] : '';
  };

  const imageUrlDomain = process.env.NODE_ENV === 'development' ? 'http://localhost:8076' : '';

  return (
    <div className="marketingplan-wrapper">
      <Container px="md" py="lg" size="lg">
        <Flex
          mb="sm"
          justify="space-between"
          align="center"
        >
          <Title order={1}>{marketingPlan.marketing_plan.name}</Title>
          <Group>
            <Button onClick={editPlan}>
              <Icon wrapper={false} name="edit" />
            </Button>
            <Button onClick={addDate}>
              <Icon wrapper={false} name="plus" library="fas" />
            </Button>
          </Group>
        </Flex>

        <Grid>
          <Grid.Col span={7}>
            <Paper p="md" mb="md">
              <Stack>
                <Box>
                  <Text fw="bold">{__('Description')}</Text>
                  <Text>{marketingPlan.marketing_plan.description}</Text>
                </Box>
                <Box>
                  <Text fw="bold">{__('Language')}</Text>
                  <Text>{languageOptions.find((lang) => lang.value === marketingPlan.marketing_plan.language)?.label}</Text>
                </Box>
                <Box>
                  <Text fw="bold">{__('Published')}</Text>
                  <Text>{marketingPlan.marketing_plan.published_at ? __('Yes') : __('No')}</Text>
                </Box>
                <Box>
                  <Text fw="bold">{__('Group')}</Text>
                  {marketingPlan.marketing_plan.marketing_plans_group && (
                    <Badge
                      onClick={() => navigate(`/marketinghub/marketingplans/groups/${marketingPlan.marketing_plan.marketing_plans_group?.id}`)}
                      gradient={{ from: stringToHash(marketingPlan.marketing_plan.marketing_plans_group.id), to: stringToHash(marketingPlan.marketing_plan.marketing_plans_group.name) }}
                    >
                      {marketingPlan.marketing_plan.marketing_plans_group.name}
                    </Badge>
                  )}
                </Box>
              </Stack>
            </Paper>
          </Grid.Col>
          <Grid.Col span={5} mb="md">
            <BackgroundImage
              radius="lg"
              src={`${imageUrlDomain}/images/view/${marketingPlan.marketing_plan.image_id}`}
              h="100%"
            />
          </Grid.Col>
        </Grid>

        {Object.keys(timeline).map((year: string) => (
          <Paper className="year-wrapper" key={year} p="md" mb="md">
            <Title py="sm">{year}</Title>
            <Stack>
              {Object.keys(timeline[year]).map((month: string) => (
                <Paper className="month-wrapper" key={month} p="md" bg="dark">
                  <Title py="sm" order={2}>{getMonthName(month)}</Title>
                  <Stack>
                    {timeline[year][month].map((date) => (
                      <Event
                        date={date}
                        key={date.id}
                        onSubmit={() => getMarketingPlan()}
                        onDelete={() => getMarketingPlan()}
                      />
                    ))}
                  </Stack>
                </Paper>
              ))}
            </Stack>
          </Paper>
        ))}
      </Container>
    </div>
  );
}

export default MarketingPlan;
