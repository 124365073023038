import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faArchive, faArrowRotateLeft, faBrowser, faCalendar, faCheck, faChevronLeft,
  faChevronRight, faCircleDot, faCircleExclamation, faClose, faCoffeePot, faColumns, faComputerMouse, faDown,
  faEdit, faExclamation, faEye, faHandshake, faHashtag, faImage, faImages, faLink, faList, faListOl,
  faListUl, faMultiply, faNewspaper, faPen, faPhone, faQuestion, faSackDollar, faSave, faShapes, faSpinnerThird, faTableLayout, faTags, faText, faThList, faUp, faUpload, faVideo, faW, faArrowUp, faArrowDown, faArrowLeft, faArrowRight
} from '@fortawesome/pro-duotone-svg-icons';

import {
  faPlus, faXmark
} from '@fortawesome/free-solid-svg-icons';

library.add(
  faListOl,
  faListUl,
  faUp,
  faDown,
  faEdit,
  faClose,
  faChevronLeft,
  faChevronRight,
  faMultiply,
  faSpinnerThird,
  faUpload,
  faImage,
  faPen,
  faText,
  faVideo,
  faQuestion,
  faComputerMouse,
  faList,
  faCircleExclamation,
  faSackDollar,
  faTags,
  faNewspaper,
  faShapes,
  faArrowUp,
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faPhone,
  faCoffeePot,
  faCalendar,
  faHashtag,
  faThList,
  faArchive,
  faBrowser,
  faTableLayout,
  faImages,
  faW,
  faEye,
  faLink,
  faHandshake,
  faExclamation,
  faHandshake,
  faCheck,
  faSave,
  faCircleDot,
  faColumns,
  faArrowRotateLeft
);

library.add(
  faXmark,
  faPlus
);
