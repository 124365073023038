import {
  Container, Flex, Title, Button, MultiSelect
} from '@mantine/core';
import { closeModal, openModal } from '@mantine/modals';
import { useEffect, useRef, useState } from 'react';
import Archive from '../../../components/helpers/Archive';
import Icon from '../../../components/helpers/Icon';
import { Image as ImageModel } from '../../../models/images/image';
import { __ } from '../../../utils/translate';
import AddImage from '../../../components/images/AddImage';
import ImageCard from '../../../components/images/ImageCard';
import { ImageGroup } from '../../../models/images/imageGroup';
import { get } from '../../../utils/api';

function Index() {
  const list = useRef({ load: () => { } });

  const addImage = () => {
    openModal({
      modalId: 'add-image',
      title: __('Add image'),
      size: 'xl',
      children: (
        <AddImage
          onSubmit={() => {
            closeModal('add-image');
            list.current.load();
          }}
        />
      )
    });
  };

  const [groupSelection, setGroupSelection] = useState<string[]>([]);
  const [groups, setGroups] = useState({ imageGroups: [] } as { imageGroups: ImageGroup[] });

  const getGroups = async () => setGroups(await get('image-groups'));

  const groupOptions = groups.imageGroups.map((group) => ({
    label: group.name,
    value: group.id
  }));

  useEffect(() => {
    getGroups();
  }, []);

  return (
    <div className="images-wrapper">
      <Container px="md" py="lg" size="lg">
        <Flex
          mb="sm"
          justify="space-between"
          align="center"
        >
          <Title order={1}>{__('Images')}</Title>
          <Button onClick={addImage}>
            <Icon wrapper={false} name="plus" library="fas" />
          </Button>
        </Flex>
        <div className="image-groups-wrapper">
          <Archive
            ref={list}
            limit={32}
            request="images"
            model="Images"
            type="grid"
            gridSize={4}
            order={{ created: 'DESC' }}
            extraQuery={{
              master: true,
              ...(groupSelection.length > 0 && { groups: groupSelection })
            }}
            extraSearchOptions={(
              <MultiSelect
                data={groupOptions}
                placeholder={__('Filter on group')}
                value={groupSelection}
                onChange={(value) => setGroupSelection(value)}
              />
            )}
          >
            {(item: ImageModel) => (
              <ImageCard
                image={item}
                onEditSubmit={(type) => {
                  list.current.load();
                  if (type === 'delete') {
                    closeModal('edit-image');
                  }
                }}
              />
            )}
          </Archive>
        </div>
      </Container>
    </div>
  );
}

export default Index;
