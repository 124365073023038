import {
  Container, Stack, Title, Grid
} from '@mantine/core';
import Widget from '../components/helpers/Widget';
import { __ } from '../utils/translate';

function Index() {
  return (
    <div className="index-wrapper">
      <Container px="md" py="xl" size="lg" className="economy-wrapper">
        <Title order={1} mb="sm">{__('Economy')}</Title>
        <div className="holiday-dates-wrapper">
          <Grid>
            <Grid.Col span={4}>
              <Widget
                icon="calendar"
                to="/economy?language=swe"
                title={`${__('Holiday dates')} - ${__('Sweden')}`}
                controller="economy"
                type="HolidayDates"
                filter={{
                  country: 'swe'
                }}
              />
            </Grid.Col>
            <Grid.Col span={4}>
              <Widget
                icon="calendar"
                to="/economy?language=nor"
                title={`${__('Holiday dates')} - ${__('Norway')}`}
                controller="economy"
                type="HolidayDates"
                filter={{
                  country: 'nor'
                }}
              />
            </Grid.Col>
            <Grid.Col span={4}>
              <Widget
                icon="calendar"
                to="/economy?language=eng"
                title={`${__('Holiday dates')} - ${__('England')}`}
                controller="economy"
                type="HolidayDates"
                filter={{
                  country: 'eng'
                }}
              />
            </Grid.Col>
          </Grid>
        </div>
      </Container>

      <Container px="md" py="xl" size="lg" className="support-wrapper">
        <Title order={1} mb="sm">{__('Support')}</Title>
        <div className="articles-wrapper">
          <Grid mb="sm">
            <Grid.Col span={4}>
              <Widget
                icon="newspaper"
                to="/support/articles"
                title={__('Articles')}
                controller="articles"
                type="Articles"
                filter={{
                  type: 'education'
                }}
              />
            </Grid.Col>
            <Grid.Col span={4}>
              <Widget
                icon="shapes"
                to="/support/articles/categories"
                title={__('Categories')}
                controller="articles"
                type="ArticlesCategories"
                filter={{
                  type: 'education'
                }}
              />
            </Grid.Col>
            <Grid.Col span={4}>
              <Widget
                icon="tags"
                to="/support/articles/tags"
                title={__('Tags')}
                controller="articles"
                type="ArticlesTags"
                filter={{
                  type: 'education'
                }}
              />
            </Grid.Col>
          </Grid>
        </div>
      </Container>
    </div>
  );
}
export default Index;
