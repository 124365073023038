import { IconName } from '@fortawesome/fontawesome-svg-core';
import {
  Button, Checkbox, Divider, Flex, Group, Stack, Text, TextInput
} from '@mantine/core';
import { useListState } from '@mantine/hooks';
import { useEffect, useState } from 'react';
import { ArticleChapterBlockList as ArticleChapterBlockListModel } from '../../../models/articles/articleChapterBlockList';
import { __ } from '../../../utils/translate';
import Icon from '../../helpers/Icon';
import IconPicker from '../../helpers/IconPicker';

function ArticleChapterBlockList({ initialValues, submit }: { initialValues: ArticleChapterBlockListModel, submit: Function }) {
  const [title, setTitle] = useState(initialValues?.title || '');
  const [list, listHandler] = useListState(initialValues?.list || []);
  const [type, setType] = useState(initialValues?.type || 'ordered');
  const [hasSecondaryText, setHasSecondaryText] = useState(initialValues?.has_secondary_text || false);

  useEffect(() => {
    submit({
      title, list, type, has_secondary_text: hasSecondaryText
    });
  }, [title, list, type, hasSecondaryText]);

  const moveListItem = (index: number, offset: number) => {
    listHandler.reorder({ from: index, to: index + offset });
  };

  return (
    <Stack
      className="list-wrapper"
      sx={{
        position: 'relative',
        zIndex: 1
      }}
    >
      <TextInput
        placeholder={__('Title')}
        value={title}
        onChange={(event) => setTitle(event.currentTarget.value)}
        label={__('Title')}
        mb="lg"
      />
      <Divider />
      <div className="list-inner ">
        {list.map((item, index) => {
          const isFirst = index === 0;
          const isLast = index === list.length - 1;
          return (

            <div>
              <Group position="right">
                <Button.Group>
                  <Button
                    variant={isFirst ? 'light' : 'filled'}
                    disabled={isFirst}
                    onClick={() => moveListItem(index, -1)}
                    size="xs"
                  >
                    <Icon wrapper={false} name="up" />
                  </Button>
                  <Button
                    variant={isLast ? 'light' : 'filled'}
                    disabled={isLast}
                    onClick={() => moveListItem(index, 1)}
                    size="xs"
                  >
                    <Icon wrapper={false} name="down" />
                  </Button>
                </Button.Group>
                <Button onClick={() => listHandler.remove(index)} size="xs">
                  <Icon wrapper={false} name="xmark" library="fas" />
                </Button>
              </Group>
              <Flex align="center" py="md">
                {type === 'unordered' ? (
                  <Flex w="10%" justify="center">
                    <IconPicker
                      value={(list[index].icon || 'fa-question') as IconName}
                      onChange={(value: IconName) => {
                        listHandler.setItemProp(index, 'icon', value);

                        listHandler.applyWhere(
                          (i) => i.icon === null || i.icon === undefined,
                          (i) => ({
                            ...i,
                            icon: value
                          })
                        );
                      }}
                    />
                  </Flex>
                ) : (
                  <Text w="10%" align="center">
                    {index + 1}
                  </Text>
                )}
                <Stack w={type === 'unordered' ? '90%' : '100%'}>
                  <TextInput
                    placeholder={__('An interesting title')}
                    value={item.text}
                    onChange={(event) => listHandler.setItemProp(index, 'text', event.currentTarget.value)}
                  />
                  {hasSecondaryText && (
                  <TextInput
                    placeholder={__('An interesting description')}
                    value={item.secondary_text}
                    onChange={(event) => listHandler.setItemProp(index, 'secondary_text', event.currentTarget.value)}
                  />
                  )}
                </Stack>
              </Flex>
            </div>
          );
        })}
        <Divider mb="md" />
        <Group>
          <Button onClick={() => listHandler.append({ text: '' })}>{__('Add item')}</Button>
          <Button.Group>
            <Button variant={type === 'ordered' ? 'filled' : 'outline'} onClick={() => setType('ordered')}>
              <Icon name="list-ol" wrapper={false} />
            </Button>
            <Button variant={type === 'unordered' ? 'filled' : 'outline'} onClick={() => setType('unordered')}>
              <Icon name="list-ul" wrapper={false} />
            </Button>
          </Button.Group>

          <Checkbox
            checked={hasSecondaryText}
            onChange={(event) => setHasSecondaryText(event.currentTarget.checked)}
            label={__('Secondary text')}
          />
        </Group>
      </div>
    </Stack>
  );
}

export default ArticleChapterBlockList;
