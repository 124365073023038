import { IconName } from '@fortawesome/fontawesome-svg-core';
import {
  Group, Paper, Title, UnstyledButton
} from '@mantine/core';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { get } from '../../utils/api';
import Icon from './Icon';

function Widget({
  title, controller, type, filter, icon, to
}: {
  title: string, controller: string, type: string, filter?: Record<string, any>, icon: IconName, to: string
}) {
  const [count, setCount] = useState(0);
  const getRequest = async () => {
    const res = await get(`${controller}/count/${type}`, filter);

    if (res.status === 200) {
      setCount(res.count);
    }
  };

  useEffect(() => {
    getRequest();
  }, [filter]);

  return (
    <UnstyledButton
      component={Link}
      to={to}
    >
      <Paper
        shadow="sm"
        p="md"
        sx={(theme) => ({
          transition: '0.2s ease-in-out',
          '&:hover': {
            backgroundColor: theme.colors.dark[6]
          }
        })}
      >
        <Group>
          <Icon name={icon} size={48} />
          <div>
            <Title order={3}>{title}</Title>
            {count}
          </div>
        </Group>
      </Paper>
    </UnstyledButton>
  );
}

Widget.defaultProps = {
  filter: {}
};

export default Widget;
