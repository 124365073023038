import {
  DefaultMantineColor, MantineNumberSize, ThemeIcon, ThemeIconVariant
} from '@mantine/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconName, SizeProp } from '@fortawesome/fontawesome-svg-core';
import { on } from 'events';

function Icon({
  name = 'list',
  size,
  color,
  wrapper = true,
  spin = false,
  library = 'fad'
}: {
  name: IconName,
  size?: MantineNumberSize | SizeProp,
  color?: DefaultMantineColor | string,
  wrapper?: boolean,
  spin?: boolean,
  library?: 'fad' | 'fas'
}) {
  return (
    <FontAwesomeIcon
      icon={[library, name]}
      fixedWidth
      spin={spin}
      size={(size ?? '1x') as SizeProp}
      color={!wrapper ? color : undefined}
    />
  );
}

Icon.defaultProps = {
  size: null,
  color: 'tertiary',
  wrapper: true,
  spin: false,
  library: 'fad'
};

export default Icon;
