import { TextInput, Button } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useState, useContext } from 'react';
import { post } from '../../utils/api';
import { handleResponceErrors } from '../../utils/helpers';
import { __ } from '../../utils/translate';
import Language from '../../components/helpers/LanguageContext';
import { MarketingPlanGroup } from '../../models/marketingplan/MarketingPlanGroup';

function AddGroup({ onSubmit }: { onSubmit: (marketingPlanGroups: MarketingPlanGroup) => void }) {
  const { language } = useContext(Language);

  const [loading, setLoading] = useState(false);

  const form = useForm({
    initialValues: {
      name: ''
    }
  });

  const submit = async (props: {
    name: string;
    language?: 'swe' | 'nor' | 'eng';
  }) => {
    setLoading(true);
    const data = props;
    data.language = language as 'swe' | 'nor' | 'eng';

    const resp = await post('marketing-plans/groups', data);
    setLoading(false);

    if (resp.status === 200) {
      if (onSubmit) {
        onSubmit(resp.marketing_plan);
      }
    } else {
      const errors = handleResponceErrors(resp.error);

      form.setErrors(errors);
    }
  };

  return (
    <form onSubmit={form.onSubmit((data) => submit(data))}>
      <TextInput
        required
        label={__('Name')}
        {...form.getInputProps('name')}
        mb="lg"
      />

      <Button fullWidth type="submit" loading={loading}>
        {
          loading ? __('Saving...') : __('Save')
        }
      </Button>
    </form>
  );
}

export default AddGroup;
