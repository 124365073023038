import { createRef, useContext, useState } from 'react';
import {
  Button,
  Space,
  TextInput
  // Select
} from '@mantine/core';
import { FormErrors, useForm } from '@mantine/form';
import { post } from '../../utils/api';
import { __ } from '../../utils/translate';
import { ArticleCategory } from '../../models/articles/articleCategory';
import ImageHandle from '../helpers/ImageHandle';
import LanguageContext from '../helpers/LanguageContext';

function AddArticleCategory({ type = 'education', onSubmit }: { type: 'education', onSubmit: (articles_categories: ArticleCategory) => void }) {
  const [loading, setLoading] = useState(false);
  const { language } = useContext(LanguageContext);

  const form = useForm({
    initialValues: {
      name: '',
      thumbnail_id: null
    }
  });
  const imageRef = createRef<any>();

  const submit = async (data: { name: string, thumbnail_id: any }) => {
    setLoading(true);

    const images = await imageRef.current.submit();
    data.thumbnail_id = images[0] ? images[0] : null;

    const resp = await post('articles/categories', { ...data, type, language });
    setLoading(false);

    if (resp.status === 200) {
      if (onSubmit) {
        onSubmit(resp.articleCategory);
      }
    } else if (resp.error) {
      const errors = {} as FormErrors;

      Object.keys(resp.error).forEach((key) => {
        errors[key] = Object.values(resp.error[key]);
      });

      form.setErrors(errors);
    }
  };

  return (
    <form onSubmit={form.onSubmit((data) => submit(data))}>

      <TextInput
        required
        label={__('Name')}
        {...form.getInputProps('name')}
        mb="lg"
      />

      <ImageHandle ref={imageRef} />
      <Space h="md" />

      <Button fullWidth type="submit" loading={loading}>
        {
          loading ? __('Saving...') : __('Save')
        }
      </Button>
    </form>
  );
}

export default AddArticleCategory;
