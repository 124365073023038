import { Box } from '@mantine/core';
import { Article } from '../../models/articles/article';
import { __ } from '../../utils/translate';
import Archive from '../helpers/Archive';

function ListArticles({ onSelect }: {onSelect: (selection: any) => void}) {
  return (
    <div className="list-articles-wrapper">
      <Archive
        limit={20}
        request="articles"
        model="articles"
        type="grid"
        gridSize={1}
        order={{ 'Articles.name': 'ASC' }}
        flush
      >
        {(item: Article) => (
          <Box
            onClick={() => onSelect(item)}
            p="xs"
            sx={(theme) => ({
              borderRadius: theme.radius.sm,
              '&:hover': {
                backgroundColor: theme.colors.dark[3],
                borderColor: theme.colors.dark[3]
              }
            })}
          >
            {item.name}
          </Box>
        )}
      </Archive>
    </div>
  );
}

export default ListArticles;
