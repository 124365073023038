import {
  Card,
  Container,
  Stack,
  Text,
  Title
} from '@mantine/core';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { get } from '../../../utils/api';
import { __ } from '../../../utils/translate';

function BuildId() {
  const { id } = useParams<{ id: string }>();
  const [build, setBuild] = useState({
    id: '',
    result: '',
    consoleText: ''
  });
  const getBuildLog = async () => {
    const result = await get(`internal/buildlog/${id}`);
    if (result.status === 200) {
      setBuild(result.build);
    }
  };

  useEffect(() => {
    getBuildLog();

    setInterval(() => {
      getBuildLog();
    }, 10000);
  }, []);

  return (
    <div className="build-wrapper">
      <Container px="md" py="lg" size="lg">
        <Title order={1} mb="md">{__('Build status')}</Title>
        <Card mb="sm">
          <Stack align="center">
            <Title>{build.id}</Title>
            <Text>{build.result}</Text>
          </Stack>
        </Card>
        <Card mb="sm">
          <Title order={4}>{__('Build log')}</Title>
          <pre style={{ overflowX: 'scroll' }}>{build.consoleText}</pre>
        </Card>

      </Container>
    </div>
  );
}

export default BuildId;
