import {
  Paper, Text, Group, Divider, Button
} from '@mantine/core';
import { useModals } from '@mantine/modals';
import { useEffect, useState } from 'react';
import { MarketingPlanDate, MarketingPlanDateCampaign } from '../../models/marketingplan/MarketingPlanDate';
import { get } from '../../utils/api';
import { __ } from '../../utils/translate';
import Icon from '../helpers/Icon';
import EditDate from './EditDate';

function Date({ date, onSubmit, onDelete }: { date: MarketingPlanDate, onSubmit: (id: string) => void, onDelete: (id: string) => void}) {
  const modals = useModals();
  const [campaign, setCampaign] = useState({
    campaign: {
      title: ''
    }
  });
  const getCampaign = async (campaignId: string) => {
    const res = await get(`campaigns/view/${campaignId}`);

    if (res.campaign) {
      setCampaign(res);
    } else {
      setCampaign({
        campaign: {
          title: __('Campaign not found')
        }
      });
    }
  };

  useEffect(() => {
    if ((date.attributes as MarketingPlanDateCampaign).campaign_id) {
      getCampaign((date.attributes as MarketingPlanDateCampaign).campaign_id);
    }
  }, [(date.attributes as MarketingPlanDateCampaign).campaign_id]);

  const types = {
    campaign: __('Campaign'),
    advertisement: __('Advertisement'),
    mailing: __('Mailing'),
    post: __('Post'),
    event: __('Event'),
    custom_text: __('Custom text')
  };

  const editDate = () => {
    const id = modals.openModal({
      title: __('Edit marketingplan date'),
      children: (
        <EditDate
          id={date.id}
          onSubmit={() => {
            modals.closeModal(id);
            onSubmit(id);
          }}
          onDelete={() => {
            modals.closeModal(id);
            onDelete(id);
          }}
        />
      )
    });
  };

  return (
    <Paper>
      <Group grow>
        <Group p="md">
          <Text>{date.date.toLocaleDateString()}</Text>
          <Divider orientation="vertical" size="xs" />
          <Text>{date.attributes.event_day || types[date.type]}</Text>
          <Divider orientation="vertical" size="xs" />
          <Text>{campaign.campaign.title || date.attributes.event_name}</Text>
        </Group>
        <Group position="right" h="100%">
          <Button
            onClick={() => editDate()}
            variant="subtle"
            size="lg"
            sx={(theme) => ({
              borderRadius: '0',
              borderLeft: `1px solid ${theme.colors.dark[5]}`
            })}
          >
            <Icon name="pen" wrapper={false} />
          </Button>
        </Group>
      </Group>
    </Paper>
  );
}

export default Date;
