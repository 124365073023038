import {
  Box,
  Button,
  Divider,
  Group,
  Stack, Switch, TextInput
} from '@mantine/core';
import { closeModal, openModal } from '@mantine/modals';
import { useEffect, useState } from 'react';
import { ArticleChapterBlockButton as ArticleChapterBlockButtonModel, ButtonData } from '../../../models/articles/articleChapterBlockButton';
import { __ } from '../../../utils/translate';
import Icon from '../../helpers/Icon';
import ListArticles from '../ListArticles';

function ArticleChapterBlockButton({ initialValues, submit }: {
  initialValues: ArticleChapterBlockButtonModel | ButtonData,
  submit: Function
}) {
  const getRandomId = () => `new_${Math.random().toString(36).substr(2, 9)}`;

  const legacyButton = () => {
    if (initialValues) {
      (initialValues as ButtonData).id = getRandomId();
      return [initialValues];
    }
    return null;
  };

  const [buttons, setButtons] = useState<ButtonData[]>(
    (initialValues as ArticleChapterBlockButtonModel).buttons ?? legacyButton() ?? [{
      id: getRandomId(),
      label: '',
      text: '',
      url: '',
      color: 'primary'
    }]
  );

  const [isLink, setIsLink] = useState(true);
  const [isCenter, setIsCenter] = useState(false);

  const [title, setTitle] = useState((initialValues as ArticleChapterBlockButtonModel).title ?? '');

  const updateButton = (id: string, field: keyof ButtonData | (keyof ButtonData)[], value: any | any[]) => {
    const newButtons = [...buttons];

    if (Array.isArray(field)) {
      field.forEach((f, index) => {
        const v = Array.isArray(value) ? value[index] : value;
        newButtons[newButtons.findIndex((b) => b.id === id)][f] = v ?? '';
      });
    } else {
      newButtons[newButtons.findIndex((b) => b.id === id)][field] = value;
    }

    setButtons(newButtons);
  };

  const addButton = () => {
    setButtons([
      ...buttons,
      {
        id: getRandomId(),
        label: '',
        text: '',
        url: '',
        color: 'primary'
      }
    ]);
  };

  const removeButton = (id: string) => {
    setButtons(buttons.filter((b) => b.id !== id));
  };

  // trigger on any data change
  useEffect(() => {
    submit({
      buttons,
      title,
      isLink,
      isCenter
    });
  }, [buttons, title, isLink, isCenter]);

  const moveButton = (id: string, direction: number) => {
    const newButtons = [...buttons];
    const temp = newButtons.splice(newButtons.findIndex((b) => b.id === id), 1);
    newButtons.splice(newButtons.findIndex((b) => b.id === id) + direction, 0, temp[0]);
    setButtons(newButtons);
  };

  const toolbar = (id: string) => {
    const isFirst = buttons.findIndex((b) => b.id === id) === 0;
    const isLast = buttons.findIndex((b) => b.id === id) === buttons.length - 1;

    return (
      <Group className="toolbar-wrapper">
        <Button.Group
          sx={{
            pointerEvents: 'all',
            opacity: 1,
            cursor: 'auto'
          }}
        >
          <Button
            variant={isFirst ? 'light' : 'filled'}
            disabled={isFirst}
            onClick={() => moveButton(id, -1)}
          >
            <Icon wrapper={false} name="up" />
          </Button>
          <Button
            variant={isLast ? 'light' : 'filled'}
            disabled={isLast}
            onClick={() => moveButton(id, 1)}
          >
            <Icon wrapper={false} name="down" />
          </Button>
        </Button.Group>
        <Button
          sx={{
            pointerEvents: 'all',
            opacity: 1,
            cursor: 'auto'
          }}
          onClick={() => removeButton(id)}
        >
          <Icon wrapper={false} name="xmark" library="fas" />
        </Button>
      </Group>
    );
  };

  return (
    <Stack className="button-wrapper">
      <TextInput
        placeholder={__('Title')}
        value={title}
        onChange={(event) => setTitle(event.currentTarget.value)}
        label={__('Title')}
        mb="lg"
      />
      <Switch
        label={__('View as links')}
        checked={isLink}
        onChange={(event) => setIsLink(event.currentTarget.checked)}
        mb="lg"
        onLabel={__('Links')}
        offLabel={__('Buttons')}
      />
      <Switch
        label={__('Center align buttons/links')}
        checked={isCenter}
        onChange={(event) => setIsCenter(event.currentTarget.checked)}
        mb="lg"
        onLabel={__('Center')}
        offLabel={__('Left')}
      />
      {buttons.map((button, index) => (
        <Box
          key={button.id}
          sx={(theme) => ({
            backgroundColor: theme.colors.black[7],
            borderRadius: theme.radius.md
          })}
        >
          <Group position="right" spacing="xs" m="xs">
            {toolbar(button.id)}
          </Group>
          <Divider my={0} />
          <Stack m="xs">
            <TextInput
              label={__('Label')}
              placeholder={__('Label')}
              value={button.label}
              onChange={(event) => updateButton(button.id, 'label', event.currentTarget.value)}
            />
            <TextInput
              label={__('Button text')}
              placeholder={__('Button text')}
              value={button.text}
              onChange={(event) => updateButton(button.id, 'text', event.currentTarget.value)}
            />
            <TextInput
              placeholder={__('URL')}
              value={button.url}
              label={__('URL')}
              description={__('Use [url article_id="article_id"] to link to an article. Use a local url (/users/start) to link to anywhere in wondr.')}
              onChange={(event) => updateButton(button.id, 'url', event.currentTarget.value)}
              rightSection={(
                <Button
                  variant="subtle"
                  compact
                  onClick={() => {
                    openModal({
                      modalId: 'list-articles',
                      title: __('Select article'),
                      size: 'lg',
                      children: (
                        <ListArticles
                          onSelect={(item) => {
                            updateButton(button.id, ['url', 'text'], [`[url article_id="${item.id}"]`, item.name]);
                            closeModal('list-articles');
                          }}
                        />
                      )
                    });
                  }}
                >
                  <Icon name="link" wrapper={false} />
                </Button>
              )}
            />
          </Stack>
        </Box>
      ))}
      <Button onClick={addButton}>
        {__('Add Button')}
      </Button>
    </Stack>
  );
}

export default ArticleChapterBlockButton;
