import {
  AppShell,
  Aside,
  Box,
  Button,
  Divider,
  Flex,
  Group,
  MantineProvider,
  Navbar, ScrollArea,
  Title,
  UnstyledButton
} from '@mantine/core';
import { ModalsProvider } from '@mantine/modals';
import React, {
  ReactElement, useContext, useEffect
} from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import ReactDOM from 'react-dom/client';
import {
  HashRouter,
  Link,
  Route,
  Routes,
  useLocation
} from 'react-router-dom';
import './scss/style.scss';

import { IconName } from '@fortawesome/fontawesome-svg-core';
import Icon from './components/helpers/Icon';
import LanguageContext, { LanguagePicker, LanguageProvider } from './components/helpers/LanguageContext';
import './utils/fontawesome';
import { __ } from './utils/translate';

import Index from './routes';
import Economy from './routes/economy';
import Marketinghub from './routes/marketinghub';

import InternalBuilds from './routes/internal/builds';
import InternalBuild from './routes/internal/builds/id';

import Campaigns from './routes/marketinghub/campaigns';
import Campaign from './routes/marketinghub/campaigns/campaign';
import CampaignsGroups from './routes/marketinghub/campaigns/groups';
import CampaignsGroup from './routes/marketinghub/campaigns/groups/group';
import CampaignsTags from './routes/marketinghub/campaigns/tags';
import CampaignsTag from './routes/marketinghub/campaigns/tags/tag';
import Support from './routes/support';
import SupportArticles from './routes/support/articles';
import SupportArticle from './routes/support/articles/article';
import SupportArticleCategories from './routes/support/articles/categories';
import SupportArticleCategory from './routes/support/articles/categories/category';
import SupportArticleTags from './routes/support/articles/tags';
import SupportArticleTag from './routes/support/articles/tags/tag';

import { AuthProvider } from './components/helpers/AuthContext';
import NavigationContext, { NavigationProvider } from './components/helpers/NavigationContext';
import MarketingPlans from './routes/marketinghub/marketingplans';
import MarketingPlansGroups from './routes/marketinghub/marketingplans/groups';
import MarketingPlansGroup from './routes/marketinghub/marketingplans/groups/group';
import MarketingPlan from './routes/marketinghub/marketingplans/marketingplan';
import { flattenObject } from './utils/helpers';

import { ArticleProvider } from './components/helpers/ArticleContext';
import Partners from './routes/marketinghub/Partners/Index';
import ImageGroup from './routes/media-library/images/groups/group';
import ImageGroups from './routes/media-library/images/groups/index';
import Images from './routes/media-library/images/index';
import ImagesIndex from './routes/media-library/index';

interface navItem {
  title: string,
  icon?: IconName,
  to: string,
  component: ReactElement,
  collapsed?: boolean,
  children?: navItem[],
}

function ChildNavigation({ items, header }: { items: navItem[], header: string }) {
  const { setSubNavigation } = useContext(NavigationContext);
  const location = useLocation();

  return (
    <div>
      <Button
        size="lg"
        fullWidth
        onClick={() => setSubNavigation(null)}
        leftIcon={<Icon name="chevron-left" wrapper={false} />}
        styles={(theme) => ({
          root: {
            borderRadius: 0,
            color: theme.colors.dark[0],
            backgroundColor: 'transparent',
            borderBottom: `solid 1px ${theme.colors.dark[3]}`
          }
        })}
      >
        {header}
      </Button>
      {items.map((item) => (
        <div key={item.to}>
          <Button
            className="flex-start-buttons"
            size="md"
            fullWidth
            component={Link}
            to={item.to}
            styles={(theme) => ({
              root: {
                borderRadius: 0,
                color: theme.colors.dark[0],
                backgroundColor: location.pathname === item.to ? theme.colors.dark[6] : 'transparent'
              }
            })}
          >
            {item.title}
          </Button>
          {item.children && (
            <Box
              sx={(theme) => ({
                backgroundColor: theme.colors.black[7]
              })}
            >
              {item.children.map((i) => (
                <Button
                  key={i.to}
                  className="flex-start-buttons"
                  size="sm"
                  pl="2rem"
                  fullWidth
                  component={Link}
                  to={i.to}
                  styles={(theme) => ({
                    root: {
                      fontWeight: 500,
                      borderRadius: 0,
                      color: theme.colors.dark[0],
                      backgroundColor: location.pathname.includes(i.to) ? theme.colors.dark[6] : 'transparent'
                    }
                  })}
                >
                  {`${i.title}`}
                </Button>
              ))}
            </Box>
          )}
        </div>
      ))}
    </div>
  );
}

function App() {
  const { subNavigation, setSubNavigation, asideNavigation } = useContext(NavigationContext);
  const { setLanguage, setAllowAllLanguages } = useContext(LanguageContext);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === '/') {
      setSubNavigation(null);
    }

    if (location.search.length > 0) {
      const params = new URLSearchParams(location.search);
      if (params.get('language')) {
        setLanguage(params.get('language') as 'swe' | 'eng' | 'nor' | null);
      }
    }
    // set language
    setAllowAllLanguages(location.pathname.includes('economy'));
  }, [location]);

  const nabarItems: navItem[] = [
    {
      title: __('Economy'),
      icon: 'sack-dollar',
      to: '/economy',
      component: <Economy />
    },
    {
      title: __('Marketinghub'),
      icon: 'tags',
      to: '/marketinghub',
      component: <Marketinghub />,
      children: [
        {
          title: __('Templates'),
          to: '/marketinghub/campaigns',
          component: <Campaigns />,
          children: [
            {
              title: __('Template groups'),
              to: '/marketinghub/campaigns/groups',
              component: <CampaignsGroups />
            },
            {
              title: __('Template tags'),
              to: '/marketinghub/campaigns/tags',
              component: <CampaignsTags />
            }
          ]
        },
        {
          title: __('Marketingplans'),
          to: '/marketinghub/marketingplans',
          component: <MarketingPlans />,
          children: [
            {
              title: __('Groups'),
              to: '/marketinghub/marketingplans/groups',
              component: <MarketingPlansGroups />
            }
          ]
        },
        {
          title: __('Partners'),
          to: '/marketinghub/partners',
          component: <Partners />
        }
      ]
    },
    {
      title: __('Support'),
      icon: 'question',
      to: '/support',
      component: <Support />,
      children: [
        {
          title: __('Articles'),
          icon: 'newspaper',
          to: '/support/articles',
          component: <SupportArticles />,
          children: [
            {
              title: __('Article categories'),
              icon: 'shapes',
              to: '/support/articles/categories',
              component: <SupportArticleCategories />
            },
            {
              title: __('Article tags'),
              icon: 'tags',
              to: '/support/articles/tags',
              component: <SupportArticleTags />
            }
          ]
        }
      ]
    },
    {
      title: __('Sales'),
      icon: 'phone',
      to: '/sales',
      component: <Index />
    },
    {
      title: __('Internal'),
      icon: 'coffee-pot',
      to: '/internal/build',
      component: <InternalBuilds />
    },
    {
      title: __('Media library'),
      icon: 'image',
      to: '/media-library',
      component: <ImagesIndex />,
      children: [
        {
          title: __('Images'),
          to: '/media-library/images',
          component: <Images />,
          children: [
            {
              title: __('Image groups'),
              to: '/media-library/images/groups',
              component: <ImageGroups />
            }
          ]
        }
      ]

    }
  ];

  return (
    <AppShell
      fixed
      padding={0}
      navbarOffsetBreakpoint="sm"
      navbar={(
        <Navbar height="100%" width={{ base: 300 }} className="sidebar-wrapper">
          <Navbar.Section my="lg">
            <UnstyledButton component={Link} to="/">
              <Group position="center">
                <img src="/app/w.svg" alt="Logotype" height="40px" />
                <Title order={2} align="center">
                  {__('Mastr')}
                </Title>
              </Group>
            </UnstyledButton>
          </Navbar.Section>
          <Divider />
          <Navbar.Section grow component={ScrollArea}>
            <Flex justify="stretch" direction={subNavigation ? 'row' : 'column'}>
              <Box>
                {nabarItems.map((item) => (
                  <Button.Group className="flex-start-buttons" key={item.to}>
                    <Button
                      size="lg"
                      fullWidth
                      component={Link}
                      onClick={() => (item.children ? setSubNavigation(<ChildNavigation items={item.children as navItem[]} header={item.title} />) : setSubNavigation(null))}
                      to={item.to}
                      styles={(theme) => ({
                        root: {
                          borderRadius: 0,
                          color: theme.colors.dark[0],
                          backgroundColor: location.pathname.includes(item.to) ? theme.colors.dark[5] : 'transparent'
                        }
                      })}
                    >
                      <Group>
                        <Icon name={item.icon ?? 'question'} wrapper={false} />
                        {!subNavigation && item.title}
                      </Group>
                    </Button>
                    {item.children && !subNavigation && (
                      <Button
                        onClick={() => setSubNavigation(<ChildNavigation items={item.children as navItem[]} header={item.title} />)}
                        size="lg"
                        styles={(theme) => ({
                          root: {
                            borderRadius: 0,
                            color: theme.colors.dark[0],
                            borderLeft: `solid 1px ${theme.colors.dark[3]}`,
                            backgroundColor: 'transparent'
                          }
                        })}
                      >
                        <Icon name="chevron-right" wrapper={false} />
                      </Button>
                    )}
                  </Button.Group>
                ))}
              </Box>
              {subNavigation && (
                <Box
                  sx={(theme) => ({
                    height: 'calc(100vh - 150px)',
                    width: '100%',
                    backgroundColor: theme.colors.black[6]
                  })}
                >
                  {subNavigation}
                </Box>
              )}
            </Flex>
          </Navbar.Section>
          <Navbar.Section>
            <Divider />
            <Box p="md" pt="xs">
              <LanguagePicker />
            </Box>
          </Navbar.Section>
        </Navbar>
      )}
      aside={asideNavigation ? (
        <Aside height="100%" width={{ base: 300 }} className="aside-wrapper">
          {asideNavigation}
        </Aside>
      ) : undefined}
      styles={(theme) => ({
        main: { backgroundColor: theme.colors.dark[8] }
      })}
    >
      <Routes>
        <Route path="" element={<Index />} />
        {flattenObject(nabarItems, 'children').map((item) => (
          <Route key={item.to} path={item.to} element={item.component} />
        ))}

        <Route path="/marketinghub/marketingplans/:marketingPlanId" element={<MarketingPlan />} />
        <Route path="/marketinghub/marketingplans/groups/:marketingPlanGroupId" element={<MarketingPlansGroup />} />

        <Route path="/support/articles/categories/:articleCategoryId" element={<SupportArticleCategory />} />
        <Route path="/support/articles/tags/:articleTagId" element={<SupportArticleTag />} />
        <Route path="/support/articles/:articleId" element={<SupportArticle />} />
        <Route path="/support/articles/:articleId/:chapterId" element={<SupportArticle />} />

        <Route path="/marketinghub/campaigns/:id" element={<Campaign />} />
        <Route path="/marketinghub/campaigns/groups/:id" element={<CampaignsGroup />} />
        <Route path="/marketinghub/campaigns/tags/:id" element={<CampaignsTag />} />

        <Route path="/media-library/images/groups/:id" element={<ImageGroup />} />

        <Route path="/internal/builds/:id" element={<InternalBuild />} />
      </Routes>
    </AppShell>
  );
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <HashRouter>
      <DndProvider backend={HTML5Backend}>
        <MantineProvider
          theme={{
            colorScheme: 'dark',
            colors: {
              primary: ['#e7f7fa', '#b2e5ee', '#7dd2e3', '#47c0d7', '#12aecc', '#0e879e', '#0a6070', '#063942', '#021114'],
              black: ['#e7e8e9', '#b2b4b7', '#7c8084', '#474c52', '#111820', '#0d1319', '#090d12', '#06080a', '#020203'],
              tertiary: ['#f6e9f2', '#e1b8d4', '#cc87b7', '#b75699', '#a2257c', '#7e1d60', '#591444', '#350c28', '#10040c'],
              dark: ['#ffffff', '#3abcd5', '#1299b3', '#127e95', '#126376', '#114857', '#112d39', '#0e141b', '#000000']
            },
            primaryColor: 'primary',
            primaryShade: 5,
            fontFamily: '"open sans", sans-serif',
            headings: { fontFamily: '"Baloo 2", sans-serif' },
            components: {
              Paper: {
                styles: () => ({
                  root: {
                    borderRadius: '1.25rem'
                  }
                })
              },
              Button: {
                defaultProps: {
                  color: 'tertiary'
                },
                styles: {
                  root: {
                    borderRadius: '1.25rem'
                  }
                }
              },
              Badge: {
                defaultProps: {
                  variant: 'gradient'
                },
                styles: {
                  root: {
                    transition: 'all 0.2s ease-in-out',
                    ':hover': {
                      cursor: 'pointer',
                      opacity: 0.8,
                      transform: 'scale(1.1)'
                    }
                  }
                }
              }
            }
          }}
          withGlobalStyles
          withNormalizeCSS
        >
          <AuthProvider>
            <LanguageProvider>
              <NavigationProvider>
                <ArticleProvider>
                  <ModalsProvider
                    modalProps={{
                      closeOnClickOutside: false,
                      overlayColor: '#0a6070'
                    }}
                  >
                    <App />
                  </ModalsProvider>
                </ArticleProvider>
              </NavigationProvider>
            </LanguageProvider>
          </AuthProvider>
        </MantineProvider>
      </DndProvider>
    </HashRouter>
  </React.StrictMode>
);
