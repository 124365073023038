import { useState, useContext } from 'react';
import {
  Button, TextInput
} from '@mantine/core';
import { FormErrors, useForm } from '@mantine/form';
import { post } from '../../utils/api';
import { __ } from '../../utils/translate';
import { ArticleTag } from '../../models/articles/articleTag';
import LanguageContext from '../helpers/LanguageContext';

function AddArticleTag({ type = 'education', onSubmit }: { type: 'education', onSubmit: (articles_categories: ArticleTag) => void }) {
  const [loading, setLoading] = useState(false);
  const { language } = useContext(LanguageContext);

  const form = useForm({
    initialValues: {
      name: ''
    }
  });

  const submit = async (data: { name: string }) => {
    setLoading(true);
    const resp = await post('articles/tags', { ...data, type, language });
    setLoading(false);

    if (resp.status === 200) {
      if (onSubmit) {
        onSubmit(resp.articleCategory);
      }
    } else {
      const errors = {} as FormErrors;

      Object.keys(resp.error).forEach((key) => {
        errors[key] = Object.values(resp.error[key]);
      });

      form.setErrors(errors);
    }
  };

  return (
    <form onSubmit={form.onSubmit((data) => submit(data))}>

      <TextInput
        required
        label={__('Name')}
        {...form.getInputProps('name')}
        mb="lg"
      />

      <Button fullWidth type="submit" loading={loading}>
        {
          loading ? __('Saving...') : __('Save')
        }
      </Button>
    </form>
  );
}

export default AddArticleTag;
