import {
  Button, Checkbox, Group, Select, Textarea, TextInput
} from '@mantine/core';
import { useForm } from '@mantine/form';
import {
  createRef, useContext, useEffect, useState
} from 'react';
import { MarketingPlan } from '../../models/marketingplan/MarketingPlan';
import { MarketingPlanGroup } from '../../models/marketingplan/MarketingPlanGroup';
import {
  get, put, remove
} from '../../utils/api';
import { handleResponceErrors } from '../../utils/helpers';
import { __ } from '../../utils/translate';
import ImageHandle from '../helpers/ImageHandle';
import LanguageContext from '../helpers/LanguageContext';

function EditPlan({ marketingPlanId, onSubmit, onDelete }: { marketingPlanId: string, onSubmit: (marketingPlan: MarketingPlan) => void, onDelete: () => void }) {
  const [loadingInit, setLoadingInit] = useState(true);
  const [loadingPut, setLoadingPut] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const { language } = useContext(LanguageContext);

  const getPlan = async () => get(`marketing-plans/${marketingPlanId}`);
  const removePlan = async () => remove(`marketing-plans/${marketingPlanId}`);
  const deletePlan = async () => {
    setLoadingDelete(true);
    const resp = await removePlan();

    if (resp.status === 200) {
      if (onDelete) {
        onDelete();
      }
    }

    setLoadingDelete(false);
  };

  const [groups, setGroups] = useState(({} as { marketing_plans_groups: MarketingPlanGroup[] }));
  const getGroups = async () => setGroups(await get('marketing-plans/groups', { language: language as 'swe' | 'nor' | 'eng' }));

  const form = useForm({
    initialValues: {
      name: '',
      description: '',
      marketing_plans_group_id: '',
      image_id: null,
      published_at: false
    }
  });

  const imageRef = createRef<any>();

  const submit = async (props: any) => {
    setLoadingPut(true);
    const data = props;

    data.published_at = data.published_at ? new Date() : null;

    const images = await imageRef.current.submit();
    data.image_id = images[0] ? images[0] : null;

    const resp = await put(`marketing-plans/${marketingPlanId}`, data);
    setLoadingPut(false);

    if (resp.status === 200) {
      if (onSubmit) {
        onSubmit(resp.marketing_plan);
      }
    } else {
      const errors = handleResponceErrors(resp.error);

      form.setErrors(errors);
    }
  };

  useEffect(() => {
    async function init() {
      await getGroups();
      const plan = await getPlan();

      form.setValues({
        name: plan.marketing_plan.name,
        description: plan.marketing_plan.description,
        marketing_plans_group_id: plan.marketing_plan.marketing_plans_group_id,
        image_id: plan.marketing_plan.image_id,
        published_at: !!plan.marketing_plan.published_at
      });

      await setLoadingInit(false);
    }

    init();
  }, []);

  const groupOptions = groups.marketing_plans_groups ? groups.marketing_plans_groups.map((group) => ({
    label: group.name,
    value: group.id
  })) : [];

  return (
    <form onSubmit={form.onSubmit((data) => submit(data))}>

      <TextInput
        required
        label={__('Name')}
        {...form.getInputProps('name')}
        mb="lg"
      />

      <Textarea
        label={__('Description')}
        {...form.getInputProps('description')}
        mb="lg"
      />

      <ImageHandle
        ref={imageRef}
        initialImages={form.values.image_id ? ([{ id: form.values.image_id }] as any) : []}
        error={form.errors.image_id}
      />

      <Checkbox
        {...form.getInputProps('published_at')}
        label={__('Published')}
        mb="lg"
        mt="lg"
      />

      <Select
        data={groupOptions}
        label={__('Group')}
        placeholder={__('Select group')}
        {...form.getInputProps('marketing_plans_group_id')}
        mb="lg"
        allowDeselect
      />

      <Group grow>
        <Button loading={loadingDelete} disabled={loadingPut || loadingDelete} color="red" variant="light" onClick={() => deletePlan()}>
          {
            loadingDelete ? __('Deleting...') : __('Delete')
          }
        </Button>
        <Button type="submit" loading={loadingPut} disabled={loadingPut || loadingDelete}>
          {
            loadingPut ? __('Saving...') : __('Save')
          }
        </Button>
      </Group>
    </form>
  );
}

export default EditPlan;
