import { Modal, Select } from '@mantine/core';
import {
  createContext, ReactNode, useContext, useEffect, useMemo, useState
} from 'react';
import { __ } from '../../utils/translate';

// Support all of Europe
export type Language = 'swe' | 'eng' | 'nor' | 'dan' | 'fin' | 'deu' | 'fra' | 'ita' | 'esp' | 'nld' | 'pol' | 'por' | 'rus' | 'tur' | 'grc' | 'rou' | 'hun' | 'cze' | 'svk' | 'hrv' | 'bgr' | 'irl' | 'ukr' | 'bel' | 'ltu' | 'lva' | 'est' | 'srb' | 'bih' | 'mne' | 'mkd' | 'alb' | 'che' | 'aut' | 'lux' | 'lie' | 'mco' | 'and' | 'mlt' | 'cyp' | 'isl' | 'fro' | 'smr' | 'vat';
export type Country = 'swe' | 'eng' | 'nor' | 'dan' | 'fin' | 'deu' | 'fra' | 'ita' | 'esp' | 'nld' | 'pol' | 'por' | 'rus' | 'tur' | 'grc' | 'rou' | 'hun' | 'cze' | 'svk' | 'hrv' | 'bgr' | 'irl' | 'ukr' | 'bel' | 'ltu' | 'lva' | 'est' | 'srb' | 'bih' | 'mne' | 'mkd' | 'alb' | 'che' | 'aut' | 'lux' | 'lie' | 'mco' | 'and' | 'mlt' | 'cyp' | 'isl' | 'fro' | 'smr' | 'vat';
export type LanguageAlternativeCodes = {
  'swe': 'se',
  'eng': 'en',
  'nor': 'no',
  'dan': 'dk',
  'fin': 'fi',
  'deu': 'de',
  'fra': 'fr',
  'ita': 'it',
  'esp': 'es',
  'nld': 'nl',
  'pol': 'pl',
  'por': 'pt',
  'rus': 'ru',
  'tur': 'tr',
  'grc': 'gr',
  'rou': 'ro',
  'hun': 'hu',
  'cze': 'cz',
  'svk': 'sk',
  'hrv': 'hr',
  'bgr': 'bg',
  'irl': 'ie',
  'ukr': 'ua',
  'bel': 'by',
  'ltu': 'lt',
  'lva': 'lv',
  'est': 'ee',
  'srb': 'rs',
  'bih': 'ba',
  'mne': 'me',
  'mkd': 'mk',
  'alb': 'al',
  'che': 'ch',
  'aut': 'at',
  'lux': 'lu',
  'lie': 'li',
  'mco': 'mc',
  'and': 'ad',
  'mlt': 'mt',
  'cyp': 'cy',
  'isl': 'is',
  'fro': 'fo',
  'smr': 'sm',
  'vat': 'va'
};

interface LanguageInterface {
  language: null | Language;
  languageOptions: { value: Language, label: string }[];
  countryOptions: { value: Country, label: string }[];
  allowAllLanguages: boolean;
  languageAlternativeCodes: LanguageAlternativeCodes;
  setLanguage: (lang: null | Language) => void;
  setAllowAllLanguages: (allow: boolean) => void;
}
// ATTENTION: there is a copy of countryOptions in CampaignTemplateSave.tsx, and should be updated if this is updated
const LanguageContext = createContext<LanguageInterface>({
  language: null,
  languageOptions: [
    { value: 'alb', label: __('Albanian') },
    { value: 'and', label: __('Andorran') },
    { value: 'aut', label: __('Austrian German') },
    { value: 'bel', label: __('Belarusian') },
    { value: 'bih', label: __('Bosnian') },
    { value: 'bgr', label: __('Bulgarian') },
    { value: 'hrv', label: __('Croatian') },
    { value: 'cyp', label: __('Cypriot Greek') },
    { value: 'cze', label: __('Czech') },
    { value: 'dan', label: __('Danish') },
    { value: 'nld', label: __('Dutch') },
    { value: 'eng', label: __('English') },
    { value: 'est', label: __('Estonian') },
    { value: 'fro', label: __('Faroese') },
    { value: 'fin', label: __('Finnish') },
    { value: 'fra', label: __('French') },
    { value: 'deu', label: __('German') },
    { value: 'grc', label: __('Greek') },
    { value: 'hun', label: __('Hungarian') },
    { value: 'isl', label: __('Icelandic') },
    { value: 'irl', label: __('Irish') },
    { value: 'ita', label: __('Italian') },
    { value: 'vat', label: __('Latin') },
    { value: 'lva', label: __('Latvian') },
    { value: 'lie', label: __('Liechtenstein German') },
    { value: 'ltu', label: __('Lithuanian') },
    { value: 'lux', label: __('Luxembourgish') },
    { value: 'mkd', label: __('Macedonian') },
    { value: 'mlt', label: __('Maltese') },
    { value: 'mco', label: __('Monégasque') },
    { value: 'mne', label: __('Montenegrin') },
    { value: 'nor', label: __('Norwegian') },
    { value: 'pol', label: __('Polish') },
    { value: 'por', label: __('Portuguese') },
    { value: 'rou', label: __('Romanian') },
    { value: 'rus', label: __('Russian') },
    { value: 'smr', label: __('Sammarinese') },
    { value: 'srb', label: __('Serbian') },
    { value: 'svk', label: __('Slovak') },
    { value: 'esp', label: __('Spanish') },
    { value: 'swe', label: __('Swedish') },
    { value: 'che', label: __('Swiss') },
    { value: 'tur', label: __('Turkish') },
    { value: 'ukr', label: __('Ukrainian') }
  ],
  countryOptions: [
    { value: 'alb', label: __('Albania') },
    { value: 'and', label: __('Andorra') },
    { value: 'aut', label: __('Austria') },
    { value: 'bel', label: __('Belarus') },
    { value: 'bih', label: __('Bosnia and Herzegovina') },
    { value: 'bgr', label: __('Bulgaria') },
    { value: 'hrv', label: __('Croatia') },
    { value: 'cyp', label: __('Cyprus') },
    { value: 'cze', label: __('Czech Republic') },
    { value: 'dan', label: __('Denmark') },
    { value: 'est', label: __('Estonia') },
    { value: 'fro', label: __('Faroe Islands') },
    { value: 'fin', label: __('Finland') },
    { value: 'fra', label: __('France') },
    { value: 'deu', label: __('Germany') },
    { value: 'grc', label: __('Greece') },
    { value: 'hun', label: __('Hungary') },
    { value: 'isl', label: __('Iceland') },
    { value: 'irl', label: __('Ireland') },
    { value: 'ita', label: __('Italy') },
    { value: 'lva', label: __('Latvia') },
    { value: 'lie', label: __('Liechtenstein') },
    { value: 'ltu', label: __('Lithuania') },
    { value: 'lux', label: __('Luxembourg') },
    { value: 'mkd', label: __('North Macedonia') },
    { value: 'mlt', label: __('Malta') },
    { value: 'mco', label: __('Monaco') },
    { value: 'mne', label: __('Montenegro') },
    { value: 'nld', label: __('Netherlands') },
    { value: 'nor', label: __('Norway') },
    { value: 'pol', label: __('Poland') },
    { value: 'por', label: __('Portugal') },
    { value: 'rou', label: __('Romania') },
    { value: 'rus', label: __('Russia') },
    { value: 'smr', label: __('San Marino') },
    { value: 'srb', label: __('Serbia') },
    { value: 'svk', label: __('Slovakia') },
    { value: 'esp', label: __('Spain') },
    { value: 'swe', label: __('Sweden') },
    { value: 'che', label: __('Switzerland') },
    { value: 'tur', label: __('Turkey') },
    { value: 'ukr', label: __('Ukraine') },
    { value: 'eng', label: __('England') },
    { value: 'vat', label: __('Vatican City') }
  ],
  allowAllLanguages: true,
  languageAlternativeCodes: {
    swe: 'se',
    eng: 'en',
    nor: 'no',
    dan: 'dk',
    fin: 'fi',
    deu: 'de',
    fra: 'fr',
    ita: 'it',
    esp: 'es',
    nld: 'nl',
    pol: 'pl',
    por: 'pt',
    rus: 'ru',
    tur: 'tr',
    grc: 'gr',
    rou: 'ro',
    hun: 'hu',
    cze: 'cz',
    svk: 'sk',
    hrv: 'hr',
    bgr: 'bg',
    irl: 'ie',
    ukr: 'ua',
    bel: 'by',
    ltu: 'lt',
    lva: 'lv',
    est: 'ee',
    srb: 'rs',
    bih: 'ba',
    mne: 'me',
    mkd: 'mk',
    alb: 'al',
    che: 'ch',
    aut: 'at',
    lux: 'lu',
    lie: 'li',
    mco: 'mc',
    and: 'ad',
    mlt: 'mt',
    cyp: 'cy',
    isl: 'is',
    fro: 'fo',
    smr: 'sm',
    vat: 'va'
  },
  setLanguage: (lang) => { },
  setAllowAllLanguages: (allow) => { }
});

export function LanguagePicker() {
  const {
    language, setLanguage, countryOptions, allowAllLanguages
  } = useContext(LanguageContext);
  return (

    <Select
      label={__('Country')}
      onChange={(value) => {
        setLanguage(value as Language | null);
        if (value) {
          localStorage.setItem('language', value as Language);
        } else {
          localStorage.removeItem('language');
        }
      }}
      value={language}
      placeholder={__('All languages')}
      allowDeselect={allowAllLanguages}
      data={countryOptions}
    />
  );
}

export function LanguageProvider({ children }: { children: ReactNode }) {
  const { languageOptions, countryOptions, languageAlternativeCodes } = useContext(LanguageContext);
  const [language, setLanguage] = useState<null | Language>(null);
  const [allowAllLanguages, setAllowAllLanguages] = useState(true);

  const languageProviderValue = useMemo(() => ({
    language, languageOptions, countryOptions, setLanguage, allowAllLanguages, setAllowAllLanguages, languageAlternativeCodes
  }), [language, languageOptions, countryOptions, setLanguage, allowAllLanguages, setAllowAllLanguages, languageAlternativeCodes]);

  useEffect(() => {
    const lang = localStorage.getItem('language');
    if (lang) {
      setLanguage(lang as Language);
    }
  }, []);

  return (
    <LanguageContext.Provider value={languageProviderValue}>
      <div>
        <Modal
          opened={!language && !allowAllLanguages}
          title={__('Select language')}
          onClose={() => { }}
          overlayColor="#020203"
          withCloseButton={false}
        >
          <LanguagePicker />
        </Modal>
        {children}
      </div>
    </LanguageContext.Provider>
  );
}
export default LanguageContext;
