import {
  BackgroundImage, Box, Button, Container, Divider, Flex, Group, Overlay, Tabs, Text, Title, Tooltip
} from '@mantine/core';
import { closeModal, openModal } from '@mantine/modals';
import {
  useContext, useEffect, useRef, useState
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { __ } from '../../../utils/translate';

import ArticleChapter from '../../../components/articles/ArticleChapter';
import ChapterNavigation from '../../../components/articles/ChapterNavigation';
import EditArticle from '../../../components/articles/EditArticle';
import Errors from '../../../components/articles/Errors';
import ArticleContext from '../../../components/helpers/ArticleContext';
import Icon from '../../../components/helpers/Icon';
import NavigationContext from '../../../components/helpers/NavigationContext';
import { ArticleChapter as ArticleChapterModel } from '../../../models/articles/articleChapter';

function Article() {
  const articleContext = useContext(ArticleContext);
  const { setAsideNavigation } = useContext(NavigationContext);
  const imageUrlDomain = process.env.NODE_ENV === 'development' ? 'http://localhost:8076' : '';

  const headerRef = useRef<HTMLDivElement>(null);
  const [headerHeight, setHeaderHeight] = useState(headerRef.current?.clientHeight || 0);

  const { articleId, chapterId } = useParams();

  useEffect(() => {
    articleContext.initArticle(articleId as string);
    setAsideNavigation(
      <>
        <Tabs defaultValue="chapterNavigation" className="h-100">
          <Tabs.List>
            <Tabs.Tab value="chapterNavigation">
              {__('Chapter navigation')}
            </Tabs.Tab>
            <Tabs.Tab value="patches">
              {__('Patches')}
            </Tabs.Tab>
          </Tabs.List>

          <Tabs.Panel value="chapterNavigation">
            <ChapterNavigation />
          </Tabs.Panel>
          <Tabs.Panel value="patches">
            patches
          </Tabs.Panel>
        </Tabs>
        <Errors />
      </>
    );
  }, [articleId, chapterId]);

  const navigate = useNavigate();

  const editArticle = () => {
    openModal({
      modalId: 'edit-article',
      title: __('Edit article'),
      size: 'xl',
      children: (
        <EditArticle
          onSubmit={() => {
            closeModal('edit-article');
          }}
          onDelete={() => {
            closeModal('edit-article');
            navigate('/support/articles/');
          }}
        />
      )
    });
  };

  const moveChapter = async (dir: 1 | -1, id: string) => {
    const chapters = articleContext.getChapters();

    if (chapters) {
      const index = chapters.findIndex((c) => c.id === id);

      if (index !== -1) {
        // Swap sort on index and index + dir
        const temp = chapters[index].sort;
        chapters[index].sort = chapters[index + dir].sort;
        chapters[index + dir].sort = temp;
      }
    }

    articleContext.putArticle({ articles_chapters: chapters });
  };

  const addChapter = () => {
    const temp = articleContext.article;

    if (temp.articles_chapters) {
      temp.articles_chapters.push({
        id: `new_${Math.random().toString(36).substring(2, 15)}${Math.random().toString(36).substring(2, 15)}`, // Overwrite on save
        name: `${__('Chapter')} ${temp.articles_chapters.length + 1}`,
        sort: temp.articles_chapters.length
      } as ArticleChapterModel);
    }

    articleContext.putArticle(temp);
  };

  useEffect(() => {
    setHeaderHeight(headerRef.current?.clientHeight || 0);
    window.addEventListener('resize', () => {
      setHeaderHeight(headerRef.current?.clientHeight || 0);
    });
  });

  const articleChapters = useRef({ addBlock: () => { } });

  return (
    <Box mih={`calc(100vh - ${headerHeight}px)`}>
      <Box
        sx={(theme) => ({
          position: 'fixed',
          left: '300px',
          right: '300px',
          top: 0,
          backgroundColor: theme.colors.dark[7],
          borderBottom: `1px solid ${theme.colors.dark[5]}`,
          zIndex: 100,
          overflow: 'hidden'
        })}
      >
        <BackgroundImage
          src={`${imageUrlDomain}/images/view/${articleContext.article?.image}`}
        >
          <Overlay opacity={0.6} color="#000" zIndex={5} />
          <Container
            ref={headerRef}
            fluid
            size="lg"
            py="md"
            mih="80px"
            sx={{ zIndex: 10, position: 'relative' }}
          >
            <Flex
              align="center"
              justify="space-between"
            >
              <Box w="69%">
                <Title order={1}>{articleContext.article?.name}</Title>
                <Text>{articleContext.article?.description.substring(0, 200)}</Text>
              </Box>
              <Group>
                {articleContext.article?.published_at && (
                <Title order={4}>
                  {__('Published')}
                </Title>
                )}
                <Button onClick={() => editArticle()}>
                  <Icon wrapper={false} name="edit" />
                </Button>
              </Group>
            </Flex>
          </Container>
        </BackgroundImage>
      </Box>
      <Box mt={headerHeight}>
        {articleContext.article.articles_chapters?.sort((a, b) => a.sort - b.sort).map((chapter, index) => (
          chapter.id && (
            <ArticleChapter
              key={chapter.id}
              ref={articleChapters}
              headerHeight={headerHeight}
              articleChapterId={chapter.id}
              headerActions={(
                <Button.Group>
                  <Button
                    onClick={() => { moveChapter(-1, chapter.id); }}
                    disabled={index === 0}
                  >
                    <Icon wrapper={false} name="up" />
                  </Button>
                  <Button
                    onClick={() => { moveChapter(1, chapter.id); }}
                    disabled={index === (articleContext.article.articles_chapters as ArticleChapterModel[]).length - 1}
                  >
                    <Icon wrapper={false} name="down" />
                  </Button>
                </Button.Group>
              )}
            />
          )
        ))}
      </Box>
      <Box
        sx={(theme) => ({
          backgroundColor: theme.colors.dark[6],
          zIndex: 10,
          position: 'sticky',
          bottom: 0
        })}
      >
        <Divider my={0} />
        <Container py="xs" size="lg">
          <Group position="center">
            <Button onClick={async () => {
              await addChapter();
              await articleChapters.current.addBlock();
            }}
            >
              {__('Add chapter')}
            </Button>
            {articleContext.article.articles_chapters?.length === 1 && (
              <Tooltip color="dark" label={__('By only using one chapter, Wondr will behave as if there are no chapters.')}>
                <Button color="black">
                  <Icon wrapper={false} name="exclamation" />
                </Button>
              </Tooltip>
            )}
            <Divider orientation="vertical" />
            <Button onClick={() => articleContext.publishArticle()} loading={articleContext.loadingPublish} disabled={articleContext.loadingPublish || articleContext.loadingSave}>
              {__('Publish changes')}
            </Button>
            <Button onClick={() => articleContext.draftArticle()} loading={articleContext.loadingDraft} disabled={articleContext.loadingDraft || articleContext.loadingPublish || articleContext.loadingSave}>
              { __('Set to draft') }
            </Button>
            <Button onClick={() => articleContext.saveArticle()} loading={articleContext.loadingSave} disabled={articleContext.loadingPublish || articleContext.loadingSave}>
              <Icon wrapper={false} name="save" />
            </Button>
          </Group>
        </Container>
        <Divider my={0} />
      </Box>
    </Box>
  );
}

export default Article;
