import {
  Button, Group, Select, Textarea, TextInput
} from '@mantine/core';
import { DatePicker } from '@mantine/dates';
import { useForm } from '@mantine/form';
import { useContext, useEffect, useState } from 'react';
import { Campaign } from '../../models/Campaign';
import { MarketingPlanDate } from '../../models/marketingplan/MarketingPlanDate';
import {
  get, put, remove
} from '../../utils/api';
import { handleResponceErrors } from '../../utils/helpers';
import { __ } from '../../utils/translate';
import LanguageContext from '../helpers/LanguageContext';

function EditDate({ id, onSubmit, onDelete }: { id: string, onSubmit: (marketingPlanDate: MarketingPlanDate) => void, onDelete: () => void }) {
  const [loadingInit, setLoadingInit] = useState(true);
  const [loadingPut, setLoadingPut] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [campaigns, setCampaigns] = useState<{ campaigns: Campaign[] }>({ campaigns: [] });
  const { language } = useContext(LanguageContext);

  const getDate = async () => get(`marketing-plans/dates/${id}`);
  const removeDate = async () => remove(`marketing-plans/dates/${id}`);
  const deleteDate = async () => {
    setLoadingDelete(true);
    const resp = await removeDate();

    if (resp.status === 200) {
      if (onDelete) {
        onDelete();
      }
    }

    setLoadingDelete(false);
  };

  const getCampaigns = async () => setCampaigns(await get('campaigns', {
    limit: 99999,
    countyr: language,
    order: { title: 'ASC' }
  }));

  const typeOptions = [
    { value: 'campaign', label: __('Campaign') },
    { value: 'advertisement', label: __('Advertisement') },
    { value: 'mailing', label: __('Mailing') },
    { value: 'post', label: __('Post') },
    { value: 'event', label: __('Event') },
    { value: 'custom_text', label: __('Custom text') }
  ];

  const form = useForm({
    initialValues: {
      date: new Date(),
      type: null,
      attributes: {}
    }
  });

  const submit = async (props: {
    date: Date;
    type: string | null;
    attributes: Record<string, any>;
    language?: 'swe' | 'nor' | 'eng';
    marketing_plan_id?: string;
  }) => {
    setLoadingPut(true);
    const data = props;

    const resp = await put(`marketing-plans/dates/${id}`, data);
    setLoadingPut(false);

    if (resp.status === 200) {
      if (onSubmit) {
        onSubmit(resp.marketing_plan);
      }
    } else {
      const errors = handleResponceErrors(resp.error);

      form.setErrors(errors);
    }
  };

  useEffect(() => {
    async function init() {
      await getCampaigns();
      const date = await getDate();

      form.setValues({
        date: date.marketing_plans_date.date,
        type: date.marketing_plans_date.type,
        attributes: date.marketing_plans_date.attributes
      });

      await setLoadingInit(false);
    }

    init();
  }, []);

  return (
    <form onSubmit={form.onSubmit((data) => submit(data))}>

      <DatePicker
        placeholder="Pick date"
        label={__('Date')}
        {...form.getInputProps('date')}
        withAsterisk
        mb="md"
      />

      <Select
        {...form.getInputProps('type')}
        placeholder={__('Select type')}
        data={typeOptions}
        required
        mb="md"
      />

      {form.values.type && ['advertisement', 'mailing', 'post', 'event', 'custom_text'].includes(form.values.type) && (
        <div>
          <TextInput
            label={__('Todays name')}
            {...form.getInputProps('attributes.event_day')}
            mb="md"
          />

          <TextInput
            label={__('Title')}
            {...form.getInputProps('attributes.event_name')}
            required
            mb="md"
          />

          <Textarea
            label={__('Description')}
            {...form.getInputProps('attributes.event_description')}
            mb="md"
          />
        </div>
      )}

      {form.values.type === 'custom_text' && (
        <div>
          <TextInput
            label={__('Activity type')}
            {...form.getInputProps('attributes.event_type')}
            required
            mb="md"
          />
        </div>
      )}

      {form.values.type === 'campaign' && (
        <div>
          <TextInput
            label={__('Todays name')}
            {...form.getInputProps('attributes.event_day')}
            mb="md"
          />

          <TextInput
            label={__('Title')}
            {...form.getInputProps('attributes.event_name')}
            mb="md"
          />

          <Select
            label={__('Campaign')}
            required
            mb="md"
            data={campaigns.campaigns.map((c) => ({ value: c.id, label: c.title }))}
            {...form.getInputProps('attributes.campaign_id')}
          />
        </div>
      )}

      <Group grow>
        <Button loading={loadingDelete} disabled={loadingPut || loadingDelete} color="red" variant="light" onClick={() => deleteDate()}>
          {
            loadingDelete ? __('Deleting...') : __('Delete')
          }
        </Button>
        <Button type="submit" loading={loadingPut} disabled={loadingPut || loadingDelete}>
          {
            loadingPut ? __('Saving...') : __('Save')
          }
        </Button>
      </Group>
    </form>
  );
}

export default EditDate;
