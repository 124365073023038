import { IconName } from '@fortawesome/fontawesome-svg-core';
import {
  Button, Container, Flex, Group, Title, Tooltip
} from '@mantine/core';
import { useLocalStorage } from '@mantine/hooks';
import { closeModal, openModal } from '@mantine/modals';
import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import AddArticle from '../../../components/articles/AddArticle';
import ArticleArchive from '../../../components/articles/ArticleArchive';
import TrashArticle from '../../../components/articles/TrashArticle';
import Icon from '../../../components/helpers/Icon';
import { __ } from '../../../utils/translate';

function Index() {
  const navigate = useNavigate();
  const archive = useRef({ getItems: () => { } });
  const [listTypes, setlistTypes] = useLocalStorage<'table' | 'grid'>({ key: 'list-type', defaultValue: 'grid' });

  const listTypesOptions = {
    grid: 'image',
    table: 'th-list'
  } as Record<'table' | 'grid', IconName>;

  const tooltipNiceName = {
    grid: __('Grid'),
    table: __('Table')
  } as Record<'table' | 'grid', string>;

  const addArticle = () => {
    openModal({
      modalId: 'add-article',
      title: __('Add article'),
      size: 'xl',
      children: (
        <AddArticle
          type="education"
          onSubmit={(article) => {
            // navigate to the article.id
            navigate(`/support/articles/${article.id}`);
            closeModal('add-article');
          }}
        />
      )
    });
  };

  const archiveArticle = () => {
    openModal({
      modalId: 'archive-article',
      title: __('Archived article'),
      children: (
        <TrashArticle
          type="education"
          onSubmit={() => {
            closeModal('archive-article');
            archive.current.getItems();
          }}
        />
      )
    });
  };

  return (
    <div className="articles-wrapper p-rel">
      <Container px="md" py="lg" size="lg">
        <Flex justify="space-between" align="center" mb="sm">
          <Title order={1}>{__('Articles')}</Title>
          <Group>
            <Button.Group>
              {(Object.keys(listTypesOptions) as ('table' | 'grid')[]).map((type: 'table' | 'grid') => (
                <Tooltip key={type} label={tooltipNiceName[type]} color="primary" withArrow position="bottom">
                  <Button onClick={() => setlistTypes(type)} variant={listTypes === type ? 'filled' : 'outline'}>
                    <Icon wrapper={false} name={listTypesOptions[type]} />
                  </Button>
                </Tooltip>
              ))}
            </Button.Group>
            <Button color="dark" onClick={archiveArticle}>
              <Icon wrapper={false} name="archive" />
            </Button>
            <Button onClick={addArticle}>
              <Icon wrapper={false} name="plus" library="fas" />
            </Button>
          </Group>
        </Flex>

        <ArticleArchive
          articleType="education"
          listType={listTypes as 'table' | 'grid'}
        />
      </Container>
    </div>
  );
}

export default Index;
