import {
  Flex, Stack, Group, Button
} from '@mantine/core';

import { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import ArticleContext from '../helpers/ArticleContext';

function ChapterNavigation() {
  const slug = {
    education: 'support'
  };

  const { article } = useContext(ArticleContext);
  const location = useLocation();

  return (
    <Flex
      h="inherit"
      direction="column"
      justify="space-between"
      w="100%"
    >
      <Stack justify="flex-start" spacing={0}>
        {article.articles_chapters ? article.articles_chapters.map((chapter, index) => (
          <Group grow key={chapter.id} spacing={0}>
            <Button
              size="md"
              fullWidth
              className="flex-start-buttons"
              variant="subtle"
              component={Link}
              to={`/${slug[article.type]}/articles/${article.id}#${chapter.id}`}
              onClick={() => {
                // scroll to hash
                const element = document.getElementById(chapter.id);
                if (element) {
                  element.scrollIntoView({ behavior: 'smooth', block: 'start' });
                }
              }}
              key={chapter.id}
              sx={(theme) => ({
                borderRadius: '0',
                color: theme.colors.dark[0]
                // backgroundColor: location.hash.includes(chapter.id) ? theme.colors.tertiary[5] : 'transparent'
              })}
            >
              {chapter.name}
            </Button>
          </Group>
        )) : null}
      </Stack>
    </Flex>
  );
}

export default ChapterNavigation;
