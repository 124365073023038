import {
  Badge,
  Box,
  Button, Container, Divider, Flex, Paper, Text, Title
} from '@mantine/core';
import { openModal, closeModal } from '@mantine/modals';
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import EditCampaign from '../../../components/campaigns/EditCampaign';
import Icon from '../../../components/helpers/Icon';
import LanguageContext from '../../../components/helpers/LanguageContext';
import { Campaign as CampaignModel } from '../../../models/campaigns/Campaign';
import { CampaignGroup } from '../../../models/campaigns/CampaignGroup';
import { CampaignTag } from '../../../models/campaigns/CampaignTag';
import { get } from '../../../utils/api';
import { stringToHash } from '../../../utils/helpers';
import { __ } from '../../../utils/translate';

function Campaign() {
  const { setLanguage } = useContext(LanguageContext);
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [campaign, setCampaign] = useState<{ campaign: CampaignModel }>({
    campaign: {
      id: id ?? '',
      title: '',
      description: '',
      price: 0,
      type: 'wireframe',
      country: 'se',
      created: new Date(),
      published_at: null,
      included_in_trial: false
    }
  });

  const getCampaign = async () => {
    const c = await get(`campaigns/${id}`);
    setCampaign(c);
  };

  useEffect(() => {
    getCampaign();
  }, [id]);

  const editCampaign = () => {
    openModal({
      modalId: 'edit-campaign',
      title: __('Edit campaign'),
      children: (
        <EditCampaign
          id={id as string}
          onSubmit={() => {
            closeModal('edit-campaign');
            getCampaign();
          }}
          onDelete={() => {
            closeModal('edit-campaign');
            navigate('/marketinghub/campaigns');
          }}
        />
      )
    });
  };

  const typeOptions = {
    wireframe: __('Wireframe'),
    content: __('Content'),
    webpage: __('Webpage')
  };

  return (
    <div className="campaign-wrapper">
      <Container px="md" py="lg" size="lg">
        <Flex
          mb="sm"
          justify="space-between"
          align="center"
        >
          <Title order={1}>{campaign.campaign.title}</Title>
          <Button onClick={editCampaign}>
            <Icon wrapper={false} name="edit" />
          </Button>
        </Flex>
        <Flex gap="md">
          <Paper shadow="md" p="md" w="55%">
            <Box mb="sm">
              <Title order={2}>{__('Description')}</Title>
              <Text>{campaign.campaign.description || '-'}</Text>
            </Box>
            {false && (
              <>
                <Divider mb="sm" />
                <Box mb="sm">
                  <Title order={2}>{__('Price')}</Title>
                  <Text>
                    {(campaign.campaign.price / 100).toLocaleString('sv-se', {
                      style: 'currency',
                      currency: 'SEK'
                    })}
                  </Text>
                </Box>
              </>
            )}
            <Divider mb="sm" />
            <Box mb="sm">
              <Title order={2}>{__('Type')}</Title>
              <Text>{campaign.campaign.type ? typeOptions[campaign.campaign.type] : '-'}</Text>
            </Box>
            <Divider mb="sm" />
            <Box mb="sm">
              <Title order={2}>{__('Published')}</Title>
              <Text>{campaign.campaign.published_at ? campaign.campaign.published_at.toLocaleString('sv-se') : '-'}</Text>
            </Box>
            <Divider mb="sm" />
            <Box mb="sm">
              <Title order={2}>{__('Part of trial')}</Title>
              <Text>{campaign.campaign.included_in_trial ? __('Yes') : __('No')}</Text>
            </Box>
          </Paper>
          <Paper
            shadow="md"
            p="md"
            sx={{
              width: '45%'
            }}
          >
            <Title order={2}>{__('Groups')}</Title>
            {campaign.campaign.campaign_groups?.map((group: CampaignGroup) => (
              <Badge
                key={group.id}
                gradient={{ from: stringToHash(group.id), to: stringToHash(group.name) }}
                onClick={() => navigate(`/marketinghub/campaigns/groups/${group.id}`)}
              >
                {group.name}
              </Badge>
            ))}
            <Divider my="md" />
            <Title order={2}>{__('Tags')}</Title>
            {campaign.campaign.campaign_tags?.map((tag: CampaignTag) => (
              <Badge
                key={tag.id}
                gradient={{ from: stringToHash(tag.id), to: stringToHash(tag.name) }}
                onClick={() => navigate(`/marketinghub/campaigns/tags/${tag.id}`)}
              >
                {tag.name}
              </Badge>
            ))}
          </Paper>
        </Flex>

      </Container>
    </div>
  );
}

export default Campaign;
