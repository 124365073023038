import {
  Button,
  Divider,
  Grid,
  Group,
  Paper,
  TextInput
} from '@mantine/core';
import { DatePicker } from '@mantine/dates';
import { useForm } from '@mantine/form';
import { useFocusTrap } from '@mantine/hooks';
import { useEffect, useState } from 'react';
import {
  get, post, put, remove
} from '../../utils/api';

import HolidayDate from '../../models/holidayDate';
import { __ } from '../../utils/translate';
import Icon from '../helpers/Icon';
import ListHelper from '../helpers/ListHelper';
import { Country } from '../helpers/LanguageContext';

function HolidayList({ country }: { country: Country }) {
  const focusTrapRef = useFocusTrap();
  const [loading, setLoading] = useState(false);
  const [loadingInit, setLoadingInit] = useState(false);
  const form = useForm({
    initialValues: {
      holidayDates: [] as HolidayDate[]
    }
  });

  const getDates = async (query?: Record<string, any>) => {
    const result = await get('economy/holiday_dates', { country, ...query });
    form.setFieldValue('holidayDates', result.holidayDates);
  };

  const postDate = async (data: HolidayDate) => post('economy/holiday_dates', data);
  const putDate = async (id: string, data: HolidayDate) => put(`economy/holiday_dates/${id}`, data);

  const deleteDate = async (id: string) => {
    setLoading(true);
    await remove(`economy/holiday_dates/${id}`);
    await getDates();
    setLoading(false);
  };

  const submit = async (data: { holidayDates: HolidayDate[] }) => {
    const promises = [] as Promise<any>[];

    setLoading(true);
    data.holidayDates.forEach(async (holidayDate) => {
      holidayDate.country = country;

      if (holidayDate.date && holidayDate.name) {
        if (holidayDate.id === null) {
          promises.push(postDate(holidayDate));
        } else {
          promises.push(putDate(holidayDate.id, holidayDate));
        }
      }
    });

    Promise.all(promises).then(async () => {
      await getDates();
      setLoading(false);
    });
  };

  useEffect(() => {
    setLoadingInit(true);
    getDates();
    setLoadingInit(false);
  }, [country]);

  return (
    <Paper shadow="md" p="md" className="banking-days-wrapper">
      <form onSubmit={form.onSubmit((data) => submit(data))}>
        <ListHelper items={form.values.holidayDates} loading={loadingInit} empty={__('No dates found')}>
          {(holidayDate: HolidayDate, index: number) => (
            <Grid grow key={holidayDate.id} align="flex-end" ref={focusTrapRef}>
              <Grid.Col span={5}>
                <TextInput
                  label={__('Name')}
                  {...form.getInputProps(`holidayDates.${index}.name`)}
                />
              </Grid.Col>
              <Grid.Col span={5}>
                <DatePicker
                  allowFreeInput
                  placeholder="YYYY-MM-DD"
                  label={__('Date')}
                  value={holidayDate.date as Date}
                  onChange={(value) => {
                    form.setFieldValue(`holidayDates.${index}.date`, value);
                  }}
                  onBlur={(e) => {
                    const last = form.values.holidayDates[form.values.holidayDates.length - 1];
                    if (last.date) {
                      form.insertListItem('holidayDates', { id: null, date: null });
                    }
                  }}
                />
              </Grid.Col>
              <Grid.Col span={1}>
                <Button fullWidth onClick={() => (holidayDate.id ? deleteDate(holidayDate.id) : form.removeListItem('holidayDates', index))} color="red" variant="light">
                  <Icon name="multiply" wrapper={false} />
                </Button>
              </Grid.Col>
            </Grid>
          )}
        </ListHelper>
        <Divider my="md" />
        <Group position="right">
          {loading && <Icon name="spinner-third" wrapper={false} spin />}
          <Button onClick={() => form.insertListItem('holidayDates', { id: null, date: null })} disabled={loading || loadingInit} color="primary">
            <Icon name="plus" library="fas" wrapper={false} />
          </Button>
          <Button type="submit" color="tertiary" disabled={loading || loadingInit}>{__('Save')}</Button>
        </Group>
      </form>
    </Paper>
  );
}

export default HolidayList;
