import {
  Button, Container, Flex, Paper, Table, Text, Title
} from '@mantine/core';
import { closeModal, openModal } from '@mantine/modals';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { get } from '../../../../utils/api';

import EditArticleTag from '../../../../components/articles/EditArticleTag';
import Icon from '../../../../components/helpers/Icon';
import { ArticleTag as ArticleTagModel } from '../../../../models/articles/articleTag';
import { __ } from '../../../../utils/translate';

function ArticleTag() {
  const { articleTagId } = useParams() as { articleTagId: string };
  const [{ tag }, setArticleTag]: [{ tag: ArticleTagModel }, any] = useState({
    tag: {
      id: '',
      articles: [],
      created_at: new Date(),
      name: ''
    }
  });
  const getArticleTag = async () => setArticleTag(await get(`articles/tags/${articleTagId}`, {
    type: 'education',
    should_contain: true
  }));

  useEffect(() => {
    getArticleTag();
  }, []);

  const navigate = useNavigate();

  const editArticleTag = () => {
    openModal({
      modalId: 'edit-article-tag',
      title: __('Edit article category'),
      size: 'md',
      children: (
        <EditArticleTag
          articleTagId={articleTagId}
          onSubmit={() => {
            closeModal('edit-article-tag');
            getArticleTag();
          }}
          onDelete={() => {
            closeModal('edit-article-tag');
            navigate('/support/articles/tags');
          }}
        />
      )
    });
  };

  return (
    <div className="article-tag-wrapper">
      <Container px="md" py="lg" size="lg">
        <Flex justify="space-between" align="center">
          <Title>{tag.name}</Title>
          <Button onClick={editArticleTag}>
            <Icon wrapper={false} name="edit" />
          </Button>
        </Flex>
        <Paper shadow="sm" p="md" mt="md">
          <Title order={2}>{__('Articles')}</Title>
          <Table highlightOnHover striped horizontalSpacing="md" verticalSpacing="md">
            <thead>
              <tr>
                <th><Text>{__('Name')}</Text></th>
                <th><Text align="right">{__('Created')}</Text></th>
                <th><Text align="right">{__('Last update')}</Text></th>
                <th><Text align="right">{__('Published')}</Text></th>
              </tr>
            </thead>
            <tbody>
              {(tag.articles || []).map((article) => (
                <tr key={article.id} onClick={() => navigate(`/support/articles/${article.id}`)}>
                  <td align="left">{article.name}</td>
                  <td align="right">{new Date(article.created_at).toLocaleDateString()}</td>
                  <td align="right">{new Date(article.updated_at).toLocaleDateString()}</td>
                  <td align="right">{article.published_at ? new Date(article.published_at).toLocaleDateString() : '-'}</td>
                </tr>
              ))}

            </tbody>
          </Table>
        </Paper>
      </Container>
    </div>
  );
}

export default ArticleTag;
