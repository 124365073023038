import {
  createContext, ReactNode, useContext, useEffect, useMemo, useState
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Article as ArticleModel } from '../../models/articles/article';
import { ArticleChapter } from '../../models/articles/articleChapter';
import { get, put, remove } from '../../utils/api';
import NavigationContext from './NavigationContext';

const emptyArticle: ArticleModel = {
  id: '',
  name: '',
  description: '',
  type: 'education',
  language: '',
  articles_categories: [],
  articles_tags: [],
  articles_chapters: [],
  education_type: 'education',
  created_at: new Date(),
  updated_at: new Date(),
  published_at: null
};
interface IArticleContext {
  loadingSave: boolean;
  loadingPublish: boolean;
  loadingDraft: boolean;
  article: ArticleModel;
  error: Record<string, any> | null;
  putArticle: (data: Record<string, any>) => void;
  deleteArticle: () => void;
  publishArticle: () => void;
  draftArticle: () => void;
  saveArticle: () => void;
  initArticle: (id: string) => void;
  getChapters: () => ArticleChapter[];
  putChapter: (id: string, data: Record<string, any>) => any;
  deleteChapter: (id: string) => any;
  deleteBlock: (chapterId: string, blockId: string) => any;
}
const ArticleContext = createContext<IArticleContext>({
  loadingSave: false,
  loadingPublish: false,
  loadingDraft: false,
  article: emptyArticle,
  error: null,
  putArticle: (data: Record<string, any>) => null,
  deleteArticle: () => null,
  publishArticle: () => null,
  draftArticle: () => null,
  saveArticle: () => null,
  initArticle: (id: string) => null,
  getChapters: () => [],
  putChapter: (id: string, data: Record<string, any>) => null,
  deleteChapter: (id: string) => null,
  deleteBlock: (chapterId: string, blockId: string) => null
});
export function ArticleProvider({ children }: { children: ReactNode }) {
  const navigate = useNavigate();
  const { setAsideNavigation } = useContext(NavigationContext);
  // setup
  const [articleId, setArticleId] = useState<string | null>(null);
  const [article, setArticle] = useState<ArticleModel>(emptyArticle);
  const [loadingSave, setLoadingSave] = useState(false);
  const [loadingPublish, setLoadingPublish] = useState(false);
  const [loadingDraft, setLoadingDraft] = useState(false);
  const [changesHasBeenMade, setChangesHasBeenMade] = useState(false);
  const [error, setError] = useState<Record<string, any> | null>(null);

  // Article
  const getArticle = async () => {
    const resp = await get(`articles/${articleId}`);
    setArticle(resp.article);
  };

  const putArticle = (articleData: Record<string, any>) => {
    setArticle({ ...article, ...articleData });
  };

  const deleteArticle = async () => {
    setArticleId(null);
    setArticle(emptyArticle);
    setChangesHasBeenMade(false);
    setAsideNavigation(false);
    await remove(`articles/${articleId}`);
    navigate('/support/articles');
  };

  const saveArticle = async () => {
    setError(null);
    setLoadingSave(true);
    // remove ids from chapters and blocks that stats with 'new_'
    const articleCopy = { ...article };
    articleCopy.articles_chapters = articleCopy.articles_chapters?.map((c: any) => {
      if (c.id?.startsWith('new_')) {
        delete c.id;
      }
      c.articles_chapters_blocks = c.articles_chapters_blocks?.map((b: any) => {
        if (b.id?.startsWith('new_')) {
          delete b.id;
        }
        return b;
      });
      return c;
    });

    const saveRes = await put(`articles/${articleId}`, articleCopy);

    if (saveRes.error) {
      setError(saveRes.error);
      setLoadingSave(false);
      return false;
    }

    await getArticle();
    setLoadingSave(false);
    setChangesHasBeenMade(false);

    setTimeout(() => {
      // scroll to the bottom of the page
      window.scrollTo(0, document.body.scrollHeight);
    }, 200);
    return true;
  };

  const publishArticle = async () => {
    setLoadingPublish(true);
    await saveArticle();
    const publishRes = await put(`articles/publish/${articleId}`);
    if (publishRes.error) {
      setError(publishRes.error);
    } else {
      await getArticle();
    }
    setLoadingPublish(false);
  };

  const draftArticle = async () => {
    setLoadingDraft(true);
    const draftRes = await put(`articles/draft/${articleId}`);
    if (draftRes.error) {
      setError(draftRes.error);
    } else {
      await getArticle();
    }
    setLoadingDraft(false);
  };

  // Chapters
  const getChapters = () => JSON.parse(JSON.stringify(article.articles_chapters)) || [];

  const putChapter = (id: ArticleChapter['id'], data: Record<string, any>) => {
    setArticle({
      ...article,
      articles_chapters: article.articles_chapters?.map((c) => {
        if (c.id === id) {
          return { ...c, ...data };
        }
        return c;
      }) || []
    });
    setChangesHasBeenMade(true);
  };

  const deleteChapter = async (chapterId: string) => {
    if (chapterId.startsWith('new_')) {
      setArticle({
        ...article,
        articles_chapters: article.articles_chapters?.filter((c) => c.id !== chapterId) || []
      });
      setChangesHasBeenMade(true);
    } else {
      setLoadingSave(true);
      await saveArticle();
      await remove(`articles/chapters/${chapterId}`);
      await getArticle();
      setLoadingSave(false);
    }
  };

  // Blocks
  const getBlocks = (chapterId: string) => JSON.parse(JSON.stringify(getChapters().find((c:any) => c.id === chapterId)?.articles_chapters_blocks)) || [];

  const deleteBlock = async (chapterId: string, blockId: string) => {
    if (blockId.startsWith('new_')) {
      setArticle({
        ...article,
        articles_chapters: article.articles_chapters?.map((c) => {
          if (c.id === chapterId) {
            return {
              ...c,
              articles_chapters_blocks: c.articles_chapters_blocks?.filter((b) => b.id !== blockId) || []
            };
          }
          return c;
        }) || []
      });
      setChangesHasBeenMade(true);
    } else {
      setLoadingSave(true);
      await saveArticle();
      await remove(`articles/blocks/${blockId}`);
      await getArticle();
      setLoadingSave(false);
    }
  };

  // Init article
  const initArticle = async (id: string) => {
    if (articleId === id) { // Prevent double init on same article
      return;
    }

    setArticleId(id);
  };
  useEffect(() => { // Should only trigger on articleId change to re init article editor
    if (articleId) { // Dev env trigger twice on init
      getArticle();
    }
  }, [articleId]);

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (changesHasBeenMade) {
        event.preventDefault();
        event.returnValue = '';
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [changesHasBeenMade]);

  const location = useLocation();

  useEffect(() => {
    if (articleId) {
      saveArticle();
      setArticleId(null);
      setArticle(emptyArticle);
      setAsideNavigation(false);
    }
  }, [location.pathname]);

  const providerValue = useMemo(() => ({
    loadingSave,
    loadingPublish,
    loadingDraft,
    article,
    error,
    putArticle,
    deleteArticle,
    publishArticle,
    draftArticle,
    saveArticle,
    initArticle,
    getChapters,
    putChapter,
    deleteChapter,
    getBlocks,
    deleteBlock
  }), [
    loadingSave,
    loadingPublish,
    loadingDraft,
    article,
    error,
    putArticle,
    deleteArticle,
    publishArticle,
    draftArticle,
    saveArticle,
    initArticle,
    getChapters,
    putChapter,
    deleteChapter,
    getBlocks,
    deleteBlock
  ]);
  return (
    <ArticleContext.Provider value={providerValue}>
      {children}
    </ArticleContext.Provider>
  );
}
export default ArticleContext;
