import {
  Button, Group, TextInput
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { createRef, useEffect, useState } from 'react';
import { get, put, remove } from '../../utils/api';
import { handleResponceErrors } from '../../utils/helpers';
import { __ } from '../../utils/translate';
import ImageHandle from '../helpers/ImageHandle';

function EditImageGroup({ id, onSubmit, onDelete }: { id: string, onSubmit: () => void, onDelete: () => void }) {
  const [loadingInit, setLoadingInit] = useState(true);
  const [loadingPut, setLoadingPut] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);

  const form = useForm({
    initialValues: {
      name: '',
      image_id: ''
    }
  });

  const getImageGroup = async () => get(`image-groups/view/${id}`);
  const removeImageGroup = async () => remove(`image-groups/delete/${id}`);

  const imageRef = createRef<any>();

  const submit = async (data: any) => {
    setLoadingPut(true);

    const images = await imageRef.current.submit();
    data.image_id = images[0] ? images[0] : null;

    const resp = await put(`image-groups/edit/${id}`, data);
    setLoadingPut(false);

    if (resp.status === 200) {
      if (onSubmit) {
        onSubmit();
      }
    } else {
      const errors = handleResponceErrors(resp.error);
      form.setErrors(errors);
    }
  };

  const deleteImageGroup = async () => {
    setLoadingDelete(true);
    const resp = await removeImageGroup();

    if (resp.status === 200) {
      if (onDelete) {
        onDelete();
      }
    }

    setLoadingDelete(false);
  };

  useEffect(() => {
    async function init() {
      setLoadingInit(true);
      const imageGroup = await getImageGroup();
      form.setValues({
        name: imageGroup.imageGroup.name,
        image_id: imageGroup.imageGroup.image_id
      });
      setLoadingInit(false);
    }
    init();
  }, []);

  return (
    <form onSubmit={form.onSubmit((data) => submit(data))}>
      {!loadingInit && (
        <>
          <TextInput
            required
            label={__('Name')}
            {...form.getInputProps('name')}
            mb="lg"
          />

          <ImageHandle
            ref={imageRef}
            initialImages={[{ id: form.values.image_id }]}
          />

          <Group grow mt="md">
            <Button loading={loadingDelete} disabled={loadingPut || loadingDelete} color="red" variant="light" onClick={() => deleteImageGroup()}>
              {
                loadingDelete ? __('Deleting...') : __('Delete')
              }
            </Button>
            <Button type="submit" loading={loadingPut} disabled={loadingPut || loadingDelete}>
              {
                loadingPut ? __('Saving...') : __('Save')
              }
            </Button>
          </Group>
        </>
      )}
    </form>
  );
}

export default EditImageGroup;
