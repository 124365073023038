import {
  Button,
  Group, TextInput
} from '@mantine/core';
import { useForm } from '@mantine/form';
import {
  createRef, useContext, useEffect, useState
} from 'react';
import { get, put, remove } from '../../utils/api';
import { handleResponceErrors } from '../../utils/helpers';
import { __ } from '../../utils/translate';
import ImageHandle from '../helpers/ImageHandle';
import LanguageContext from '../helpers/LanguageContext';

function EditCampaignGroup({ id, onSubmit, onDelete }: {id: string, onSubmit: () => void, onDelete: () => void }) {
  const [loadingPut, setLoadingPut] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);

  const { language } = useContext(LanguageContext);

  const form = useForm({
    initialValues: {
      name: '',
      image_id: ''
    }
  });

  const getCampaignGroup = async () => {
    const campaignGroup = await get(`campaign-groups/${id}`);

    form.setValues({
      name: campaignGroup.campaignGroup.name,
      image_id: campaignGroup.campaignGroup.image_id
    });
  };

  useEffect(() => {
    getCampaignGroup();
  }, [id]);

  const imageRef = createRef<any>();

  const submit = async (data : any) => {
    setLoadingPut(true);

    data.country = language;

    const images = await imageRef.current.submit();
    data.image_id = images[0] ? images[0] : null;

    console.log(images);

    const resp = await put(`campaign-groups/${id}`, data);
    setLoadingPut(false);

    if (resp.status === 200) {
      if (onSubmit) {
        onSubmit();
      }
    } else {
      const errors = handleResponceErrors(resp.error);

      form.setErrors(errors);
    }
  };

  const deleteCampaignGroup = async () => {
    setLoadingDelete(true);
    await remove(`campaign-groups/${id}`);
    await onDelete();
    setLoadingDelete(false);
  };

  return (
    <form onSubmit={form.onSubmit((data) => submit(data))}>
      <TextInput
        {...form.getInputProps('name')}
        label={__('Name')}
        placeholder={__('Name')}
        mb="md"
      />

      <ImageHandle
        ref={imageRef}
        initialImages={[{ id: form.values.image_id }]}
      />

      <Group grow mt="md">
        <Button color="red" variant="light" onClick={() => deleteCampaignGroup()} loading={loadingDelete}>
          {
            loadingDelete ? __('Deleting...') : __('Delete')
          }
        </Button>
        <Button type="submit" loading={loadingPut}>
          {
            loadingPut ? __('Saving...') : __('Save')
          }
        </Button>
      </Group>
    </form>
  );
}

export default EditCampaignGroup;
