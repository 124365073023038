import {
  MultiSelect
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { useEffect, useState } from 'react';
import { ImageGroup } from '../../models/images/imageGroup';
import { get, post } from '../../utils/api';
import { __ } from '../../utils/translate';
import ImageHandle from '../helpers/ImageHandle';

function AddImage({ imageGroupIds, onSubmit }: { imageGroupIds?: string[], onSubmit: () => void }) {
  const [imageGroups, setImageGroups] = useState(({} as { imageGroups: ImageGroup[] }));
  const getImageGroups = async () => setImageGroups(await get('image-groups'));

  const form = useForm({
    initialValues: {
      image_group_ids: imageGroupIds
    }
  });

  useEffect(() => {
    getImageGroups();
  }, []);

  const imageGroupsOptions = imageGroups.imageGroups ? imageGroups.imageGroups.map((ig) => ({
    label: ig.name,
    value: ig.id
  })) : [];

  return (
    <form>
      {imageGroupIds && imageGroupIds.length === 0 && (
        <MultiSelect
          my="md"
          data={imageGroupsOptions}
          label={__('Image groups')}
          placeholder={__('Select image groups')}
          {...form.getInputProps('image_group_ids')}
        />
      )}

      {form.values.image_group_ids && form.values.image_group_ids.length > 0 && (
        <ImageHandle
          tabs={['upload']}
          directUpload
          onAfterUpload={async (res: any) => {
            if (res.success) {
              const groupRes = await post(`images/edit/${res.image.id}`, {
                image_groups_images: form.values.image_group_ids?.map((id) => ({ image_group_id: id }))
              });

              if (res.errors) {
                res.errors = res.errors.concat(groupRes.errors);
                res.status = groupRes.status;
              }
            }
            return res;
          }}
        />
      )}
    </form>
  );
}

AddImage.defaultProps = {
  imageGroupIds: []
};

export default AddImage;
