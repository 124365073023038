import { Button, Group, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { createRef, useContext, useState } from 'react';
import { post } from '../../utils/api';
import { handleResponceErrors } from '../../utils/helpers';
import { __ } from '../../utils/translate';
import ImageHandle from '../helpers/ImageHandle';
import LanguageContext from '../helpers/LanguageContext';

function AddCampaignGroup({ onSubmit }: { onSubmit: () => void }) {
  const [loading, setLoading] = useState(false);

  const { language } = useContext(LanguageContext);

  const form = useForm({
    initialValues: {
      name: '',
      image_id: null as string | null
    }
  });

  const imageRef = createRef<any>();

  const submit = async (data: any) => {
    setLoading(true);

    data.country = language;

    const images = await imageRef.current.submit();
    data.image_id = images[0] ? images[0] : null;

    const resp = await post('campaign-groups', data);
    setLoading(false);

    if (resp.status === 200) {
      if (onSubmit) {
        onSubmit();
      }
    } else {
      const errors = handleResponceErrors(resp.error);

      form.setErrors(errors);
    }
  };

  return (
    <form onSubmit={form.onSubmit((data) => submit(data))}>
      <TextInput
        {...form.getInputProps('name')}
        label={__('Name')}
        placeholder={__('Name')}
        mb="md"
      />

      <ImageHandle ref={imageRef} />

      <Group grow mt="md">
        <Button type="submit" loading={loading}>
          {
            loading ? __('Saving...') : __('Save')
          }
        </Button>
      </Group>
    </form>
  );
}

export default AddCampaignGroup;
