import {
  Button,
  Group,
  Space,
  TextInput
} from '@mantine/core';
import { FormErrors, useForm } from '@mantine/form';
import { openConfirmModal } from '@mantine/modals';
import {
  createRef, useContext, useEffect, useState
} from 'react';
import { Article } from '../../models/articles/article';
import {
  get, put, remove
} from '../../utils/api';
import { __ } from '../../utils/translate';
import ImageHandle from '../helpers/ImageHandle';
import LanguageContext from '../helpers/LanguageContext';

// eslint-disable-next-line no-unused-vars
function EditArticleCategory({ articleCategoryId, onSubmit, onDelete }: { articleCategoryId: string, onSubmit: (article: Article) => void, onDelete: () => void }) {
  const [loadingInit, setLoadingInit] = useState(true);
  const [loadingPut, setLoadingPut] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const { language } = useContext(LanguageContext);

  const form = useForm({
    initialValues: {
      name: '',
      thumbnail_id: null
    }
  });

  const getCategory = async () => {
    const category = await get(`articles/categories/${articleCategoryId}`);

    form.setValues({
      name: category.category.name,
      thumbnail_id: category.category.thumbnail_id
    });

    setLoadingInit(false);
  };

  const putCategory = async (articleCategory: { name: string, thumbnail_id: string | null }) => put(`articles/categories/${articleCategoryId}`, articleCategory);
  const removeCategory = async () => remove(`articles/categories/${articleCategoryId}`);

  const deleteCategory = async () => {
    await openConfirmModal({
      title: __('Are you sure you want delete this category?'),
      labels: { confirm: __('Confirm'), cancel: __('Cancel') },
      onConfirm: async () => {
        setLoadingDelete(true);
        const resp = await removeCategory();

        if (resp.status === 200) {
          if (onDelete) {
            onDelete();
          }
        }

        setLoadingDelete(false);
      }
    });
  };

  useEffect(() => {
    getCategory();
  }, []);

  const imageRef = createRef<any>();

  const submit = async (data: { name: string, thumbnail_id: string | null }) => {
    setLoadingPut(true);

    const images = await imageRef.current.submit();
    data.thumbnail_id = images[0] ? images[0] : null;

    const resp = await putCategory(data);
    setLoadingPut(false);

    if (resp.status === 200) {
      if (onSubmit) {
        onSubmit(resp.articleCategory);
      }
    } else if (resp.error) {
      const errors = {} as FormErrors;

      Object.keys(resp.error).forEach((key) => {
        errors[key] = Object.values(resp.error[key]);
      });

      form.setErrors(errors);
    }
  };

  return (
    <form onSubmit={form.onSubmit((data) => submit(data))}>

      {!loadingInit && (
        <div>
          <TextInput
            required
            label={__('Name')}
            {...form.getInputProps('name')}
            mb="lg"
          />

          <ImageHandle
            ref={imageRef}
            initialImages={form.values.thumbnail_id ? [{ id: form.values.thumbnail_id }] : []}
          />
          <Space h="md" />
        </div>
      )}

      <Group grow>
        <Button loading={loadingDelete} disabled={loadingPut || loadingDelete} color="red" variant="light" onClick={() => deleteCategory()}>
          {
            loadingDelete ? __('Deleting...') : __('Delete')
          }
        </Button>
        <Button type="submit" loading={loadingPut} disabled={loadingPut || loadingDelete}>
          {
            loadingPut ? __('Saving...') : __('Save')
          }
        </Button>
      </Group>
    </form>
  );
}

export default EditArticleCategory;
