import { useContext, useEffect, useState } from 'react';
import {
  Button, Text, Table, Container, Paper, Title, Flex, Badge
} from '@mantine/core';
import { closeModal, openModal } from '@mantine/modals';
import { useNavigate } from 'react-router-dom';
import { get } from '../../../../utils/api';
import AddCampaignTag from '../../../../components/campaigns/AddCampaignTag';
import { __ } from '../../../../utils/translate';
import { CampaignTag } from '../../../../models/campaigns/CampaignTag';
import LanguageContext from '../../../../components/helpers/LanguageContext';
import Icon from '../../../../components/helpers/Icon';
import { stringToHash } from '../../../../utils/helpers';

function Index() {
  const { language } = useContext(LanguageContext);
  const [campaignTags, setArticleTags]: [{ campaign_tags: CampaignTag[] }, any] = useState({ campaign_tags: [] });
  const getCampaignTags = async () => setArticleTags(await get('campaigns/tags', { language }));

  const navigate = useNavigate();

  useEffect(() => {
    getCampaignTags();
  }, [language]);

  const addCampaignTag = () => {
    openModal({
      modalId: 'add-campaign-tag',
      title: __('Add campaign tag'),
      size: '',
      children: (
        <AddCampaignTag
          onSubmit={() => {
            closeModal('add-campaign-tag');
            getCampaignTags();
          }}
        />
      )
    });
  };

  return (
    <div className="campaign-wrapper p-rel">
      <Container px="md" py="lg" size="lg">
        <Flex justify="space-between" align="center">
          <Title order={1} mb="sm">{__('Campaign tags')}</Title>
          <Button onClick={addCampaignTag}>
            <Icon wrapper={false} name="plus" library="fas" />
          </Button>
        </Flex>

        <Paper shadow="sm" p="md">
          <Table highlightOnHover striped horizontalSpacing="md" verticalSpacing="md">
            <thead>
              <tr>
                <th><Text>{__('Name')}</Text></th>
              </tr>
            </thead>
            <tbody>
              {campaignTags.campaign_tags.map((campaignTag) => (
                <tr key={campaignTag.id} onClick={() => navigate(`/marketinghub/campaigns/tags/${campaignTag.id}`)}>
                  <td align="left">
                    <Badge
                      size="lg"
                      variant="gradient"
                      gradient={{ from: stringToHash(campaignTag.name), to: stringToHash(campaignTag.id) }}
                    >
                      {campaignTag.name}
                    </Badge>
                  </td>
                </tr>
              ))}

            </tbody>
          </Table>
        </Paper>
      </Container>
    </div>
  );
}

export default Index;
