import {
  TextInput, Button, Select, Textarea
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { useState, useContext, useEffect } from 'react';
import { DatePicker } from '@mantine/dates';
import { get, post } from '../../utils/api';
import { handleResponceErrors } from '../../utils/helpers';
import { __ } from '../../utils/translate';
import Language from '../helpers/LanguageContext';
import { MarketingPlanDate } from '../../models/marketingplan/MarketingPlanDate';
import { Campaign } from '../../models/Campaign';

function AddDate({ marketingPlanId, onSubmit }: { marketingPlanId: string, onSubmit: (marketingPlanDate: MarketingPlanDate) => void }) {
  const { language } = useContext(Language);

  const [loading, setLoading] = useState(false);
  const [campaigns, setCampaigns] = useState <{campaigns: Campaign[]}>({ campaigns: [] });
  const getCampaigns = async () => setCampaigns(await get('campaigns'));

  useEffect(() => {
    getCampaigns();
  }, []);

  const typeOptions = [
    { value: 'campaign', label: __('Campaign') },
    { value: 'advertisement', label: __('Advertisement') },
    { value: 'mailing', label: __('Mailing') },
    { value: 'post', label: __('Post') },
    { value: 'event', label: __('Event') },
    { value: 'custom_text', label: __('Custom text') }
  ];

  const form = useForm({
    initialValues: {
      date: new Date(),
      type: null,
      attributes: {}
    }
  });

  const submit = async (props: {
    date: Date;
    type: string | null;
    attributes: Record<string, any>;
    language?: 'swe' | 'nor' | 'eng';
    marketing_plan_id?: string;
  }) => {
    setLoading(true);
    const data = props;
    data.language = language as 'swe' | 'nor' | 'eng';
    data.marketing_plan_id = marketingPlanId;

    const resp = await post('marketing-plans/dates', data);
    setLoading(false);

    if (resp.status === 200) {
      if (onSubmit) {
        onSubmit(resp.marketing_plan);
      }
    } else {
      const errors = handleResponceErrors(resp.error);

      form.setErrors(errors);
    }
  };

  return (
    <form onSubmit={form.onSubmit((data) => submit(data))}>

      <DatePicker
        placeholder="Pick date"
        label={__('Date')}
        {...form.getInputProps('date')}
        withAsterisk
        mb="md"
      />

      <Select
        {...form.getInputProps('type')}
        placeholder={__('Select type')}
        data={typeOptions}
        required
        mb="md"
      />

      {form.values.type && ['advertisement', 'mailing', 'post', 'event', 'custom_text'].includes(form.values.type) && (
        <div>
          <TextInput
            label={__('Todays name')}
            {...form.getInputProps('attributes.event_day')}
            mb="md"
          />

          <TextInput
            label={__('Title')}
            {...form.getInputProps('attributes.event_name')}
            required
            mb="md"
          />

          <Textarea
            label={__('Description')}
            {...form.getInputProps('attributes.event_description')}
            mb="md"
          />
        </div>
      )}

      {form.values.type === 'custom_text' && (
        <div>
          <TextInput
            label={__('Activity type')}
            {...form.getInputProps('attributes.event_type')}
            required
            mb="md"
          />
        </div>
      )}

      {form.values.type === 'campaign' && (
        <div>
          <TextInput
            label={__('Todays name')}
            {...form.getInputProps('attributes.event_day')}
            mb="md"
          />

          <TextInput
            label={__('Title')}
            {...form.getInputProps('attributes.event_name')}
            mb="md"
          />

          <Select
            label={__('Campaign')}
            required
            mb="md"
            data={campaigns.campaigns.map((c) => ({ value: c.id, label: c.title }))}
            {...form.getInputProps('attributes.campaign_id')}
          />
        </div>
      )}

      <Button fullWidth type="submit" loading={loading}>
        {
          loading ? __('Saving...') : __('Save')
        }
      </Button>
    </form>
  );
}

export default AddDate;
