import { Grid } from '@mantine/core';
import { useEffect, useState } from 'react';
import { closeModal, openModal } from '@mantine/modals';
import { get } from '../../utils/api';
import { __ } from '../../utils/translate';
import { Partner } from '../../models/Partner/Partner';
import AddPartner from './AddPartner';
import WidgetPartner from '../helpers/WidgetPartner';
import { Country } from '../helpers/LanguageContext';

function PartnerList({ country }: { country: Country | null }) {
  const [partners, setPartners] = useState([]);

  const getPartners = async (query?: Record<string, any>) => {
    const result = await get('partners/partners', { country, ...query });
    setPartners(result.partners);
  };

  useEffect(() => {
    getPartners();
  }, [country]);

  const editPartner = async (id: string) => {
    openModal({
      modalId: 'edit-partner',
      title: __('edit partner'),
      size: 'xl',
      children: (
        <AddPartner
          onSubmit={() => {
            closeModal('edit-partner');
            getPartners();
          }}
          editPartnerId={id}
        />
      ),
      onClose: () => {
        getPartners();
      }
    });
  };

  const imageUrlDomain = process.env.NODE_ENV === 'development' ? 'http://localhost:8076' : '';

  return (
    <Grid mb="sm">
      {partners.length === 0 && __('No partners added')}
      {partners.map((partner: Partner, index: number) => (
        <Grid.Col span={4} key={partner.id}>
          <WidgetPartner
            title={partner.name}
            image={partner.image ? `${imageUrlDomain}/images/view/${partner.image_logo}` : ''}
            icon="handshake"
            callback={() => editPartner(partner.id)}
          />
        </Grid.Col>
      ))}
    </Grid>
  );
}

export default PartnerList;
