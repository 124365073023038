import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Button, Text, Table, Title, Container, Paper, Flex
} from '@mantine/core';
import { closeModal, openModal } from '@mantine/modals';
import { get } from '../../../../utils/api';

import { CampaignTag as CampaignTagModel } from '../../../../models/campaigns/CampaignTag';
import { __ } from '../../../../utils/translate';
import EditCampaignTag from '../../../../components/campaigns/EditCampaignTag';
import Icon from '../../../../components/helpers/Icon';

function CampaignTag() {
  const { id } = useParams() as { id: string };
  const [campaignTag, setCampaignTag]: [{ campaign_tag: CampaignTagModel }, any] = useState({
    campaign_tag: {
      id: '',
      campaigns: [],
      name: ''
    }
  });
  const getCampaignTag = async () => setCampaignTag(await get(`campaigns/tags/${id}`));

  useEffect(() => {
    getCampaignTag();
  }, []);

  const navigate = useNavigate();

  const editCampaignTag = () => {
    openModal({
      modalId: 'edit-campaign-tag',
      title: __('Edit campaign tag'),
      size: 'md',
      children: (
        <EditCampaignTag
          id={id}
          onSubmit={() => {
            closeModal('edit-campaign-tag');
            getCampaignTag();
          }}
          onDelete={() => {
            closeModal('edit-campaign-tag');
            navigate('/marketinghub/campaigns/tags');
          }}
        />
      )
    });
  };

  return (
    <div className="campaign-tag-wrapper">
      <Container px="md" py="lg" size="lg">
        <Flex justify="space-between" align="center">
          <Title>{campaignTag.campaign_tag.name}</Title>
          <Button onClick={editCampaignTag}>
            <Icon wrapper={false} name="edit" />
          </Button>
        </Flex>
        <Paper shadow="sm" p="md" mt="md">
          <Title order={2}>{__('Campaigns')}</Title>
          <Table highlightOnHover striped horizontalSpacing="md" verticalSpacing="md">
            <thead>
              <tr>
                <th><Text>{__('Name')}</Text></th>
              </tr>
            </thead>
            <tbody>
              {campaignTag.campaign_tag.campaigns && campaignTag.campaign_tag.campaigns.map((campaign) => (
                <tr key={campaign.id} onClick={() => navigate(`/marketinghub/campaigns/${campaign.id}`)}>
                  <td align="left">{campaign.title}</td>
                </tr>
              ))}

            </tbody>
          </Table>
        </Paper>
      </Container>
    </div>
  );
}

export default CampaignTag;
