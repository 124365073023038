import {
  Container, Title, Paper, Table, Button, Text, Flex, Badge
} from '@mantine/core';
import { useModals } from '@mantine/modals';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { __ } from '../../../../utils/translate';
import LanguageContext from '../../../../components/helpers/LanguageContext';
import { MarketingPlanGroup } from '../../../../models/marketingplan/MarketingPlanGroup';
import { get } from '../../../../utils/api';
import AddGroup from '../../../../components/marketingplans/AddGroup';
import Icon from '../../../../components/helpers/Icon';
import { stringToHash } from '../../../../utils/helpers';

function Index() {
  const modals = useModals();
  const navigate = useNavigate();
  const { language } = useContext(LanguageContext);

  const [groups, setGroups]: [{ marketing_plans_groups: MarketingPlanGroup[] }, any] = useState({ marketing_plans_groups: [] });
  const getGroups = async () => setGroups(await get('marketing-plans/groups', {
    limit: 20,
    ...(language ? { language } : {})
  }));

  useEffect(() => {
    getGroups();
  }, [language]);

  const addGroup = () => {
    const id = modals.openModal({
      title: __('Add marketingplan group'),
      children: (
        <AddGroup
          onSubmit={() => {
            modals.closeModal(id);
            getGroups();
          }}
        />
      )
    });
  };

  return (
    <div className="marketingplans-wrapper">
      <Container px="md" py="lg" size="lg">
        <Flex
          mb="sm"
          justify="space-between"
          align="center"
        >
          <Title order={1}>{__('Marketingplan groups')}</Title>
          <Button onClick={addGroup}>
            <Icon wrapper={false} name="plus" library="fas" />
          </Button>
        </Flex>

        <Paper shadow="sm" p="md" className="marketingplan-groups-wrapper">
          <Table highlightOnHover striped horizontalSpacing="md" verticalSpacing="md">
            <thead>
              <tr>
                <th><Text>{__('Name')}</Text></th>
              </tr>
            </thead>
            <tbody>
              {groups.marketing_plans_groups.map((group) => (
                <tr key={group.id} onClick={() => navigate(`/marketinghub/marketingplans/groups/${group.id}`)}>
                  <td align="left">
                    <Badge
                      size="lg"
                      gradient={{ from: stringToHash(group.id), to: stringToHash(group.name) }}
                    >
                      {group.name}
                    </Badge>
                  </td>
                </tr>
              ))}

            </tbody>
          </Table>
        </Paper>
      </Container>
    </div>
  );
}

export default Index;
