import {
  Container, Flex, Title, Button, BackgroundImage, Overlay
} from '@mantine/core';
import { openModal, closeModal } from '@mantine/modals';
import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Archive from '../../../../components/helpers/Archive';
import Icon from '../../../../components/helpers/Icon';
import AddImageGroup from '../../../../components/images/AddImageGroup';
import { ImageGroup } from '../../../../models/images/imageGroup';
import { stringToHash } from '../../../../utils/helpers';
import { __ } from '../../../../utils/translate';

function Index() {
  const imageUrlDomain = process.env.NODE_ENV === 'development' ? 'http://localhost:8076' : '';
  const navigate = useNavigate();
  const list = useRef({ load: () => { } });

  const addImageGroup = () => {
    openModal({
      modalId: 'add-image-group',
      title: __('Add image group'),
      children: (
        <AddImageGroup
          onSubmit={() => {
            closeModal('add-image-group');
            list.current.load();
          }}
        />
      )
    });
  };

  return (
    <div className="image-groups-wrapper">
      <Container px="md" py="lg" size="lg">
        <Flex
          mb="sm"
          justify="space-between"
          align="center"
        >
          <Title order={1}>{__('Image groups')}</Title>
          <Button onClick={addImageGroup}>
            <Icon wrapper={false} name="plus" library="fas" />
          </Button>
        </Flex>
        <div className="image-groups-wrapper">
          <Archive
            ref={list}
            limit={30}
            type="grid"
            request="image-groups"
            model="imageGroups"
          >
            {(item: ImageGroup) => (
              <BackgroundImage
                key={item.id}
                src={`${imageUrlDomain}/images/view/${item.image_id}`}
                radius="lg"
                p="md"
                pt="sm"
                mb="lg"
                onClick={() => navigate(`/media-library/images/groups/${item.id}`)}
                sx={() => ({
                  aspectRatio: '16/10',
                  position: 'relative',
                  cursor: 'pointer',
                  transition: '0.2s ease-in-out',
                  backgroundColor: stringToHash(item.name),
                  '&:hover': {
                    opacity: 0.8
                  }
                })}
              >
                <Overlay
                  opacity={0.5}
                  color="#000"
                  zIndex={5}
                />
                <Title
                  order={3}
                  sx={{
                    position: 'absolute',
                    bottom: '0.5rem',
                    left: '0.75rem',
                    zIndex: 10
                  }}
                >
                  {item.name}
                </Title>
              </BackgroundImage>
            )}
          </Archive>
        </div>
      </Container>
    </div>
  );
}

export default Index;
