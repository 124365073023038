import {
  Button,
  Container, Paper, SimpleGrid, Title, Group as MGroup, BackgroundImage, Overlay, Box
} from '@mantine/core';
import { openModal, closeModal } from '@mantine/modals';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Icon from '../../../../components/helpers/Icon';
import AddImage from '../../../../components/images/AddImage';
import EditImageGroup from '../../../../components/images/EditImageGroup';
import ImageCard from '../../../../components/images/ImageCard';
import { ImageGroup } from '../../../../models/images/imageGroup';
import { get } from '../../../../utils/api';
import { __ } from '../../../../utils/translate';

function Group() {
  const imageUrlDomain = process.env.NODE_ENV === 'development' ? 'http://localhost:8076' : '';
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [group, setGroup] = useState<{ imageGroup: ImageGroup }>({
    imageGroup: {
      id: id || '',
      name: '',
      image_id: '',
      images: []
    }
  });

  const getGroup = async () => setGroup(await get(`image-groups/view/${id}`));

  useEffect(() => {
    getGroup();
  }, []);

  const editImageGroup = () => {
    openModal({
      modalId: 'edit-image-group',
      title: __('Edit image group'),
      children: (
        <EditImageGroup
          id={id as string}
          onSubmit={() => {
            closeModal('edit-image-group');
            getGroup();
          }}
          onDelete={() => {
            closeModal('edit-image-group');
            navigate('/media-library/images/groups');
          }}
        />
      )
    });
  };

  const addImage = () => {
    openModal({
      modalId: 'add-image',
      title: __('Add image'),
      size: 'xl',
      children: (
        <AddImage
          imageGroupIds={[id as string]}
          onSubmit={() => {
            closeModal('add-image');
            getGroup();
          }}
        />
      ),
      onClose: () => getGroup()
    });
  };

  return (
    <div className="image-group-wrapper">
      <Container px="md" py="lg" size="lg">
        <Paper shadow="lg" className="images-wrapper" sx={{ overflow: 'hidden' }}>
          <BackgroundImage
            src={`${imageUrlDomain}/images/view/${group.imageGroup.image_id}`}
            p="md"
            pt="sm"
            mb="lg"
            sx={{
              aspectRatio: '16/4',
              position: 'relative'
            }}
          >
            <Overlay opacity={0.5} color="#000" zIndex={5} />

            <Title
              order={1}
              sx={{
                position: 'absolute',
                bottom: '1rem',
                left: '1rem',
                zIndex: 10
              }}
            >
              {group.imageGroup.name}
            </Title>
            <MGroup
              sx={{
                position: 'absolute',
                top: '1rem',
                right: '1rem',
                zIndex: 10
              }}
            >
              <Button onClick={addImage}>
                <Icon wrapper={false} name="plus" library="fas" />
              </Button>
              <Button onClick={editImageGroup}>
                <Icon wrapper={false} name="edit" />
              </Button>
            </MGroup>
          </BackgroundImage>
          <Box p="md">
            <Title order={2} mb="sm">{__('Images')}</Title>
            <SimpleGrid cols={4}>
              {group.imageGroup.images.map((image) => (
                <ImageCard image={image} />
              ))}
            </SimpleGrid>
          </Box>
        </Paper>
      </Container>
    </div>
  );
}

export default Group;
