import {
  BackgroundImage, Box, Button, Container, Group as MGroup, Overlay, Paper, Table, Text, Title
} from '@mantine/core';
import { closeModal, openModal } from '@mantine/modals';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import EditArticleCategory from '../../../../components/articles/EditArticleCategory';
import Icon from '../../../../components/helpers/Icon';
import { ArticleCategory as ArticleCategoryModel } from '../../../../models/articles/articleCategory';
import { get } from '../../../../utils/api';
import { __ } from '../../../../utils/translate';

function ArticleCategory() {
  const navigate = useNavigate();
  const { articleCategoryId } = useParams() as { articleCategoryId: string };
  const [articleCategory, setArticleCategory]: [{ category: ArticleCategoryModel }, any] = useState({
    category: {
      id: '',
      articles: [],
      created_at: new Date(),
      thumbnail_id: '',
      name: ''
    }
  });
  const getArticleCategory = async () => setArticleCategory(await get(`articles/categories/${articleCategoryId}`, {
    type: 'education',
    should_contain: true
  }));

  useEffect(() => {
    getArticleCategory();
  }, []);

  const editArticleCategory = () => {
    openModal({
      modalId: 'edit-article-category',
      title: __('Edit article category'),
      size: 'md',
      children: (
        <EditArticleCategory
          articleCategoryId={articleCategoryId}
          onSubmit={() => {
            closeModal('edit-article-category');
            getArticleCategory();
          }}
          onDelete={() => {
            closeModal('edit-article-category');
            navigate('/support/articles/categories');
          }}
        />
      )
    });
  };

  const imageUrlDomain = process.env.NODE_ENV === 'development' ? 'http://localhost:8076' : '';

  return (
    <div className="article-category-wrapper">
      <Container px="md" py="lg" size="lg">
        <Paper shadow="md" className="campaign-wrapper" sx={{ overflow: 'hidden' }}>
          <BackgroundImage
            src={`${imageUrlDomain}/images/view/${articleCategory.category.thumbnail_id}`}
            p="md"
            pt="sm"
            mb="lg"
            sx={{
              aspectRatio: '16/4',
              position: 'relative'
            }}
          >
            <Overlay opacity={0.6} color="#000" zIndex={5} />

            <Title
              order={1}
              sx={{
                position: 'absolute',
                bottom: '1rem',
                left: '1rem',
                zIndex: 10
              }}
            >
              {articleCategory.category.name}
            </Title>
            <MGroup
              sx={{
                position: 'absolute',
                top: '1rem',
                right: '1rem',
                zIndex: 10
              }}
            >
              <Button onClick={editArticleCategory}>
                <Icon wrapper={false} name="edit" />
              </Button>
            </MGroup>
          </BackgroundImage>
          <Box p="md">
            <Title order={2}>{__('Articles')}</Title>
            <Table highlightOnHover striped horizontalSpacing="md" verticalSpacing="md">
              <thead>
                <tr>
                  <th><Text>{__('Name')}</Text></th>
                  <th><Text align="right">{__('Created')}</Text></th>
                  <th><Text align="right">{__('Last update')}</Text></th>
                  <th><Text align="right">{__('Published')}</Text></th>
                </tr>
              </thead>
              <tbody>
                {(articleCategory.category.articles || []).map((article) => (
                  <tr key={article.id}>
                    <td onClick={() => navigate(`/support/articles/${article.id}`)} align="left">{article.name}</td>
                    <td onClick={() => navigate(`/support/articles/${article.id}`)} align="right">{new Date(article.created_at).toLocaleDateString()}</td>
                    <td onClick={() => navigate(`/support/articles/${article.id}`)} align="right">{new Date(article.updated_at).toLocaleDateString()}</td>
                    <td onClick={() => navigate(`/support/articles/${article.id}`)} align="right">{article.published_at ? new Date(article.published_at).toLocaleDateString() : '-'}</td>
                  </tr>
                ))}

              </tbody>
            </Table>
          </Box>
        </Paper>
      </Container>
    </div>
  );
}

export default ArticleCategory;
