import { Button, Group, TextInput } from '@mantine/core';
import { FormErrors, useForm } from '@mantine/form';
import { useContext, useEffect, useState } from 'react';
import { MarketingPlanGroup } from '../../models/marketingplan/MarketingPlanGroup';
import { get, put, remove } from '../../utils/api';
import { __ } from '../../utils/translate';
import LanguageContext from '../helpers/LanguageContext';

function EditGroup({ marketingPlanGroupId, onSubmit, onDelete }: { marketingPlanGroupId: string, onSubmit: (marketingPlanGroup: MarketingPlanGroup) => void, onDelete: () => void }) {
  const [loadingInit, setLoadingInit] = useState(true);
  const [loadingPut, setLoadingPut] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const { language } = useContext(LanguageContext);

  const getGroup = async () => get(`marketing-plans/groups/${marketingPlanGroupId}`);
  const removeGroup = async () => remove(`marketing-plans/groups/${marketingPlanGroupId}`);
  const deleteGroup = async () => {
    setLoadingDelete(true);
    const resp = await removeGroup();

    if (resp.status === 200) {
      if (onDelete) {
        onDelete();
      }
    }

    setLoadingDelete(false);
  };

  const form = useForm({
    initialValues: {
      name: ''
    }
  });

  useEffect(() => {
    async function init() {
      const group = await getGroup();

      form.setValues({
        name: group.marketing_plans_group.name
      });

      await setLoadingInit(false);
    }

    init();
  }, []);

  const submit = async (props: { name: string; language?: 'swe' | 'nor' | 'eng'; }) => {
    setLoadingPut(true);
    const data = props;
    data.language = language as 'swe' | 'nor' | 'eng';

    const resp = await put(`marketing-plans/groups/${marketingPlanGroupId}`, data);
    setLoadingPut(false);

    if (resp.status === 200) {
      if (onSubmit) {
        onSubmit(resp.articleArticle);
      }
    } else if (resp.error) {
      const errors = {} as FormErrors;

      Object.keys(resp.error).forEach((key) => {
        errors[key] = Object.values(resp.error[key]);
      });

      form.setErrors(errors);
    }
  };

  return (
    <form onSubmit={form.onSubmit((data) => submit(data))}>

      {!loadingInit && (
        <TextInput
          required
          label={__('Name')}
          {...form.getInputProps('name')}
          mb="lg"
        />
      )}

      <Group grow>
        <Button loading={loadingDelete} disabled={loadingPut || loadingDelete} color="red" variant="light" onClick={() => deleteGroup()}>
          {
            loadingDelete ? __('Deleting...') : __('Delete')
          }
        </Button>
        <Button type="submit" loading={loadingPut} disabled={loadingPut || loadingDelete}>
          {
            loadingPut ? __('Saving...') : __('Save')
          }
        </Button>
      </Group>
    </form>
  );
}

export default EditGroup;
