import {
  Container, Flex, Title, Stack, Grid
} from '@mantine/core';
import Widget from '../../components/helpers/Widget';
import { __ } from '../../utils/translate';

function Index() {
  return (
    <div className="media-library-wrapper">
      <Container px="md" py="lg" size="lg">
        <Flex
          mb="sm"
          justify="space-between"
          align="center"
        >
          <Title order={1}>{__('Media library')}</Title>
        </Flex>
        <Stack>
          <div className="images-wrapper">
            <Grid mb="sm">
              <Grid.Col span={6}>
                <Widget
                  icon="image"
                  to="/media-library/images"
                  title={__('Images')}
                  controller="images"
                  type="Images"
                />
              </Grid.Col>
              <Grid.Col span={6}>
                <Widget
                  icon="object-group"
                  to="/media-library/images/groups"
                  title={__('Image groups')}
                  controller="image-groups"
                  type="ImageGroups"
                />
              </Grid.Col>
            </Grid>
          </div>
        </Stack>
      </Container>
    </div>
  );
}

export default Index;
