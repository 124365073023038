import {
  Button,
  Group,
  TextInput
} from '@mantine/core';
import { FormErrors, useForm } from '@mantine/form';
import { openConfirmModal } from '@mantine/modals';
import { useEffect, useState } from 'react';
import { Article } from '../../models/articles/article';
import {
  get, put, remove
} from '../../utils/api';
import { __ } from '../../utils/translate';

// eslint-disable-next-line no-unused-vars
function EditArticleTag({ articleTagId, onSubmit, onDelete }: { articleTagId: string, onSubmit: (article: Article) => void, onDelete: () => void }) {
  const [loadingPut, setLoadingPut] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);

  const form = useForm({
    initialValues: {
      name: ''
    }
  });

  const getTag = async () => {
    const { tag } = await get(`articles/tags/${articleTagId}`);

    form.setValues({
      name: tag.name
    });
  };

  const putTag = async (tag: { name: string }) => put(`articles/tags/${articleTagId}`, tag);
  const removeTag = async () => remove(`articles/tags/${articleTagId}`);

  const deleteTag = async () => {
    await openConfirmModal({
      title: __('Are you sure you want remove this tag?'),
      labels: { confirm: __('Confirm'), cancel: __('Cancel') },
      onConfirm: async () => {
        setLoadingDelete(true);
        const resp = await removeTag();

        if (resp.status === 200) {
          if (onDelete) {
            onDelete();
          }
        }

        setLoadingDelete(false);
      }
    });
  };

  useEffect(() => {
    getTag();
  }, []);

  const submit = async (data: { name: string }) => {
    setLoadingPut(true);
    const resp = await putTag(data);
    setLoadingPut(false);

    if (resp.status === 200) {
      if (onSubmit) {
        onSubmit(resp.articleTag);
      }
    } else if (resp.error) {
      const errors = {} as FormErrors;

      Object.keys(resp.error).forEach((key) => {
        errors[key] = Object.values(resp.error[key]);
      });

      form.setErrors(errors);
    }
  };

  return (
    <form onSubmit={form.onSubmit((data) => submit(data))}>

      <TextInput
        required
        label={__('Name')}
        {...form.getInputProps('name')}
        mb="lg"
      />

      <Group grow>
        <Button loading={loadingDelete} disabled={loadingPut || loadingDelete} color="red" variant="light" onClick={() => deleteTag()}>
          {
            loadingDelete ? __('Deleting...') : __('Delete')
          }
        </Button>
        <Button type="submit" loading={loadingPut} disabled={loadingPut || loadingDelete}>
          {
            loadingPut ? __('Saving...') : __('Save')
          }
        </Button>
      </Group>
    </form>
  );
}

export default EditArticleTag;
