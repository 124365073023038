import {
  Box, Button, MultiSelect, Select, SimpleGrid, Space, Textarea, TextInput, NumberInput
} from '@mantine/core';
import { useForm } from '@mantine/form';
import {
  useContext, useEffect, useRef, useState
} from 'react';
import { Article } from '../../models/articles/article';
import { ArticleCategory } from '../../models/articles/articleCategory';
import { ArticleTag } from '../../models/articles/articleTag';
import { get, post } from '../../utils/api';
import { handleResponceErrors } from '../../utils/helpers';
import { __ } from '../../utils/translate';
import ImageHandle from '../helpers/ImageHandle';
import LanguageContext from '../helpers/LanguageContext';

// eslint-disable-next-line no-unused-vars
function AddArticle({ type = 'education', onSubmit }: { type: 'education', onSubmit: (article: Article) => void }) {
  const { language } = useContext(LanguageContext);
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState(({} as { categories: ArticleCategory[] }));
  const getCategories = async () => setCategories(await get('articles/categories', { type, language }));
  const [tags, setTags] = useState(({} as { tags: ArticleTag[] }));
  const getTags = async () => setTags(await get('articles/tags', { type, language }));

  useEffect(() => {
    getCategories();
    getTags();
  }, []);

  const categoriesOpitons = categories.categories ? categories.categories.map((category) => ({
    label: category.name,
    value: category.id
  })) : [];

  const tagsOptions = tags.tags ? tags.tags.map((tag) => ({
    label: tag.name,
    value: tag.id
  })) : [];

  const educationTypeOptions = [
    { label: __('Education'), value: 'education' },
    { label: __('Help'), value: 'help' },
    { label: __('Knowledge'), value: 'knowledge' },
    { label: __('News'), value: 'news' }
  ];

  const form = useForm({
    initialValues: {
      name: '',
      description: '',
      articles_categories: [],
      articles_tags: [],
      image: '',
      image_archive: '',
      education_type: 'education',
      sort_order: 0
    }
  });

  const imageRef = useRef<any>();
  const imageArchiveRef = useRef<any>();

  const submit = async (props: any) => {
    setLoading(true);
    const data = props;
    data.articles_categories = data.articles_categories.map((categoryId: Record<string, any>) => ({ id: categoryId })) as { id: Record<string, any> }[];
    data.articles_tags = data.articles_tags.map((tagId: Record<string, any>) => ({ id: tagId })) as { id: Record<string, any> }[];

    const images = await imageRef.current.submit();
    data.image = images[0] ? images[0] : null;

    const imagesArchive = await imageArchiveRef.current.submit();
    data.image_archive = imagesArchive[0] ? imagesArchive[0] : null;

    data.language = language as 'swe' | 'nor' | 'eng';
    data.type = type;

    const resp = await post('articles', data, false);
    setLoading(false);

    if (resp.status === 200) {
      if (onSubmit) {
        onSubmit(resp.article);
      }
    } else {
      const errors = handleResponceErrors(resp.error);

      form.setErrors(errors);
    }
  };

  return (
    <form onSubmit={form.onSubmit((data) => submit(data))}>

      <TextInput
        required
        label={__('Name')}
        {...form.getInputProps('name')}
        mb="lg"
      />
      <Textarea
        label={__('Excerpt')}
        {...form.getInputProps('description')}
        mb="lg"
      />

      <NumberInput
        label={__('Sort Order')}
        description={__('Position in the list (0 = first)')}
        min={0}
        {...form.getInputProps('sort_order')}
        mb="lg"
      />

      <SimpleGrid spacing="lg" cols={2}>
        <Box>
          <label htmlFor="image">{__('Archive image')}</label>

          <ImageHandle
            ref={imageArchiveRef}
          />
        </Box>
        <Box>
          <label htmlFor="image">{__('Single page image')}</label>

          <ImageHandle
            ref={imageRef}
          />
        </Box>
      </SimpleGrid>
      <Space h="md" />

      <Select
        data={educationTypeOptions}
        label={__('Type')}
        placeholder={__('Select type')}
        {...form.getInputProps('education_type')}
        mb="lg"
      />

      <MultiSelect
        data={categoriesOpitons}
        label={__('Category')}
        placeholder={__('Select categories')}
        {...form.getInputProps('articles_categories')}
        mb="lg"
      />

      <MultiSelect
        data={tagsOptions}
        label={__('Tags')}
        placeholder={__('Select tags')}
        {...form.getInputProps('articles_tags')}
        mb="lg"
      />

      <Button fullWidth type="submit" loading={loading}>
        {
          loading ? __('Saving...') : __('Save')
        }
      </Button>
    </form>
  );
}

export default AddArticle;
