export function formatDateTimeFormat(date: string): string {
  return new Intl.DateTimeFormat('sv-SE').format(new Date(date));
}

export function handleResponceErrors(errorsParam: any, associated: string | false = false) {
  let errorsRes = {} as any;
  const errors = associated ? errorsParam[associated] : errorsParam;

  if (Array.isArray(errors)) {
    errors.forEach((error: any) => {
      errorsRes = { ...errorsRes, ...handleResponceErrors(error) };
    });
  } else { // Object
    Object.keys(errors).forEach((key) => {
      errorsRes[key] = Object.values(errors[key]);
    });
  }

  return errorsRes;
}

export function flattenObject(items: any[], key: string): Record<string, any>[] {
  let result = [] as Record<string, any>[];
  items.forEach((item) => {
    result.push(item);
    if (item[key]) {
      result = [...result, ...flattenObject(item[key], key)];
    }
  });

  return result;
}

export function stringToHash(str: string) {
  let hash = 0 as number;
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < str.length; i++) {
    // eslint-disable-next-line no-bitwise
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  // if hash is less than 6 digits, repeat hash
  let hex = Math.abs(hash).toString(16);
  while (hex.length < 6) {
    hex = `${hex}${hex}`;
  }

  const color = hex.substring(0, 6);

  return `#${color}`;
}
