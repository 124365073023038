import {
  BackgroundImage, Badge, Box, Group, MultiSelect, Paper, Select, Text, Title, ActionIcon, NumberInput,
  Tabs, TabsValue
} from '@mantine/core';
import {
  forwardRef, useEffect, useRef, useState, useCallback
} from 'react';
import { useNavigate } from 'react-router-dom';
import { Article } from '../../models/articles/article';
import { ArticleCategory } from '../../models/articles/articleCategory';
import { ArticleTag } from '../../models/articles/articleTag';
import { get, post } from '../../utils/api';
import { stringToHash } from '../../utils/helpers';
import { __ } from '../../utils/translate';
import Archive from '../helpers/Archive';
import Icon from '../helpers/Icon';

// Add debounce hook at the top level
const useDebounce = (callback: (...args: any[]) => void, delay: number) => {
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>();

  useEffect(() => () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }, []);

  return useCallback((...args: any[]) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      callback(...args);
    }, delay);
  }, [callback, delay]);
};

const ArticleArchive = forwardRef(({ listType, articleType }: { listType: 'table' | 'grid', articleType: 'education' }, ref: any) => {
  const navigate = useNavigate();
  const imageUrlDomain = process.env.NODE_ENV === 'development' ? 'http://localhost:8076' : '';
  const archive = useRef<{ getItems:() => Promise<void> }>({ getItems: async () => {} });

  const [typesSelection, setTypesSelection] = useState<string[]>([]);
  const typesOptions = [
    { label: __('Education'), value: 'education' },
    { label: __('Help'), value: 'help' },
    { label: __('Knowledge'), value: 'knowledge' },
    { label: __('News'), value: 'news' }
  ];

  const [categoriesSelection, setCategoriesSelection] = useState<string[]>([]);
  const [categories, setCategories] = useState(({ categories: [] } as { categories: ArticleCategory[] }));
  const getCategories = async () => setCategories(await get('articles/categories', { type: articleType }));

  const [tagsSelection, setTagsSelection] = useState<string[]>([]);
  const [tags, setTags] = useState(({ tags: [] } as { tags: ArticleTag[] }));
  const getTags = async () => setTags(await get('articles/tags', { type: articleType }));

  const [published, setPublished] = useState<null | string>(null);

  const categoriesOptions = categories.categories.map((category) => ({ label: category.name, value: category.id }));
  const tagsOptions = tags.tags.map((tag) => ({ label: tag.name, value: tag.id }));

  const handleReorder = async (id: string, direction: 'up' | 'down') => {
    try {
      await post('articles/reorder', { id, direction });
      archive.current.getItems();
    } catch (error) {
      console.error('Error reordering article:', error);
    }
  };

  const handleSortOrderChange = async (id: string, value: number) => {
    try {
      await post('articles/reorder', { id, sort_order: value });
      archive.current.getItems();
    } catch (error) {
      console.error('Error updating sort order:', error);
    }
  };

  const debouncedHandleSortOrderChange = useDebounce(handleSortOrderChange, 1500);

  useEffect(() => {
    getCategories();
    getTags();
  }, [articleType]);

  const slug = {
    education: 'support'
  };

  const [activeTab, setActiveTab] = useState<TabsValue>('education');

  const handleTabChange = (value: TabsValue) => {
    setActiveTab(value || 'education');
    // Reset selections when changing tabs
    setTypesSelection([value as string || 'education']);
  };

  // Initialize typesSelection with activeTab on component mount
  useEffect(() => {
    setTypesSelection([activeTab as string]);
  }, []);

  return (
    <>
      <Tabs value={activeTab} onTabChange={handleTabChange} mb="md">
        <Tabs.List>
          {typesOptions.map((type) => (
            <Tabs.Tab key={type.value} value={type.value}>
              {type.label}
            </Tabs.Tab>
          ))}
        </Tabs.List>
      </Tabs>

      <Archive
        limit={20}
        ref={archive}
        request="articles"
        model="articles"
        type={listType}
        order={{ 'Articles.sort_order': 'ASC', 'Articles.name': 'ASC' }}
        languageType="language"
        onReorder={handleReorder}
        conditions={{
          type: articleType,
          categories: categoriesSelection,
          tags: tagsSelection,
          education_type: [activeTab as string],
          ...(published ? { published: published === 'published' } : {})
        }}
        extraSearchOptions={(
          <Group grow>
            <MultiSelect
              data={categoriesOptions}
              placeholder={__('Filter on categories')}
              value={categoriesSelection}
              onChange={(value) => setCategoriesSelection(value)}
            />
            <MultiSelect
              data={tagsOptions}
              placeholder={__('Filter on tags')}
              value={tagsSelection}
              onChange={(value) => setTagsSelection(value)}
            />
            <Select
              data={[
                { label: __('Published'), value: 'published' },
                { label: __('Unpublished'), value: 'unpublished' }
              ]}
              placeholder={__('Filter on published')}
              value={published}
              clearable
              onChange={(value) => setPublished(value)}
            />
          </Group>
        )}
        columns={[
          { key: 'name', label: __('Name') },
          { key: 'public', label: __('Public') },
          { key: 'categories', label: __('Categories') },
          { key: 'tags', label: __('Tags') },
          { key: 'created', label: __('Created'), textAlign: 'right' },
          { key: 'updated', label: __('Updated'), textAlign: 'right' },
          { key: 'synced', label: __('Last sync'), textAlign: 'right' }
        ]}
      >
        {(item: Article) => (
          listType === 'grid' ? (
            <Paper
              onClick={(e) => navigate(`/${slug[articleType]}/articles/${item.id}`)}
              sx={(theme) => ({
                backgroundColor: theme.colors.dark[6],
                position: 'relative',
                overflow: 'hidden',
                cursor: 'pointer',
                transition: 'transform 0.2s ease-in-out',
                '&:hover': {
                  transform: 'scale(1.02)'
                }
              })}
            >
              <BackgroundImage
                key={item.id}
                src={`${imageUrlDomain}/images/view/${item.image}`}
                sx={{
                  backgroundColor: stringToHash(item.id),
                  position: 'relative',
                  paddingTop: '100%'
                }}
              />

              <Group position="apart" px="md" pt="md" className="article-controls">
                <ActionIcon
                  onClick={(e) => {
                    e.stopPropagation();
                    handleReorder(item.id, 'up');
                  }}
                  variant="transparent"
                  size="xl"
                  className="reorder-button"
                >
                  <Icon name="arrow-left" wrapper={false} size="lg" />
                </ActionIcon>

                <NumberInput
                  value={item.sort_order}
                  onChange={(value) => {
                    if (typeof value === 'number') {
                      debouncedHandleSortOrderChange(item.id, value);
                    }
                  }}
                  onClick={(e) => e.stopPropagation()}
                  min={0}
                  hideControls
                  styles={{
                    input: {
                      textAlign: 'center',
                      width: '80px'
                    }
                  }}
                />

                <ActionIcon
                  onClick={(e) => {
                    e.stopPropagation();
                    handleReorder(item.id, 'down');
                  }}
                  variant="transparent"
                  size="xl"
                  className="reorder-button"
                >
                  <Icon name="arrow-right" wrapper={false} size="lg" />
                </ActionIcon>
              </Group>

              <Box className="card-content" p="md">
                <Title order={3}>
                  {item.name}
                </Title>
                <Text mb="sm">
                  {item.description.substring(0, 50)}
                </Text>
                <div>
                  {item.articles_categories.map((category) => (
                    <Badge
                      key={category.id}
                      variant="gradient"
                      gradient={{ from: stringToHash(category.id), to: stringToHash(category.name) }}
                      onClick={(e) => {
                        e.stopPropagation();
                        navigate(`/${slug[articleType]}/articles/categories/${category.id}`);
                      }}
                      mr="xs"
                      leftSection={<Icon wrapper={false} name="shapes" />}
                    >
                      {category.name}
                    </Badge>
                  ))}
                </div>
                <div>
                  {item.articles_tags.map((tag) => (
                    <Badge
                      key={tag.id}
                      variant="gradient"
                      gradient={{ from: stringToHash(tag.id), to: stringToHash(tag.name) }}
                      onClick={(e) => {
                        e.stopPropagation();
                        navigate(`/${slug[articleType]}/articles/tags/${tag.id}`);
                      }}
                      mr="xs"
                      leftSection={<Icon wrapper={false} name="hashtag" />}
                    >
                      {tag.name}
                    </Badge>
                  ))}
                </div>
              </Box>
            </Paper>
          ) : (
            <tr key={item.id} onClick={() => navigate(`/${slug[articleType]}/articles/${item.id}`)}>
              <td>{item.name}</td>
              <td>{item.published_at ? __('Yes') : __('No')}</td>
              <td align="left">
                {item.articles_categories
                  ? item.articles_categories.map((category) => (
                    <Badge
                      gradient={{ from: stringToHash(category.id), to: stringToHash(category.name) }}
                      onClick={(e) => {
                        e.stopPropagation();
                        navigate(`/${slug[articleType]}/articles/categories/${category.id}`);
                      }}
                      size="lg"
                      mr="xs"
                    >
                      {category.name}
                    </Badge>
                  ))
                  : null}
              </td>
              <td align="left">
                {item.articles_tags
                  ? item.articles_tags.map((tag) => (
                    <Badge
                      gradient={{ from: stringToHash(tag.id), to: stringToHash(tag.name) }}
                      onClick={(e) => {
                        e.stopPropagation();
                        navigate(`/${slug[articleType]}/articles/tags/${tag.id}`);
                      }}
                      size="lg"
                      mr="xs"
                    >
                      {tag.name}
                    </Badge>
                  ))
                  : null}
              </td>
              <td><Text align="right">{item.created_at.toLocaleDateString('sv-se')}</Text></td>
              <td><Text align="right">{item.updated_at.toLocaleDateString('sv-se')}</Text></td>
              <td><Text align="right">{item.published_at ? item.published_at.toLocaleDateString('sv-se') : '-'}</Text></td>
            </tr>
          )
        )}
      </Archive>
    </>
  );
});

export default ArticleArchive;
