import {
  Button,
  Group, TextInput
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { useContext, useEffect, useState } from 'react';
import { get, put, remove } from '../../utils/api';
import { handleResponceErrors } from '../../utils/helpers';
import { __ } from '../../utils/translate';
import LanguageContext from '../helpers/LanguageContext';

function EditCampaignTag({ id, onSubmit, onDelete }: {id: string, onSubmit: () => void, onDelete: () => void }) {
  const [loadingPut, setLoadingPut] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);

  const { language } = useContext(LanguageContext);

  const form = useForm({
    initialValues: {
      name: ''
    }
  });

  const getCampaignTag = async () => {
    const campaignTag = await get(`campaigns/tags/${id}`);

    form.setValues({
      name: campaignTag.campaign_tag.name
    });
  };

  useEffect(() => {
    getCampaignTag();
  }, [id]);

  const submit = async (data : any) => {
    setLoadingPut(true);

    data.county = language;

    const resp = await put(`campaigns/tags/${id}`, data);
    setLoadingPut(false);

    if (resp.status === 200) {
      if (onSubmit) {
        onSubmit();
      }
    } else {
      const errors = handleResponceErrors(resp.error);

      form.setErrors(errors);
    }
  };

  const deleteCampaignTag = async () => {
    setLoadingDelete(true);
    await remove(`campaigns/tags/${id}`);
    await onDelete();
    setLoadingDelete(false);
  };

  return (
    <form onSubmit={form.onSubmit((data) => submit(data))}>
      <TextInput
        {...form.getInputProps('name')}
        label={__('Name')}
        placeholder={__('Name')}
        mb="md"
      />

      <Group grow mt="md">
        <Button color="red" variant="light" onClick={() => deleteCampaignTag()} loading={loadingDelete}>
          {
            loadingDelete ? __('Deleting...') : __('Delete')
          }
        </Button>
        <Button type="submit" loading={loadingPut}>
          {
            loadingPut ? __('Saving...') : __('Save')
          }
        </Button>
      </Group>
    </form>
  );
}

export default EditCampaignTag;
