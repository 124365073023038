import {
  Flex, Group, Pagination, Paper, SimpleGrid, Stack, Table, Text, TextInput
} from '@mantine/core';
import {
  forwardRef,
  ReactElement, useContext, useEffect, useImperativeHandle, useState
} from 'react';
import { Pagination as PaginationModel } from '../../models/pagination';
import { get } from '../../utils/api';
import { __ } from '../../utils/translate';
import Icon from './Icon';
import LanguageContext from './LanguageContext';

export interface Column {
  key: string,
  label: string,
  // sortable?: boolean - Todo
  className?: string
  textAlign?: 'left' | 'center' | 'right'
  style?: Record<string, any>
}

const Archive = forwardRef(({
  request,
  model,
  columns,
  children,
  limit,
  order,
  conditions,
  type,
  searchable,
  extraQuery,
  flush,
  gridSize,
  extraSearchOptions,
  languageType,
  onReorder
}: {
  request: string,
  model: string,
  columns?: Column[],
  children: (item: any, context: { items: any[] }) => ReactElement,
  limit?: number,
  order?: Record<string, 'ASC' | 'DESC'>,
  conditions?: Record<string, any>,
  type?: 'table' | 'grid',
  searchable?: boolean,
  extraQuery?: Record<string, any>,
  flush?: boolean
  gridSize?: number
  extraSearchOptions?: ReactElement
  languageType?: 'country' | 'language'
  onReorder?: (id: string, direction: 'up' | 'down') => Promise<void>
}, ref: any) => {
  const { language, languageAlternativeCodes } = useContext(LanguageContext);

  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [items, setItems]: [any[], any] = useState([]);
  const [pagination, setPagination]: [PaginationModel, any] = useState({
    count: 0,
    current: 0,
    direction: false,
    directionDefault: false,
    finder: 'all',
    limit: 0,
    nextPage: false,
    page: 0,
    pageCount: 0,
    perPage: 0,
    prevPage: false,
    scope: null,
    sort: null,
    sortDefault: false
  });
  const getItems = async () => {
    setLoading(true);

    const o = order;
    const c = conditions;

    const lang = {} as Record<string, string>;

    if (language && languageType) {
      if (languageType === 'country') {
        lang[languageType] = languageAlternativeCodes[language];
      } else {
        lang[languageType] = language;
      }
    }

    const response = await get(request, {
      ...lang,
      page,
      limit,
      ...(o ? { order: o } : {}),
      ...(c ? { conditions: c } : {}),
      ...(search ? { search } : {}),
      ...extraQuery
    });
    try {
      setPagination(response.pagination[model]);
      setItems(response[model]);
    } catch (e) {
      console.error(e);
      setPagination({});
      setItems([]);
    }

    setLoading(false);
  };
  useEffect(() => {
    if (language) {
      getItems();
    }
  }, [language, page, order, conditions]);

  useImperativeHandle(ref, () => ({
    getItems: async () => {
      await getItems();
    }
  }), [getItems]);

  useEffect(() => {
    const searchTimeout = setTimeout(() => {
      getItems();
    }, 300);
    return () => clearTimeout(searchTimeout);
  }, [search]);
  return (
    <div className="archive-wrapper">
      {searchable && (
        <Paper shadow="md" p={!flush ? 'md' : '0'}>
          <Stack>
            <TextInput
              placeholder={__('Search')}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            {extraSearchOptions && (
              <Group grow>{extraSearchOptions}</Group>
            )}
          </Stack>
        </Paper>
      )}
      {!loading ? (
        type === 'table' ? (
          <Paper shadow="md" p={!flush ? 'md' : '0'} mt="md">
            <Table highlightOnHover striped horizontalSpacing="md" verticalSpacing="md">
              <thead>
                <tr>
                  {(columns as Column[]).map((column: Column) => (
                    <th key={column.key} style={column.style}>
                      <Group spacing="xs" grow>
                        <Text align={column.textAlign || 'left'}>{column.label}</Text>
                      </Group>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="cursor-pointer">
                {items && items.length > 0 ? (
                  items.map((item) => children(item, { items }))
                ) : null}
              </tbody>
            </Table>
          </Paper>
        ) : (
          <SimpleGrid cols={gridSize} spacing="md" mt="md">
            {items.length > 0 ? (
              items.map((item) => children(item, { items }))
            ) : null}
          </SimpleGrid>
        )
      ) : (
        <Flex justify="center" my="lg">
          <Icon name="spinner-third" spin wrapper={false} size="7x" />
        </Flex>
      )}
      {pagination && pagination.pageCount > 1 && (
        <Paper shadow="md" p={!flush ? 'md' : '0'} mt="md">
          <Group position="center">
            <Pagination page={page} onChange={setPage} total={pagination.pageCount} />
          </Group>
        </Paper>
      )}
    </div>
  );
});
Archive.defaultProps = {
  limit: 10,
  order: { name: 'ASC' },
  conditions: undefined,
  type: 'table',
  searchable: true,
  extraQuery: {},
  columns: [],
  flush: false,
  gridSize: 4,
  extraSearchOptions: undefined,
  languageType: 'country',
  onReorder: undefined
};
export default Archive;
