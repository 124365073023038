import { Select, Stack, TextInput } from '@mantine/core';
import { useListState } from '@mantine/hooks';
import { useEffect, useState } from 'react';
import { ArticleChapterBlockImage as ArticleChapterBlockImageModel } from '../../../models/articles/articleChapterBlockImage';
import { __ } from '../../../utils/translate';
import ImageHandle from '../../helpers/ImageHandle';

function ArticleChapterBlockImage({ initialValues, submit, disable }: { initialValues: ArticleChapterBlockImageModel, submit: Function, disable?: string[]}) {
  const [title, setTitle] = useState(initialValues?.title || '');
  const [images, handleImages] = useListState(initialValues?.images ? initialValues?.images : (initialValues?.image ? [initialValues?.image] : []));
  // const [orientation, setOrientation] = useState(initialValues.orientation ?? 'horizontal');
  const [size, setSize] = useState(initialValues?.size || 'fluid');

  useEffect(() => {
    submit({
      title, images, size
    });
  }, [title, images, size]);

  return (
    <Stack className="image-wrapper">
      <TextInput
        placeholder={__('Title')}
        label={__('Title')}
        mb="sm"
        value={title}
        onChange={(event) => setTitle(event.currentTarget.value)}
      />
      <ImageHandle
        maxFiles={8}
        initialImages={initialValues?.images as any || []}
        onChange={(imagesIds: string[]) => {
          handleImages.setState(imagesIds.map((id) => ({ id } as any)));
        }}
      />
      <Select
        value={size}
        label={__('Display size')}
        data={[
          { value: 'fluid', label: __('Fluid') },
          { value: 'sm', label: __('Small') },
          { value: 'md', label: __('Medium') },
          { value: 'lg', label: __('Large') }
        ]}
        disabled={disable?.includes('size')}
        onChange={(value: 'fluid' | 'sm' | 'md' | 'lg') => (value !== null ? setSize(value) : null)}
      />
    </Stack>
  );
}

ArticleChapterBlockImage.defaultProps = {
  disable: []
};

export default ArticleChapterBlockImage;
