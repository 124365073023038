import { Table, Text } from '@mantine/core';
import { useContext, useState, useEffect } from 'react';
import { openConfirmModal } from '@mantine/modals';
import { Article } from '../../models/articles/article';
import { get, put } from '../../utils/api';
import { __ } from '../../utils/translate';
import Language from '../../components/helpers/LanguageContext';

function TrashArticle({ type, onSubmit }: { type: 'education', onSubmit: Function }) {
  const { language } = useContext(Language);
  const [articles, setArticles]: [{ articles: Article[] }, any] = useState({ articles: [] });
  const getArticles = async () => setArticles(await get('articles', { type, ...(language ? { language } : {}), removed: true }));

  useEffect(() => {
    getArticles();
  }, [language]);

  const restoreArticle = async (id: string) => {
    await openConfirmModal({
      title: __('Are you sure you want to restore this article?'),
      labels: { confirm: __('Confirm'), cancel: __('Cancel') },
      onConfirm: async () => {
        const article = await put(`articles/${id}`, { removed_at: null });
        if (article.error) return;
        onSubmit();
      }
    });
  };

  return (
    <div className="trash-article">
      <Table highlightOnHover striped horizontalSpacing="md" verticalSpacing="md">
        <thead>
          <tr>
            <th><Text>{__('Name')}</Text></th>
            <th><Text align="right">{__('Removed')}</Text></th>
          </tr>
        </thead>
        <tbody>
          {articles.articles.map((article) => (
            <tr key={article.id} onClick={() => restoreArticle(article.id)}>
              <td align="left">{article.name}</td>
              <td align="right">{article.removed_at ? new Date(article.removed_at).toLocaleString('sv-SE', { timeZone: 'UTC' }) : '-'}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
}

export default TrashArticle;
