import {
  Button, Divider, Grid, Group, NumberInput, Space, Textarea, TextInput
} from '@mantine/core';
import { useForm } from '@mantine/form';
import {
  useContext, useEffect, useRef, useState
} from 'react';
import {
  get,
  post,
  put,
  remove
} from '../../utils/api';
import { handleResponceErrors } from '../../utils/helpers';
import { __ } from '../../utils/translate';
import Icon from '../helpers/Icon';
import ImageHandle from '../helpers/ImageHandle';
import LanguageContext from '../helpers/LanguageContext';

function AddPartner({ onSubmit, editPartnerId }: { onSubmit: () => void, editPartnerId?: string }) {
  const { language } = useContext(LanguageContext);
  const [loading, setLoading] = useState(false);

  const form = useForm({
    initialValues: {
      name: '',
      description: '',
      image: '',
      image_logo: '',
      website: '',
      email: '',
      phone: '',
      services: [],
      sort_order: 0,
      country: language as 'swe' | 'nor' | 'eng'
    }
  });

  const imageRef = useRef<any>();
  const imageLogoRef = useRef<any>();

  const submit = async (props: {
    name: string;
    description: string;
    image: string;
    image_logo: string;
    website: string;
    email: string;
    phone: string;
    services: any;
    sort_order: number;
    country: 'swe' | 'nor' | 'eng';
  }) => {
    // setLoading(true);
    const data = props;

    data.country = language as 'swe' | 'nor' | 'eng';

    const imagesLogo = await imageLogoRef.current.submit();
    data.image_logo = imagesLogo[0] ? imagesLogo[0] : null;

    const images = await imageRef.current.submit();
    data.image = images[0] ? images[0] : null;

    const jsonServices = JSON.stringify(data.services.map((service: any) => service.value));
    let sendData = data;
    sendData = { ...sendData, services: jsonServices };
    let resp = null;

    if (editPartnerId) {
      resp = await put(`partners/partners/${editPartnerId}`, sendData);
    } else {
      resp = await post('partners/partners', sendData, false);
    }
    setLoading(false);

    if (resp.status === 200) {
      if (onSubmit) {
        onSubmit();
      }
    } else {
      const errors = handleResponceErrors(resp.error);
      form.setErrors(errors);
    }
  };

  const getEditPartner = async () => {
    const result = await get(`partners/partners/${editPartnerId}`);
    if (result.status === 200) {
      if (!result.partner.services) {
        result.partner.services = [];
      }
      result.partner.services = result.partner.services.map((service: string) => ({ value: service, id: service }));
      form.setValues(result.partner);
    } else {
      const errors = handleResponceErrors(result.error);
      form.setErrors(errors);
    }
  };

  const deletePartner = async (id: string) => {
    const confirmed = window.confirm('Are you sure you want to delete this partner?');
    if (confirmed) {
      await remove(`partners/partners/${id}`);
      // close this modal
      onSubmit();
    }
  };

  useEffect(() => {
    if (editPartnerId) {
      getEditPartner();
    }
  }, [editPartnerId]);

  return (
    <form onSubmit={form.onSubmit((data) => submit(data))}>
      <Grid>
        <Grid.Col span={12}>
          <TextInput
            required
            label={__('Name')}
            {...form.getInputProps('name')}
            mb="lg"
          />
          <Textarea
            label={__('Description')}
            {...form.getInputProps('description')}
            mb="lg"
          />
        </Grid.Col>
        <Grid.Col span={6}>
          <TextInput
            label={__('Webpage')}
            {...form.getInputProps('website')}
            mb="lg"
          />
          <NumberInput
            label={__('Sort order')}
            min={0}
            type="number"
            {...form.getInputProps('sort_order')}
            mb="lg"
          />
        </Grid.Col>
        <Grid.Col span={6}>
          <TextInput
            label={__('E-mail')}
            {...form.getInputProps('email')}
            mb="lg"
          />
          <TextInput
            label={__('Phone')}
            {...form.getInputProps('phone')}
            mb="lg"
          />
        </Grid.Col>
        <Grid.Col span={6}>
          <label htmlFor="image_logo">{__('Logo image')}</label>
          <ImageHandle
            key="logo"
            ref={imageLogoRef}
            error={form.errors.image_logo}
            initialImages={form.values.image_logo ? [{ id: form.values.image_logo }] : []}
          />
        </Grid.Col>
        <Grid.Col span={6}>
          <label htmlFor="image">{__('Featured image')}</label>
          <ImageHandle
            key="image"
            ref={imageRef}
            error={form.errors.image}
            initialImages={form.values.image ? [{ id: form.values.image }] : []}
          />
        </Grid.Col>
        <Grid.Col span={12}>
          <label htmlFor="services">{__('Services')}</label>
          <Space h="xs" />
          {form.values.services.map((service: any, index: number) => (
            <Grid key={service.id} align="flex-end">
              <Grid.Col xs={10} md={11}>
                <TextInput
                  placeholder={__('Service')}
                  {...form.getInputProps(`services.${index}.value`)}
                />
              </Grid.Col>
              <Grid.Col xs={2} md={1}>
                <Button fullWidth onClick={() => form.removeListItem('services', index)} color="red" variant="light" size="xs">
                  <Icon name="multiply" wrapper={false} />
                </Button>
              </Grid.Col>
            </Grid>
          ))}
        </Grid.Col>
      </Grid>
      <Button mt="xs" size="xs" onClick={() => form.insertListItem('services', { id: `newValue${form.values.services.length}`, value: '' })}>
        {__('Add service')}
      </Button>
      <Divider mb="xs" mt="xs" />
      <Group position="right">
        {editPartnerId && (
          <Button onClick={() => deletePartner(editPartnerId)} color="red" variant="light">
            {__('Delete')}
          </Button>
        )}
        <Button type="submit" loading={loading}>
          {
            loading ? `${__('Saving')}...` : __('Save')
          }
        </Button>
      </Group>
    </form>
  );
}

AddPartner.defaultProps = {
  editPartnerId: null
};

export default AddPartner;
