import { useContext } from 'react';
import {
  Title, Container, Stack, Grid
} from '@mantine/core';
import { __ } from '../../utils/translate';
import Widget from '../../components/helpers/Widget';
import LanguageContext from '../../components/helpers/LanguageContext';

function Marketinghub() {
  const { language } = useContext(LanguageContext);

  return (
    <div className="marketinghub-wrapper">
      <Container px="md" py="lg" size="lg">
        <Title order={1} mb="sm">{__('Marketinghub')}</Title>
        <Stack>
          <div className="articles-wrapper">
            <Title order={2}>{__('Templates')}</Title>
            <Title order={3}>{__('Template groups')}</Title>
            <Title order={3}>{__('Template tags')}</Title>
            <Title order={2}>{__('Marketingsplans')}</Title>
            <Title order={3}>{__('Marketingsplan groups')}</Title>
          </div>
        </Stack>
      </Container>
    </div>
  );
}

export default Marketinghub;
