import {
  Box, Stack
} from '@mantine/core';
import { useEffect, useState } from 'react';
import { ArticleChapterBlockCta as ArticleChapterBlockCtaModel } from '../../../models/articles/articleChapterBlockCta';
import ArticleChapterBlockButton from './ArticleChapterBlockButton';
import ArticleChapterBlockText from './ArticleChapterBlockText';

function ArticleChapterBlockCta({ initialValues, submit }: { initialValues: ArticleChapterBlockCtaModel, submit: Function}) {
  const [text, setText] = useState(initialValues?.text || {});
  const [button, setButton] = useState(initialValues?.button || {});

  useEffect(() => { submit({ text, button }); }, [text, button]);

  return (
    <Stack className="button-wrapper">
      <Box sx={(theme) => ({
        backgroundColor: theme.colors.black[7],
        padding: theme.spacing.lg,
        borderRadius: theme.radius.md
      })}
      >
        <ArticleChapterBlockText initialValues={text} submit={(data : ArticleChapterBlockCtaModel['text']) => setText(data)} />
      </Box>
      <Box sx={(theme) => ({
        backgroundColor: theme.colors.black[7],
        padding: theme.spacing.lg,
        borderRadius: theme.radius.md
      })}
      >
        <ArticleChapterBlockButton initialValues={button} submit={(data: ArticleChapterBlockCtaModel['button']) => setButton(data)} />
      </Box>
    </Stack>
  );
}

export default ArticleChapterBlockCta;
