import { useState } from 'react';
import {
  Box, Button, Collapse, Group
} from '@mantine/core';
import { __ } from '../../utils/translate';

export default function AdvancedSettings({ children, title }: { children: any, title?: string}) {
  const [opened, setOpened] = useState(false);

  return (
    <Box>
      <Group position="right">
        <Button onClick={() => setOpened((o) => !o)} size="xs" variant="outline" color="primary">
          {title}
        </Button>
      </Group>

      <Collapse in={opened} mt="md">
        {children}
      </Collapse>
    </Box>
  );
}

AdvancedSettings.defaultProps = {
  title: __('Advanced settings')
};
