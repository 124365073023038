import { useContext, useEffect, useState } from 'react';
import {
  Button, Text, Table, Container, Paper, Title, Flex, Badge
} from '@mantine/core';
import { closeModal, openModal } from '@mantine/modals';
import { useNavigate } from 'react-router-dom';
import { get } from '../../../../utils/api';
import AddArticleTag from '../../../../components/articles/AddArticleTag';
import { __ } from '../../../../utils/translate';
import { ArticleTag } from '../../../../models/articles/articleTag';
import LanguageContext from '../../../../components/helpers/LanguageContext';
import Icon from '../../../../components/helpers/Icon';
import { stringToHash } from '../../../../utils/helpers';

function Index() {
  const { language } = useContext(LanguageContext);
  const [articleTags, setArticleTags]: [{ tags: ArticleTag[] }, any] = useState({ tags: [] });
  const getArticleTags = async () => setArticleTags(await get('articles/tags', { type: 'education', language }));

  const navigate = useNavigate();

  useEffect(() => {
    getArticleTags();
  }, [language]);

  const addArticleTag = () => {
    openModal({
      modalId: 'add-article-tag',
      title: __('Add article tag'),
      size: 'xl',
      children: (
        <AddArticleTag
          type="education"
          onSubmit={() => {
            closeModal('add-article-tag');
            getArticleTags();
          }}
        />
      )
    });
  };

  return (
    <div className="article-wrapper p-rel">
      <Container px="md" py="lg" size="lg">
        <Flex justify="space-between" align="center">
          <Title order={1} mb="sm">{__('Article tags')}</Title>
          <Button onClick={addArticleTag}>
            <Icon wrapper={false} name="plus" library="fas" />
          </Button>
        </Flex>

        <Paper shadow="sm" p="md">
          <Table highlightOnHover striped horizontalSpacing="md" verticalSpacing="md">
            <thead>
              <tr>
                <th><Text>{__('Name')}</Text></th>
                <th><Text align="center">{__('Articles')}</Text></th>
                <th><Text align="right">{__('Created')}</Text></th>
              </tr>
            </thead>
            <tbody>
              {articleTags.tags.map((articleTag) => (
                <tr key={articleTag.id} onClick={() => navigate(`/support/articles/tags/${articleTag.id}`)}>
                  <td align="left">
                    <Badge
                      size="lg"
                      variant="gradient"
                      gradient={{ from: stringToHash(articleTag.name), to: stringToHash(articleTag.id) }}
                    >
                      {articleTag.name}
                    </Badge>
                  </td>
                  <td align="center">{articleTag.articles.length}</td>
                  <td align="right">{new Date(articleTag.created_at).toLocaleDateString()}</td>
                </tr>
              ))}

            </tbody>
          </Table>
        </Paper>
      </Container>
    </div>
  );
}

export default Index;
