import {
  Box,
  Button,
  Group,
  MultiSelect,
  Select,
  SimpleGrid,
  Space,
  Textarea,
  TextInput
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { openConfirmModal } from '@mantine/modals';
import {
  useContext, useEffect, useRef, useState
} from 'react';
import { ArticleCategory } from '../../models/articles/articleCategory';
import { ArticleTag } from '../../models/articles/articleTag';
import {
  get
} from '../../utils/api';
import { __ } from '../../utils/translate';
import ArticleContext from '../helpers/ArticleContext';
import ImageHandle from '../helpers/ImageHandle';
import LanguageContext from '../helpers/LanguageContext';

// eslint-disable-next-line no-unused-vars
function EditArticle({ onSubmit, onDelete }: { onSubmit: () => void, onDelete: () => void }) {
  const {
    article,
    putArticle,
    deleteArticle
  } = useContext(ArticleContext);

  const { language } = useContext(LanguageContext);

  const [{ categories }, setCategories] = useState(({} as { categories: ArticleCategory[] }));
  const getCategories = async (type: 'education') => setCategories(await get('articles/categories', { type, language }));
  const [{ tags }, setTags] = useState(({} as { tags: ArticleTag[] }));
  const getTags = async (type: 'education') => setTags(await get('articles/tags', { type, language }));

  const form = useForm({
    initialValues: {
      name: article.name,
      description: article.description,
      articles_categories: article.articles_categories.map((category: ArticleCategory) => category.id),
      articles_tags: article.articles_tags.map((tag: ArticleTag) => tag.id),
      image: article.image,
      image_archive: article.image_archive,
      education_type: article.education_type
    }
  });

  const categoriesOpitons = categories ? categories.map((category) => ({
    label: category.name,
    value: category.id
  })) : [];

  const tagsOptions = tags ? tags.map((tag) => ({
    label: tag.name,
    value: tag.id
  })) : [];

  const educationTypeOptions = [
    { label: __('Education'), value: 'education' },
    { label: __('Help'), value: 'help' },
    { label: __('Knowledge'), value: 'knowledge' },
    { label: __('News'), value: 'news' }
  ];

  const removeArticle = async () => {
    await openConfirmModal({
      title: __('Are you sure you want archive this article?'),
      labels: { confirm: __('Confirm'), cancel: __('Cancel') },
      onConfirm: async () => {
        deleteArticle();
        if (onDelete) {
          onDelete();
        }
      }
    });
  };

  useEffect(() => {
    async function init() {
      await getCategories(article.type);
      await getTags(article.type);
    }

    init();
  }, []);

  const imageRef = useRef<any>();
  const imageArchiveRef = useRef<any>();

  const submit = async (props: any) => {
    const data = props;

    const images = await imageRef.current.submit();
    data.image = images[0] ? images[0] : null;

    const imagesArchive = await imageArchiveRef.current.submit();
    data.image_archive = imagesArchive[0] ? imagesArchive[0] : null;

    putArticle({
      ...data,
      articles_categories: data.articles_categories.map((category: Record<string, any>) => ({ id: category })) as { id: Record<string, any> }[],
      articles_tags: data.articles_tags.map((tag: Record<string, any>) => ({ id: tag })) as { id: Record<string, any> }[]

    });

    if (onSubmit) {
      onSubmit();
    }
  };

  return (
    <form onSubmit={form.onSubmit((data) => submit(data))}>
      <TextInput
        required
        label={__('Name')}
        {...form.getInputProps('name')}
        maxLength={64}
        mb="lg"
      />
      <Textarea
        label={__('Excerpt')}
        {...form.getInputProps('description')}
        maxLength={255}
        mb="lg"
      />

      <SimpleGrid spacing="lg" cols={2}>
        <Box>
          <label htmlFor="image">{__('Archive image')}</label>

          <ImageHandle
            ref={imageArchiveRef}
            initialImages={form.values.image_archive ? [{ id: form.values.image_archive }] : []}
          />
        </Box>
        <Box>
          <label htmlFor="image">{__('Single page image')}</label>

          <ImageHandle
            ref={imageRef}
            initialImages={form.values.image ? [{ id: form.values.image }] : []}
          />
        </Box>
      </SimpleGrid>

      <Space h="sm" />

      <Select
        data={educationTypeOptions}
        label={__('Type')}
        placeholder={__('Select type')}
        {...form.getInputProps('education_type')}
        mb="lg"
      />

      <MultiSelect
        data={categoriesOpitons}
        label={__('Category')}
        placeholder={__('Select categories')}
        {...form.getInputProps('articles_categories')}
        mb="lg"
      />

      <MultiSelect
        data={tagsOptions}
        label={__('Tags')}
        placeholder={__('Select tags')}
        {...form.getInputProps('articles_tags')}
        mb="lg"
      />

      <Space h="md" />

      <Group grow>
        <Button color="red" variant="light" onClick={() => removeArticle()}>
          { __('Delete') }
        </Button>
        <Button type="submit">
          { __('Save') }
        </Button>
      </Group>
    </form>
  );
}

export default EditArticle;
