import {
  Box, Button, Checkbox, Divider, Group, Stack, Text, TextInput
} from '@mantine/core';
import { useListState } from '@mantine/hooks';
import { useEffect, useState } from 'react';
import { ArticleChapterBlockQuestion as ArticleChapterBlockQuestionModel, Question } from '../../../models/articles/articleChapterBlockQuestion';
import { __ } from '../../../utils/translate';
import Icon from '../../helpers/Icon';

function ArticleChapterBlockQuestion({ initialValues, submit }: { initialValues: ArticleChapterBlockQuestionModel, submit: Function }) {
  const [title, setTitle] = useState(initialValues?.title || '');
  const [multipile, setMultipile] = useState(initialValues?.multipile || false);
  const [requirements, setRequirements] = useState(initialValues?.requirements || 'any');
  const [questions, questionsHandler] = useListState(initialValues?.questions || [{ label: '', isCorrectAnswer: true, key: Math.random().toString() }]);
  const [label, setLabel] = useState(initialValues?.label || '');
  const [description, setDescription] = useState(initialValues?.description || '');
  const [error, setError] = useState(initialValues?.error || '');

  useEffect(() => {
    submit({
      title, multipile, requirements, questions, label, description, error
    });
  }, [title, multipile, requirements, questions, label, description, error]);

  const addQuestion = () => {
    questionsHandler.append({ label: '', isCorrectAnswer: false, key: Math.random().toString() });
  };

  const deleteQuestion = (index: number) => {
    questionsHandler.remove(index);
  };

  const moveQuestion = (index: number, dir: number) => {
    questionsHandler.reorder({ from: index, to: index + dir });
  };
  return (
    <Stack className="question-wrapper">
      <TextInput
        placeholder={__('Title')}
        value={title}
        onChange={(event) => setTitle(event.currentTarget.value)}
        label={__('Title')}
        mb="lg"
      />
      <Divider mb="md" />
      <TextInput
        required
        label={__('Label')}
        placeholder={__('What is potato?')}
        value={label}
        onChange={(event) => setLabel(event.currentTarget.value)}
        mb="lg"
      />
      <TextInput
        label={__('Description')}
        placeholder={__('Click the correct answer to continue')}
        value={description}
        onChange={(event) => setDescription(event.currentTarget.value)}
        mb="lg"
      />
      <TextInput
        label={__('Error')}
        placeholder={__('I will show when the user selects the wrong answer')}
        value={error}
        onChange={(event) => setError(event.currentTarget.value)}
        mb="lg"
      />
      <Box
        className="questions-list"
        mb="lg"
        sx={(theme) => ({
          backgroundColor: theme.colors.black[7],
          padding: theme.spacing.lg,
          borderRadius: theme.radius.md
        })}
      >
        <Text>{__('Answers')}</Text>
        {questions.map((question: Question, index: number) => {
          const isFirst = index === 0;
          const isLast = index === questions.length - 1;

          return (
            <Box
              className="question"
              key={question.key}
              sx={(theme) => ({
                backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[0],
                padding: theme.spacing.xl,
                marginTop: theme.spacing.xs,
                marginBottom: theme.spacing.lg,
                borderRadius: theme.radius.md
              })}
            >

              <Group position="right">
                <Group className="toolbar-wrapper">
                  <Button.Group>
                    <Button
                      variant={isFirst ? 'light' : 'filled'}
                      disabled={isFirst}
                      onClick={() => moveQuestion(index, -1)}
                      size="xs"
                    >
                      <Icon wrapper={false} name="up" />
                    </Button>
                    <Button
                      variant={isLast ? 'light' : 'filled'}
                      disabled={isLast}
                      onClick={() => moveQuestion(index, 1)}
                      size="xs"
                    >
                      <Icon wrapper={false} name="down" />
                    </Button>
                  </Button.Group>
                  <Button onClick={() => deleteQuestion(index)} size="xs">
                    <Icon wrapper={false} name="xmark" library="fas" />
                  </Button>
                </Group>
              </Group>

              <TextInput
                required
                label={__('Label')}
                placeholder={__('Potato is a vegetable')}
                value={question.label}
                onChange={(event) => questionsHandler.setItemProp(index, 'label', event.currentTarget.value)}
                mb="lg"
              />
              <Checkbox
                label={__('This is correct')}
                mb="lg"
                checked={question.isCorrectAnswer}
                onChange={(event) => questionsHandler.setItemProp(index, 'isCorrectAnswer', event.currentTarget.checked)}
              />
            </Box>
          );
        })}
      </Box>
      <Divider mb="md" />
      <Group>
        <Button className="add-question" onClick={() => addQuestion()}>{__('Add awnser')}</Button>
        <Checkbox
          label={__('Multiple choice')}
          checked={multipile}
          onChange={(event) => setMultipile(event.currentTarget.checked)}
        />
        <Checkbox
          label={__('All answers must be correct')}
          checked={requirements === 'all'}
          onChange={(event) => setRequirements(event.currentTarget.checked ? 'all' : 'any')}
        />
      </Group>
    </Stack>
  );
}

export default ArticleChapterBlockQuestion;
