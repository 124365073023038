import {
  Box,
  Button, Checkbox, Col, Divider, Grid, Slider, Stack
} from '@mantine/core';
import { useListState } from '@mantine/hooks';
import { closeModal, openModal } from '@mantine/modals';
import { ReactNode, useEffect, useState } from 'react';
import { ArticleChapterBlock } from '../../../models/articles/articleChapterBlock';
import { ArticleChapterBlockCols as ArticleChapterBlockColsModel } from '../../../models/articles/articleChapterBlockCols';
import { ArticleChapterBlockImage as ArticleChapterBlockImageModel } from '../../../models/articles/articleChapterBlockImage';
import { ArticleChapterBlockText as ArticleChapterBlockTextModel } from '../../../models/articles/articleChapterBlockText';
import { __ } from '../../../utils/translate';
import AdvancedSettings from '../../helpers/AdvancedSettings';
import Icon from '../../helpers/Icon';
import AddArticleChapterBlock from '../AddArticleChapterBlock';
import ArticleChapterBlockImage from './ArticleChapterBlockImage';
import ArticleChapterBlockText from './ArticleChapterBlockText';

function BlockInnerWrapper({ block, onSubmit }: { block: ArticleChapterBlock, onSubmit: (data: any) => void }) {
  const renderCol = (b: ArticleChapterBlock): ReactNode => {
    let res = <div />;
    switch (b.type) {
      case 'text':
        res = (
          <ArticleChapterBlockText
            initialValues={block as any}
            submit={(data: ArticleChapterBlockTextModel) => onSubmit(data)}
          />
        );
        break;
      case 'image':
        res = (
          <ArticleChapterBlockImage
            initialValues={block as any}
            submit={(data: ArticleChapterBlockImageModel) => onSubmit(data)}
          />
        );
        break;
      default:
    }
    return res;
  };

  return (
    <Box sx={(theme) => ({
      backgroundColor: theme.colors.black[7],
      padding: theme.spacing.lg,
      borderRadius: theme.radius.md
    })}
    >
      {block && renderCol(block)}
    </Box>
  );
}

function ArticleChapterBlockCols({ initialValues, submit }: { initialValues: ArticleChapterBlockColsModel, submit: Function }) {
  const [leftCol, setLeftCol] = useState<ArticleChapterBlock | null>(initialValues?.cols?.[0] && Object.keys(initialValues?.cols?.[0]).length > 0 ? initialValues?.cols?.[0] : null);
  const [rightCol, setRightCol] = useState<ArticleChapterBlock | null>(initialValues?.cols?.[1] && Object.keys(initialValues?.cols?.[1]).length > 0 ? initialValues?.cols?.[1] : null);
  const [width, setWidth] = useState(initialValues?.width || 6);
  const [reverseOrder, setReverseOrder] = useState(initialValues?.reverseOrder || false);

  useEffect(() => {
    const cols = [leftCol || {}, rightCol || {}].filter(Boolean);
    submit({ cols, width, reverseOrder });
  }, [leftCol, rightCol, width, reverseOrder]);

  const addBlock = async (colIndex: 0 | 1) => {
    openModal({
      modalId: 'add-article-chapter-block-block',
      title: __('Add article chapter block'),
      size: 'lg',
      children: (
        <AddArticleChapterBlock
          allowedTypes={['text', 'image']}
          onSubmit={async (type) => {
            const newBlock = {
              id: Math.random().toString(36).substring(7),
              type
            } as ArticleChapterBlock;

            if (colIndex === 0) {
              setLeftCol(newBlock);
            } else {
              setRightCol(newBlock);
            }
            closeModal('add-article-chapter-block-block');
          }}
        />
      )
    });
  };

  return (
    <Stack className="cols-wrapper">
      <Grid>
        <Col xs={width}>
          <Box sx={(theme) => ({
            backgroundColor: theme.colors.black[7],
            padding: leftCol ? 0 : theme.spacing.sm,
            borderRadius: theme.radius.md
          })}
          >
            {leftCol ? (
              <Box>
                <Box sx={(theme) => ({ textAlign: 'right', padding: theme.spacing.sm })}>
                  <Button onClick={() => setLeftCol(null)}>
                    <Icon library="fas" name="multiply" wrapper={false} />
                  </Button>
                </Box>
                <Divider />
                <BlockInnerWrapper
                  block={leftCol}
                  onSubmit={(data) => setLeftCol({ ...leftCol, ...data })}
                />
              </Box>
            ) : (
              <Box sx={{ textAlign: 'center' }}>
                <Button onClick={() => addBlock(0)}>{__('Add block')}</Button>
              </Box>
            )}
          </Box>
        </Col>
        <Col xs={12 - width}>
          <Box sx={(theme) => ({
            backgroundColor: theme.colors.black[7],
            padding: rightCol ? 0 : theme.spacing.sm,
            borderRadius: theme.radius.md
          })}
          >
            {rightCol ? (
              <Box>
                <Box sx={(theme) => ({ textAlign: 'right', padding: theme.spacing.sm })}>
                  <Button onClick={() => setRightCol(null)}>
                    <Icon library="fas" name="multiply" wrapper={false} />
                  </Button>
                </Box>
                <Divider />
                <BlockInnerWrapper
                  block={rightCol}
                  onSubmit={(data) => setRightCol({ ...rightCol, ...data })}
                />
              </Box>
            ) : (
              <Box sx={{ textAlign: 'center' }}>
                <Button onClick={() => addBlock(1)}>{__('Add block')}</Button>
              </Box>
            )}
          </Box>
        </Col>
      </Grid>
      <Slider label={`${__('Colums width')}: ${width}/${12 - width}`} value={width} onChange={setWidth} max={9} min={3} step={1} />
      <AdvancedSettings>
        <Checkbox
          label={__('Reverse order in mobile view')}
          checked={reverseOrder}
          onChange={(event) => setReverseOrder(event.currentTarget.checked)}
        />
      </AdvancedSettings>
    </Stack>
  );
}

export default ArticleChapterBlockCols;
