import { IconName } from '@fortawesome/fontawesome-svg-core';
import {
  Group, Image, Paper, Title, UnstyledButton
} from '@mantine/core';
import { __ } from '../../utils/translate';
import Icon from './Icon';

function WidgetPartner({
  title, image, callback, icon
}: {
  title: string, image: string, callback: () => void, icon: IconName
}) {
  return (
    <UnstyledButton
      component="div"
      onClick={callback}
    >
      <Paper
        shadow="sm"
        p="md"
        sx={(theme) => ({
          transition: '0.2s ease-in-out',
          '&:hover': {
            backgroundColor: theme.colors.dark[6]
          }
        })}
      >
        <Group>
          {
            image ? (
              <Image
                radius="md"
                src={image}
                height={48}
                width={48}
                withPlaceholder
              />
            ) : (
              <Icon name={icon} size={48} />
            )
          }
          <div>
            <Title order={3}>{title}</Title>
          </div>
        </Group>
      </Paper>
    </UnstyledButton>
  );
}

export default WidgetPartner;
