import {
  Card, Container, Divider, Stack, Table, Text, Title
} from '@mantine/core';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Icon from '../../../components/helpers/Icon';
import {
  get
} from '../../../utils/api';
import { __ } from '../../../utils/translate';

function BuildStatus() {
  const [queue, setQueue] = useState([]);
  const [current, setCurrent] = useState({
    id: '',
    building: false,
    startTimestamp: 0,
    estimatedDuration: 0,
    result: ''
  });
  const [history, setHistory] = useState([]);
  const getBuildLog = async () => {
    const result = await get('internal/buildlog');
    if (result.status === 200) {
      setQueue(result.queue);
      setCurrent(result.current);
      setHistory(result.history);
    }
  };

  useEffect(() => {
    getBuildLog();

    setInterval(() => {
      getBuildLog();
    }, 10000);
  }, []);

  const printEstimatedTime = (estimatedTime: number) => {
    if (estimatedTime === 0) {
      return '0s';
    }

    const seconds = Math.floor(estimatedTime / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);

    const secondsLeft = seconds % 60;
    const minutesLeft = minutes % 60;

    const res = [];

    if (hours > 0) {
      res.push(`${hours}h`);
    }

    if (minutesLeft > 0) {
      res.push(`${minutesLeft}m`);
    }

    if (secondsLeft > 0) {
      res.push(`${secondsLeft}s`);
    }

    return res.join(' ');
  };

  const printTimestamp = (timestamp: number) => {
    const date = new Date(timestamp);
    return date.toLocaleTimeString('sv-se');
  };

  /** Formating table */

  return (
    <div className="builds-wrapper">
      <Container px="md" py="lg" size="lg">
        <Title order={1} mb="md">{__('Build status')}</Title>
        <Card mb="sm">
          <Stack align="center">
            <Title>{current.id}</Title>
            <Text>
              {
              current.building
                ? `Estimated time left: ${printEstimatedTime((current.startTimestamp + current.estimatedDuration) - new Date().getTime())}`
                : current.result
              }
            </Text>
          </Stack>
        </Card>
        <Card mb="sm">
          <Title order={4}>{__('Build queue')}</Title>
          <Divider my="sm" />
          <Table>
            <thead>
              <tr>
                <th>{__('Key')}</th>
                <th>
                  <Text ta="right">
                    {__('Estimated start')}
                  </Text>
                </th>
              </tr>
            </thead>
            <tbody>
              {queue.map((build: any) => (
                <tr key={build.id}>
                  <td>{build.issue_key}</td>
                  <td className="text-right">
                    <Text ta="right">
                      {printTimestamp(build.startTimestamp)}
                    </Text>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card>

        <Card>
          <Title order={4}>{__('Build history')}</Title>
          <Divider my="sm" />
          <Table>
            <thead>
              <tr>
                <th>{__('Key')}</th>
                <th>
                  <Text ta="right">
                    {__('Finished')}
                  </Text>
                </th>
                <th>
                  <Text ta="right">
                    {__('Duration')}
                  </Text>
                </th>
                <th>
                  <Text ta="right">
                    {__('Result')}
                  </Text>
                </th>
              </tr>
            </thead>
            <tbody>
              {history.map((build: any) => (
                <tr key={build.id}>
                  <td>
                    <Link to={`/internal/builds/${build.id}`}>
                      {build.issue_key}
                    </Link>
                  </td>
                  <td className="text-right">
                    <Text ta="right">
                      {printTimestamp(build.timestamp)}
                    </Text>
                  </td>
                  <td className="text-right">
                    <Text ta="right">
                      {printEstimatedTime(build.duration)}
                    </Text>
                  </td>
                  <td className="text-right">
                    <Text ta="right">
                      {build.result === 'SUCCESS' && <Icon name="check" wrapper={false} size="2x" /> }
                      {build.result === 'FAILURE' && <Icon name="multiply" wrapper={false} color="red" size="2x" /> }
                    </Text>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card>
      </Container>
    </div>
  );
}

export default BuildStatus;
