import {
  Button, Group, MultiSelect, Textarea, TextInput
} from '@mantine/core';
import { FormErrors, useForm } from '@mantine/form';
import { useEffect, useState } from 'react';
import { ImageGroup } from '../../models/images/imageGroup';
import { get, put, remove } from '../../utils/api';
import { __ } from '../../utils/translate';

function EditImage({ id, onSubmit, onDelete }: { id: string, onSubmit: () => void, onDelete: () => void }) {
  const [loadingPut, setLoadingPut] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);

  const form = useForm({
    initialValues: {
      name: '',
      description: '',
      image_groups: []
    }
  });

  const [imageGroups, setImageGroups] = useState(({} as { imageGroups: ImageGroup[] }));
  const getImageGroups = async () => setImageGroups(await get('image-groups'));
  const getImage = async () => get(`images/view-data/${id}`);
  const putImage = async (tag: { name: string }) => put(`images/edit/${id}`, tag);
  const removeImage = async () => remove(`images/delete/${id}`);

  useEffect(() => {
    async function init() {
      const image = await getImage();
      form.setValues({
        name: image.name,
        description: image.description,
        image_groups: image.image_groups.map((ig: ImageGroup) => ig.id)
      });
    }
    init();
    getImageGroups();
  }, []);

  const imageGroupOptions = imageGroups.imageGroups ? imageGroups.imageGroups.map((ig) => ({
    label: ig.name,
    value: ig.id
  })) : [];

  const submit = async (data: any) => {
    setLoadingPut(true);
    data.image_groups_images = form.values.image_groups?.map((imageGroupId) => ({ image_group_id: imageGroupId }));

    const resp = await putImage(data);
    setLoadingPut(false);

    if (resp.status === 200) {
      if (onSubmit) {
        onSubmit();
      }
    } else if (resp.error) {
      const errors = {} as FormErrors;

      Object.keys(resp.error).forEach((key) => {
        errors[key] = Object.values(resp.error[key]);
      });

      form.setErrors(errors);
    }
  };

  const deleteImage = async () => {
    setLoadingDelete(true);
    const resp = await removeImage();

    if (resp.status === 200) {
      if (onDelete) {
        onDelete();
      }
    }

    setLoadingDelete(false);
  };

  return (
    <form onSubmit={form.onSubmit((data) => submit(data))}>
      <TextInput
        required
        label={__('Name')}
        {...form.getInputProps('name')}
        mb="lg"
      />

      <Textarea
        label={__('Description')}
        {...form.getInputProps('description')}
        mb="lg"
      />

      <MultiSelect
        data={imageGroupOptions}
        label={__('Groups')}
        placeholder={__('Select groups')}
        {...form.getInputProps('image_groups')}
        mb="lg"
      />

      <Group grow>
        <Button loading={loadingDelete} disabled={loadingPut || loadingDelete} color="red" variant="light" onClick={() => deleteImage()}>
          {
            loadingDelete ? __('Deleting...') : __('Delete')
          }
        </Button>
        <Button type="submit" loading={loadingPut} disabled={loadingPut || loadingDelete}>
          {
            loadingPut ? __('Saving...') : __('Save')
          }
        </Button>
      </Group>
    </form>
  );
}

export default EditImage;
