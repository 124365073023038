import {
  Button, Group, Textarea, TextInput
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { openConfirmModal } from '@mantine/modals';
import { useContext } from 'react';
import { ArticleChapter } from '../../models/articles/articleChapter';
import { __ } from '../../utils/translate';
import ArticleContext from '../helpers/ArticleContext';

// eslint-disable-next-line no-unused-vars
function EditArticleChapter({ articleChapterId, onSubmit, onDelete }: { articleChapterId: string, onSubmit: () => void, onDelete: () => void }) {
  const {
    article, putChapter, deleteChapter, getChapters
  } = useContext(ArticleContext);
  const chapter = getChapters().find((c) => c.id === articleChapterId) as ArticleChapter || {};

  const form = useForm({
    initialValues: {
      name: chapter.name || '',
      description: chapter.description || ''
    }
  });

  const submit = (data: {name: string, description: string}) => {
    putChapter(articleChapterId, data);
    onSubmit();
  };

  const removeChapter = async () => {
    await openConfirmModal({
      title: __('Are you sure you want remove this chapter?'),
      labels: { confirm: __('Confirm'), cancel: __('Cancel') },
      onConfirm: async () => {
        deleteChapter(articleChapterId);
        onDelete();
      }
    });
  };

  return (
    <form onSubmit={form.onSubmit((data) => submit(data))}>

      <TextInput
        required
        label={__('Name')}
        {...form.getInputProps('name')}
        mb="lg"
        description={__('This will not be visible if there is only one chapter in the article.')}
      />

      <Textarea
        label={__('Description')}
        {...form.getInputProps('description')}
        mb="lg"
      />

      <Group grow>
        <Button color="red" variant="light" onClick={() => removeChapter()}>
          {__('Delete')}
        </Button>
        <Button type="submit">
          {__('Save')}
        </Button>
      </Group>
    </form>
  );
}

export default EditArticleChapter;
