/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import { IconName } from '@fortawesome/fontawesome-svg-core';
import {
  Card,
  Grid,
  Input,
  Stack,
  Text
} from '@mantine/core';
import { ArticleChapterBlock } from '../../models/articles/articleChapterBlock';
import { __ } from '../../utils/translate';
import Icon from '../helpers/Icon';

interface BlockType {
  label: string;
  value: string;
  icon: IconName;
}

function BlockCard({ icon, title }: { icon: IconName, title: string }) {
  return (
    <Card
      p="lg"
      sx={(theme) => ({
        backgroundColor: theme.colors.dark[4],
        '&:hover': {
          backgroundColor: theme.colors.dark[3]
        }
      })}
    >
      <Stack align="center" spacing="xs">
        <Icon name={icon} size="xl" color="dark" />
        <Text color="light">{title}</Text>
      </Stack>
    </Card>
  );
}

// eslint-disable-next-line no-unused-vars
function AddArticleChapterBlock({ allowedTypes, onSubmit }: { allowedTypes?: string | string[], onSubmit: (type: ArticleChapterBlock['type']) => void }) {
  const blockTypes: BlockType[] = [
    { value: 'text', label: __('Text'), icon: 'text' },
    { value: 'image', label: __('Image'), icon: 'image' },
    { value: 'video', label: __('Video'), icon: 'video' },
    { value: 'question', label: __('Question'), icon: 'question' },
    { value: 'button', label: __('Links/Buttons'), icon: 'computer-mouse' },
    { value: 'list', label: __('List'), icon: 'list' },
    { value: 'CTA', label: __('Call to Action'), icon: 'circle-exclamation' },
    { value: 'cols', label: __('Text/Image'), icon: 'columns' }
  ];

  return (
    <form>
      <Input.Wrapper label={__('Block type')}>
        <Grid my="sm">
          {blockTypes.filter((blockType) => allowedTypes === 'all' || allowedTypes?.includes(blockType.value)).map((blockType) => (
            <Grid.Col
              span={4}
              onClick={() => {
                onSubmit(blockType.value as ArticleChapterBlock['type']);
              }}
            >
              <div>
                <BlockCard icon={blockType.icon} title={blockType.label} />
              </div>
            </Grid.Col>
          ))}
        </Grid>
      </Input.Wrapper>
    </form>
  );
}

AddArticleChapterBlock.defaultProps = {
  allowedTypes: 'all'
};

export default AddArticleChapterBlock;
