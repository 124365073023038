import {
  TextInput, Select, Button, NumberInput, Textarea, Checkbox
} from '@mantine/core';
import { useForm } from '@mantine/form';
import {
  useState, useEffect, useContext, createRef
} from 'react';
import { get, post } from '../../utils/api';
import { handleResponceErrors } from '../../utils/helpers';
import { __ } from '../../utils/translate';
import Language from '../../components/helpers/LanguageContext';
import { MarketingPlanGroup } from '../../models/marketingplan/MarketingPlanGroup';
import { MarketingPlan } from '../../models/marketingplan/MarketingPlan';
import ImageHandle from '../helpers/ImageHandle';

function AddPlan({ onSubmit }: { onSubmit: (marketingPlan: MarketingPlan) => void }) {
  const { language } = useContext(Language);

  const [loading, setLoading] = useState(false);
  const [groups, setGroups] = useState(({} as { marketing_plans_groups: MarketingPlanGroup[] }));
  const getGroups = async () => setGroups(await get('marketing-plans/groups', { language: language as 'swe' | 'nor' | 'eng' }));

  const form = useForm({
    initialValues: {
      name: '',
      description: '',
      marketing_plans_group_id: '',
      price: null,
      image_id: null,
      published_at: false
    }
  });

  useEffect(() => {
    getGroups();
  }, []);

  const groupOptions = groups.marketing_plans_groups ? groups.marketing_plans_groups.map((group) => ({
    label: group.name,
    value: group.id
  })) : [];

  const imageRef = createRef<any>();

  const submit = async (props: any) => {
    setLoading(true);
    const data = props;
    data.language = language as 'swe' | 'nor' | 'eng';
    data.published_at = data.published_at ? new Date() : null;

    const images = await imageRef.current.submit();
    data.image_id = images[0] ? images[0] : null;

    const resp = await post('marketing-plans', data);
    setLoading(false);

    if (resp.status === 200) {
      if (onSubmit) {
        onSubmit(resp.marketing_plan);
      }
    } else {
      const errors = handleResponceErrors(resp.error);

      form.setErrors(errors);
    }
  };

  return (
    <form onSubmit={form.onSubmit((data) => submit(data))}>
      <TextInput
        required
        label={__('Name')}
        {...form.getInputProps('name')}
        mb="lg"
      />

      <Textarea
        label={__('Description')}
        {...form.getInputProps('description')}
        mb="lg"
      />

      <ImageHandle
        ref={imageRef}
        error={form.errors.image_id}
      />

      <Checkbox
        {...form.getInputProps('published_at')}
        label={__('Published')}
        mb="lg"
        mt="lg"
      />

      <Select
        data={groupOptions}
        label={__('Group')}
        placeholder={__('Select group')}
        {...form.getInputProps('marketing_plans_group_id')}
        mb="lg"
      />

      <Button fullWidth type="submit" loading={loading}>
        {
          loading ? __('Saving...') : __('Save')
        }
      </Button>
    </form>
  );
}

export default AddPlan;
