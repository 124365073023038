import {
  Alert,
  Box,
  Button, Container, Divider, Flex, Group, Paper, Stack, Title
} from '@mantine/core';
import { closeModal, openConfirmModal, openModal } from '@mantine/modals';
import {
  forwardRef, ReactNode, useContext, useImperativeHandle
} from 'react';

import { ArticleChapter as ArticleChapterModel } from '../../models/articles/articleChapter';
import { ArticleChapterBlock } from '../../models/articles/articleChapterBlock';
import { ArticleChapterBlockButton as ArticleChapterBlockButtonModel } from '../../models/articles/articleChapterBlockButton';
import { ArticleChapterBlockCols as ArticleChapterBlockColsModel } from '../../models/articles/articleChapterBlockCols';
import { ArticleChapterBlockCta as ArticleChapterBlockCtaModel } from '../../models/articles/articleChapterBlockCta';
import { ArticleChapterBlockImage as ArticleChapterBlockImageModel } from '../../models/articles/articleChapterBlockImage';
import { ArticleChapterBlockList as ArticleChapterBlockListModel } from '../../models/articles/articleChapterBlockList';
import { ArticleChapterBlockQuestion as ArticleChapterBlockQuestionModel } from '../../models/articles/articleChapterBlockQuestion';
import { ArticleChapterBlockText as ArticleChapterBlockTextModel } from '../../models/articles/articleChapterBlockText';
import { ArticleChapterBlockVideo as ArticleChapterBlockVideoModel } from '../../models/articles/articleChapterBlockVideo';

import { __ } from '../../utils/translate';

import AddArticleChapterBlock from './AddArticleChapterBlock';
import ArticleChapterBlockButton from './blocks/ArticleChapterBlockButton';
import ArticleChapterBlockCta from './blocks/ArticleChapterBlockCta';
import ArticleChapterBlockImage from './blocks/ArticleChapterBlockImage';
import ArticleChapterBlockList from './blocks/ArticleChapterBlockList';
import ArticleChapterBlockQuestion from './blocks/ArticleChapterBlockQuestion';
import ArticleChapterBlockText from './blocks/ArticleChapterBlockText';
import ArticleChapterBlockVideo from './blocks/ArticleChapterBlockVideo';

import ArticleContext from '../helpers/ArticleContext';
import Icon from '../helpers/Icon';
import ArticleChapterBlockCols from './blocks/ArticleChapterBlockCols';
import EditArticleChapter from './EditArticleChapter';

const ArticleChapter = forwardRef(({
  headerHeight,
  articleChapterId,
  headerActions
}: {
  headerHeight: number,
  articleChapterId: string,
  headerActions?: ReactNode
}, ref) => {
  const articleContext = useContext(ArticleContext);
  const chapter = articleContext.getChapters().find((c) => c.id === articleChapterId) as ArticleChapterModel;

  const addBlock = async () => {
    openModal({
      modalId: 'add-article-chapter-block',
      title: __('Add article chapter block'),
      size: 'lg',
      children: (
        <AddArticleChapterBlock
          onSubmit={async (type) => {
            if (!chapter.articles_chapters_blocks) {
              chapter.articles_chapters_blocks = [];
            }

            chapter.articles_chapters_blocks.push({
              id: `new_${Math.random().toString(36).substr(2, 9)}`,
              type,
              sort: chapter.articles_chapters_blocks.reduce((i: number, block: ArticleChapterBlock) => Math.max(i, block.sort), 0) + 1
            } as ArticleChapterBlock);

            articleContext.putChapter(articleChapterId, chapter);

            closeModal('add-article-chapter-block');
          }}
        />
      )
    });
  };

  const moveBlock = async (id: string, dir: 1 | -1) => {
    const blocks = chapter.articles_chapters_blocks;

    if (blocks) {
      const index = blocks.findIndex((b) => b.id === id);

      if (index !== -1) {
        // Swap sort on index and index + dir
        const temp = blocks[index].sort;
        blocks[index].sort = blocks[index + dir].sort;
        blocks[index + dir].sort = temp;
      }
    }

    articleContext.putChapter(articleChapterId, { ...chapter, articles_chapter_blocks: blocks });
  };

  const deleteBlock = async (id: string) => {
    await openConfirmModal({
      title: __('Are you sure you want remove this block?'),
      labels: { confirm: __('Confirm'), cancel: __('Cancel') },
      onConfirm: async () => {
        articleContext.deleteBlock(articleChapterId, id);
      }
    });
  };

  const submit = async (id: string, data: ArticleChapterBlockTextModel | ArticleChapterBlockVideoModel | ArticleChapterBlockImageModel | ArticleChapterBlockQuestionModel | ArticleChapterBlockListModel | ArticleChapterBlockButtonModel | ArticleChapterBlockCtaModel | ArticleChapterBlockColsModel) => {
    if (chapter.articles_chapters_blocks) {
      const blockIndex = JSON.parse(JSON.stringify(chapter.articles_chapters_blocks)).findIndex((block:any) => block.id === id);

      if (blockIndex !== -1) {
        chapter.articles_chapters_blocks[blockIndex].attributes_draft = JSON.stringify(data);
      }

      articleContext.putChapter(articleChapterId, chapter);
    }
  };

  const toolbar = (id: string) => {
    const blocks = chapter.articles_chapters_blocks;
    const block = blocks && blocks.find((b) => b.id === id);
    const isFirst = blocks && blocks.findIndex((b) => b.id === id) === 0;
    const isLast = blocks && blocks.findIndex((b) => b.id === id) === blocks.length - 1;

    const revertRemoveBlock = (blockId: string) => () => {
      if (blocks) {
        const index = blocks.findIndex((b) => b.id === blockId);

        if (index !== -1) {
          blocks[index].removed_draft_at = null;
        }
      }

      articleContext.putChapter(articleChapterId, { ...chapter, articles_chapter_blocks: blocks });
    };

    const blockTypeNames = {
      text: __('Text'),
      image: __('Image'),
      video: __('Video'),
      question: __('Question'),
      button: __('Button'),
      list: __('List'),
      CTA: __('Call to Action'),
      cols: __('Text/Image')
    };

    return (
      <Group className="toolbar-wrapper">
        {block?.type ? blockTypeNames[block.type] : block?.type}
        {block && block.removed_draft_at && (
          <Button color="red" onClick={revertRemoveBlock(id)}>
            <Icon name="arrow-rotate-left" wrapper={false} />
          </Button>
        )}
        <Button.Group
          sx={{
            pointerEvents: block && block.removed_draft_at ? 'none' : 'all',
            opacity: block && block.removed_draft_at ? 0.4 : 1,
            cursor: block && block.removed_draft_at ? 'not-allowed' : 'auto'
          }}
        >
          <Button
            variant={isFirst ? 'light' : 'filled'}
            disabled={isFirst}
            onClick={() => moveBlock(id, -1)}
          >
            <Icon wrapper={false} name="up" />
          </Button>
          <Button
            variant={isLast ? 'light' : 'filled'}
            disabled={isLast}
            onClick={() => moveBlock(id, 1)}
          >
            <Icon wrapper={false} name="down" />
          </Button>
        </Button.Group>
        <Button
          sx={{
            pointerEvents: block && block.removed_draft_at ? 'none' : 'all',
            opacity: block && block.removed_draft_at ? 0.4 : 1,
            cursor: block && block.removed_draft_at ? 'not-allowed' : 'auto'
          }}
          onClick={() => deleteBlock(id)}
        >
          <Icon wrapper={false} name="xmark" library="fas" />
        </Button>
      </Group>
    );
  };

  const renderBlock = (block: ArticleChapterBlock): ReactNode => {
    let res = <div />;
    let attributes = {};

    try {
      attributes = JSON.parse(block.attributes_draft as string);
    } catch (e) {
      attributes = {};
    }

    switch (block.type) {
      case 'text':
        res = (
          <ArticleChapterBlockText
            initialValues={(attributes as ArticleChapterBlockTextModel)}
            submit={(data: ArticleChapterBlockTextModel) => submit(block.id, data)}
          />
        );
        break;
      case 'image':
        res = (
          <ArticleChapterBlockImage
            initialValues={(attributes as ArticleChapterBlockImageModel)}
            submit={(data: ArticleChapterBlockImageModel) => submit(block.id, data)}
          />
        );
        break;
      case 'video':
        res = (
          <ArticleChapterBlockVideo
            initialValues={(attributes as ArticleChapterBlockVideoModel)}
            submit={(data: ArticleChapterBlockVideoModel) => submit(block.id, data)}
          />
        );
        break;
      case 'question':
        res = (
          <ArticleChapterBlockQuestion
            initialValues={(attributes as ArticleChapterBlockQuestionModel)}
            submit={(data: ArticleChapterBlockQuestionModel) => submit(block.id, data)}
          />
        );
        break;
      case 'list':
        res = (
          <ArticleChapterBlockList
            initialValues={(attributes as ArticleChapterBlockListModel)}
            submit={(data: ArticleChapterBlockListModel) => submit(block.id, data)}
          />
        );
        break;
      case 'button':
        res = (
          <ArticleChapterBlockButton
            initialValues={(attributes as ArticleChapterBlockButtonModel)}
            submit={(data: ArticleChapterBlockButtonModel) => submit(block.id, data)}
          />
        );
        break;
      case 'CTA':
        res = (
          <ArticleChapterBlockCta
            initialValues={(attributes as ArticleChapterBlockCtaModel)}
            submit={(data: ArticleChapterBlockCtaModel) => submit(block.id, data)}
          />
        );
        break;
      case 'cols':
        res = (
          <ArticleChapterBlockCols
            initialValues={(attributes as ArticleChapterBlockColsModel)}
            submit={(data: ArticleChapterBlockColsModel) => submit(block.id, data)}
          />
        );
        break;
      default:
    }

    return res;
  };

  const revertRemove = async () => {
    articleContext.putChapter(articleChapterId, { ...chapter, removed_draft_at: null });
  };

  const editChapter = () => {
    openModal({
      modalId: 'editArticleChapter',
      title: __('Edit article chapter'),
      size: 'md',
      children: (
        <EditArticleChapter
          articleChapterId={articleChapterId}
          onSubmit={() => {
            closeModal('editArticleChapter');
          }}
          onDelete={() => {
            closeModal('editArticleChapter');
          }}
        />
      )
    });
  };

  useImperativeHandle(ref, () => ({
    addBlock() {
      addBlock();
    }
  }));

  return (
    <div>
      <Box
        sx={(theme) => ({
          position: 'sticky',
          top: headerHeight,
          backgroundColor: theme.colors.dark[6],
          zIndex: 10
        })}
      >
        <Divider my={0} />
        <Container py="md" size="lg">
          <Group grow>
            <Title order={2}>{chapter.name}</Title>
            <Group position="right">
              {chapter.removed_draft_at && (
                <Button radius={0} onClick={() => revertRemove()} color="red">
                  <Icon name="arrow-rotate-left" wrapper={false} />
                </Button>
              )}
              {headerActions}
              <Button radius={0} onClick={() => editChapter()}>
                <Icon name="edit" wrapper={false} />
              </Button>
            </Group>
          </Group>
        </Container>
        <Divider my={0} />
      </Box>
      <Box
        id={chapter.id}
        className="chapter-wrapper"
        sx={{
          scrollMarginTop: '148px',
          opacity: chapter.removed_draft_at ? 0.7 : 1,
          pointerEvents: chapter.removed_draft_at ? 'none' : 'all',
          cursor: chapter.removed_draft_at ? 'not-allowed' : 'auto'
        }}
      >
        <Container py="md" size="lg">
          {chapter.removed_draft_at ? (
            <Alert icon={<Icon name="close" wrapper={false} />} title={__('Chapter removed')} color="red" variant="light">
              {__('Chapter will be removed on publish')}
            </Alert>
          ) : (
            chapter.articles_chapters_blocks && chapter.articles_chapters_blocks.length > 0 ? (
              chapter.articles_chapters_blocks.sort((a, b) => a.sort - b.sort).map((block) => (
                <Stack
                  my="md"
                  key={block.id}
                  className={`block-wrapper block-type-${block.type}`}
                >
                  <Paper shadow="sm" p="md">
                    <Group grow>
                      <Group position="right">
                        { block.removed_draft_at ? __('This block will be removed on publish') : '' }
                        {toolbar(block.id)}
                      </Group>
                    </Group>
                    <Divider my="md" />
                    <Box
                      sx={{
                        pointerEvents: block && block.removed_draft_at ? 'none' : 'all',
                        opacity: block.removed_draft_at ? 0.4 : 1,
                        cursor: block.removed_draft_at ? 'not-allowed' : 'auto'
                      }}
                    >
                      {renderBlock(block)}
                    </Box>
                  </Paper>
                </Stack>
              ))
            ) : (
              <Alert icon={<Icon name="close" wrapper={false} />} title={__('Chapter empty')} color="blue">
                {__('Add blocks to this chapter to start writing your article.')}
              </Alert>
            )
          )}

        </Container>

        {!chapter.removed_draft_at && (
          <Box
            sx={(theme) => ({
              backgroundColor: theme.colors.dark[7],
              zIndex: 9,
              position: 'sticky',
              bottom: '58px'
            })}
          >
            <Divider my={0} />
            <Container py="xs" size="lg">
              <Flex justify="center">
                <Button onClick={() => addBlock()}>{__('Add block')}</Button>
              </Flex>
            </Container>
          </Box>
        )}
      </Box>
    </div>
  );
});

ArticleChapter.defaultProps = {
  headerActions: null
};

export default ArticleChapter;
