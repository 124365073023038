import { Alert } from '@mantine/core';
import { useContext } from 'react';
import { __ } from '../../utils/translate';
import ArticleContext from '../helpers/ArticleContext';

export default function Errors() {
  const { error } = useContext(ArticleContext);

  if (!error) {
    return null;
  }

  return (
    <Alert color="red" title={__('Errors')} m="xs">
      {Object.entries(error as Record<string, Record<string, string>>).map(([field, errors]) => (
        <div key={field}>
          {Object.entries(errors).map(([errorType, errorMessage]) => (
            <div key={errorType}>
              {errorMessage}
            </div>
          ))}
        </div>
      ))}
    </Alert>
  );
}
