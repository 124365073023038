import {
  BackgroundImage, Grid, Paper, Title, Image, Box, Badge
} from '@mantine/core';
import { openModal } from '@mantine/modals';
import { useNavigate } from 'react-router-dom';
import { stringToHash } from '../../utils/helpers';
import { Image as ImageModel } from '../../models/images/image';
import { __ } from '../../utils/translate';
import EditImage from './EditImage';

function ImageCard({ image, onEditSubmit }: { image: ImageModel, onEditSubmit?: (e: string) => void }) {
  const imageUrlDomain = process.env.NODE_ENV === 'development' ? 'http://localhost:8076' : '';
  const navigate = useNavigate();

  const previewImageModal = (id: string) => {
    openModal({
      modalId: 'preview-image',
      title: __('Preview image'),
      size: '75%',
      children: (
        <Grid>
          <Grid.Col md={7} sm={12}>
            <Image
              src={`${imageUrlDomain}/images/view/${id}`}
              fit="contain"
              p="md"
              sx={(theme) => ({
                borderRadius: theme.radius.lg,
                backgroundColor: theme.colors.dark[6]
              })}
            />
          </Grid.Col>
          <Grid.Col md={5} sm={12}>
            <Paper
              p="lg"
              sx={(theme) => ({
                backgroundColor: theme.colors.dark[6]
              })}
            >
              <EditImage
                id={id}
                onSubmit={() => {
                  if (onEditSubmit) {
                    onEditSubmit('submit');
                  }
                }}
                onDelete={() => {
                  if (onEditSubmit) {
                    onEditSubmit('delete');
                  }
                }}
              />
            </Paper>
          </Grid.Col>
        </Grid>
      )
    });
  };

  return (
    <Paper
      onClick={() => previewImageModal(image.id as string)}
      sx={(theme) => ({
        backgroundColor: theme.colors.dark[6],
        position: 'relative',
        overflow: 'hidden',
        cursor: 'pointer',
        transition: '0.2s ease-in-out',
        '&:hover': {
          opacity: 0.8
        }
      })}
    >
      <BackgroundImage
        key={image.id}
        src={`${imageUrlDomain}/images/view/${image.id}`}
        sx={{
          backgroundColor: stringToHash(image.name),
          position: 'relative',
          paddingTop: '100%'
        }}
      />
      <Box className="card-content" p="md">
        <Title order={6} mb="sm">
          {image.name}
        </Title>
        {image.image_groups && image.image_groups.map((ig) => (
          <Badge
            key={ig.id}
            variant="gradient"
            gradient={{ from: stringToHash(ig.id), to: stringToHash(ig.name) }}
            onClick={(e) => {
              e.stopPropagation();
              navigate(`/media-library/images/groups/${ig.id}`);
            }}
            mr="xs"
          >
            {ig.name}
          </Badge>
        ))}
      </Box>
    </Paper>
  );
}

ImageCard.defaultProps = {
  onEditSubmit: () => { }
};

export default ImageCard;
