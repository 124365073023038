import {
  createContext, ReactNode, useMemo, useState
} from 'react';

import { __ } from '../../utils/translate';

interface NavigationInterface {
  subNavigation: null | ReactNode;
  setSubNavigation: (subNavigation: null | ReactNode) => void;

  asideNavigation: null | ReactNode;
  setAsideNavigation: (asideNavigation: null | ReactNode) => void;
}

const NavigationContext = createContext<NavigationInterface>({
  subNavigation: null,
  setSubNavigation: (subNavigation) => {},

  asideNavigation: null,
  setAsideNavigation: (asideNavigation) => {}
});

export function NavigationProvider({ children }: { children: ReactNode }) {
  const [subNavigation, setSubNavigation] = useState<null | ReactNode>(null);
  const [asideNavigation, setAsideNavigation] = useState<null | ReactNode>(null);

  const navigationProviderValue = useMemo(() => ({
    subNavigation, setSubNavigation, asideNavigation, setAsideNavigation
  }), [subNavigation, setSubNavigation, asideNavigation, setAsideNavigation]);

  return (
    <NavigationContext.Provider value={navigationProviderValue}>
      {children}
    </NavigationContext.Provider>
  );
}

export default NavigationContext;
