import {
  Container, Title, Button, Text, Paper, Group as MGroup, BackgroundImage, Overlay, Box
} from '@mantine/core';
import { openModal, closeModal } from '@mantine/modals';
import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Icon from '../../../../components/helpers/Icon';
import { get } from '../../../../utils/api';
import { __ } from '../../../../utils/translate';
import { CampaignGroup as CampaignGroupModel } from '../../../../models/campaigns/CampaignGroup';
import EditCampaignGroup from '../../../../components/campaigns/EditCampaignGroup';
import Archive from '../../../../components/helpers/Archive';
import { Campaign } from '../../../../models/campaigns/Campaign';
import EditCampaign from '../../../../components/campaigns/EditCampaign';

function Group() {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const imageUrlDomain = process.env.NODE_ENV === 'development' ? 'http://localhost:8076' : '';
  const [campaignGroup, setCampaignGroup] = useState<{ campaignGroup: CampaignGroupModel }>({
    campaignGroup: {
      id: id ?? '',
      name: '',
      image_id: ''
    }
  });

  const getCampaignGroup = async () => setCampaignGroup(await get(`campaign-groups/${id}`));

  useEffect(() => {
    getCampaignGroup();
  }, [id]);

  const editCampaignGroup = () => {
    openModal({
      modalId: 'edit-campaign-group',
      title: __('Edit template group'),
      size: 'xl',
      children: (
        <EditCampaignGroup
          id={id as string}
          onSubmit={() => {
            closeModal('edit-campaign-group');
            getCampaignGroup();
          }}
          onDelete={() => {
            closeModal('edit-campaign-group');
            navigate('/marketinghub/campaigns/groups');
          }}
        />
      )
    });
  };

  const editCampaign = (campaignId: string) => {
    openModal({
      modalId: 'edit-group-on-campaign',
      title: __('Edit template'),
      size: 'xl',
      children: (
        <EditCampaign
          id={campaignId}
          onSubmit={() => {
            closeModal('edit-campaign-group');
            getCampaignGroup();
          }}
          onDelete={() => {
            closeModal('edit-campaign-group');
            getCampaignGroup();
          }}
        />
      )
    });
  };

  return (
    <div className="campaign-group-wrapper">
      <Container px="md" py="lg" size="lg">

        <Paper shadow="md" className="campaign-wrapper" sx={{ overflow: 'hidden' }}>
          <BackgroundImage
            src={`${imageUrlDomain}/images/view/${campaignGroup.campaignGroup.image_id}`}
            p="md"
            pt="sm"
            mb="lg"
            sx={{
              aspectRatio: '16/4',
              position: 'relative'
            }}
          >
            <Overlay opacity={0.6} color="#000" zIndex={5} />

            <Title
              order={1}
              sx={{
                position: 'absolute',
                bottom: '1rem',
                left: '1rem',
                zIndex: 10
              }}
            >
              {campaignGroup.campaignGroup.name}
            </Title>
            <MGroup
              sx={{
                position: 'absolute',
                top: '1rem',
                right: '1rem',
                zIndex: 10
              }}
            >
              <Button onClick={editCampaignGroup}>
                <Icon wrapper={false} name="edit" />
              </Button>
            </MGroup>
          </BackgroundImage>
          <Box p="md">
            <Title order={2} mb="sm">{__('Templates')}</Title>
            <Archive
              limit={100}
              flush
              request="campaigns"
              model="campaigns"
              order={{ title: 'ASC' }}
              extraQuery={{ groups: id }}
              searchable={false}
            >
              {(item: Campaign) => (
                <tr key={item.id}>
                  <td onClick={() => navigate(`/marketinghub/campaigns/${item.id}`)}>{item.title}</td>
                  <td onClick={() => navigate(`/marketinghub/campaigns/${item.id}`)}><Text align="right">{item.created.toLocaleDateString('sv-se')}</Text></td>
                  <td className="text-right" aria-label="edit">
                    <MGroup position="right">
                      <Button onClick={() => editCampaign(item.id)}>
                        <Icon wrapper={false} name="edit" />
                      </Button>
                    </MGroup>
                  </td>
                </tr>
              )}
            </Archive>
          </Box>
        </Paper>
      </Container>
    </div>
  );
}

export default Group;
